import React from "react"
import PropTypes from "prop-types"

class Statistics extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      titles: [],
      refreshtime: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshData()
    this.interval = setInterval(() => this.refreshData(), 60000); // refresh very minute
  }

  refreshData() {
    fetch('/api/v1/admin/statistics.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if(data.status == 500) {
	  window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Error : '+data.message, type: 'error' })
	} else {
	  if(this._isMounted) {
	    //console.log(data)
	    //console.log("refreshing")
	    const titles = Object.keys(data)
	    const refreshtime = (new Date()).toLocaleTimeString()
	    this.setState({ data: data, titles: titles, refreshtime: refreshtime })
	  }
	}
      })
  }
  
  
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }
  
  render () {
    
    function displayvalue(v) {
      var res = ""
      
      if(typeof v != "object") { res = <span>{v}</span>}
      else if(Array.isArray(v)) {
	res = v.map((x) => {
	  if(typeof x != "object") { return(<p className="my-0" key={x}><span>{x}</span></p>) }
	  else if(Array.isArray(x)) { return(<p className="my-0" key={x}><span>{x.join(' : ')}</span></p>) }
	})
      }

      return res
    }


    function displayparam(p) {
      const np = p.replace(/_/g,' ')
      return <span className="text-capitalize">{np}</span>
    }
    
    
    return (
      <React.Fragment>
	{
	  this.state.titles.map((x) => {

	    const subhash = this.state.data[x]
	    const subkeys = Object.keys(subhash)
	    
	    return(
	      <div key={x} className="py-2">
		<div className="card">
		  <div className="card-body">
		    <h5 className="card-title"><span className="text-capitalize">{x}</span></h5>
		    <small>Updated at {this.state.refreshtime}</small>
		    <table className="table table-sm row justify-content-left">
		      <tbody>
			{
			  subkeys.map((y) => {
			    return(
			      <tr key={y}><td>{displayparam(y)}</td><td>{displayvalue(subhash[y])}</td></tr>
			    )
			  })
			}
		      </tbody>
		    </table>
		  </div>
		</div>
	      </div>
	    )
	  })
	}
      </React.Fragment>
    );
  }
}

export default Statistics
