import React from "react"
import PropTypes from "prop-types"
import StockSearch from "../components/StockSearch"
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import "../components/StockChart.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  small: {
    fontSize: 12,
  }
});


// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);


class EtfCard extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      stock_options: '',
      valuation_options: '',
      return_options: '',
      allocation_options: '',
      region_options: '',
      sector_options: '',
      holdings_options: '',
      stock: ''
    }
  }

  updateData(data) {
    //console.log(data)
    const overview = data['metrics']['overview']
    const allocation = data['metrics']['allocation']
    const region = data['metrics']['region']
    const sector = data['metrics']['sector']
    const holdings = data['metrics']['holdings']
    
    const stock_options = {
      navigation: {
	bindingsClassName: 'stock'
      },
      yAxis: [
	{
	  labels: {
            align: 'left'
	  },
	  height: '80%',
	  resize: {
            enabled: true,
	    lineWidth: 2
	  }
	},
	{
	  labels: {
	    align: 'left'
	  },
	  top: '80%',
	  height: '20%',
	  offset: 0
	}
      ],

      tooltip: {
	shape: 'square',
	headerShape: 'callout',
	borderWidth: 0,
	shadow: false,
	positioner: function (width, height, point) {
          var chart = this.chart,
              position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: point.plotY
            };
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop
            };
          }

          return position;
	}
      },
      
      series: [
	{
	  type: 'candlestick',
	  id: 'ohlc',
	  data: data.chart_ohlc,
	  lastVisiblePrice: { enabled: true, color: 'red' },
	  name: data.code+' Stock Price',
	  upColor: 'green',
	  color: 'red',
	  dataGrouping: {
            units: [
              [
                'day', // unit name
                [1] // allowed multiples
              ],[
                'week', // unit name
                [1] // allowed multiples
              ],
              [
                'month', // unit name
                [1] // allowed multiples
              ],
          ]}
	},
	{
	  type: 'column',
	  id: 'volume',
	  name: data.code+' Volume',
	  data: data.chart_volume,
	  yAxis: 1,
	}
      ],


      navigator: {
	series: {
	  color: '#7cb5ec',
	  lineWidth: 2
	}
      },
      
      credits: {
	enabled: false
      },

      stockTools: {
	gui: {
	  enabled: false,
	  buttons: [ 'fullScreen', 'indicators', 'currentPriceIndicator' ]
	}
      },

      chart: {
	zoomType: 'x'
      },

      rangeSelector: {
	selected: 5,
	buttons: [
	  {
	    type: 'month',
	    count: 3,
	    text: '3m',
	    title: 'View 3 months'
	  },
	  {
	    type: 'month',
	    count: 6,
	    text: '6m',
	    title: 'View 6 months'
	  },
	  {
	    type: 'month',
	    count: 9,
	    text: '9m',
	    title: 'View 9 months'
	  },
	  {
	    type: 'ytd',
	    text: 'YTD',
	    title: 'View year to date'
	  },
	  {
	    type: 'year',
	    count: 1,
	    text: '1y',
	    title: 'View 1 year'
	  },
	  {
	    type: 'year',
	    count: 3,
	    text: '3y',
	    title: 'View 3 year'
	  },
	  {
	    type: 'year',
	    count: 5,
	    text: '5y',
	    title: 'View 5 year'
	  },
	  {
	    type: 'all',
	    text: 'All',
	    title: 'View all'
	  }
	]
      }    
    }


    const return_options = {
      navigation: {
	bindingsClassName: 'return'
      },
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: [
          'YTD',
          '1Y',
          '3Y',
          '5Y',
          '10Y',
        ],
      },
      yAxis: {
        title: {
          text: 'Percentage'
        },	
      },


      series: [
	{ name: 'Annually returns', tooltip: {valueSuffix: ' %'}, zones: [{ value: 0, color: 'red' }], data: [ overview['Returns_YTD'], overview['Returns_1Y'], overview['Returns_3Y'], overview['Returns_5Y'], overview['Returns_10Y'] ].reduce((a,b) => {
	  a.push(parseFloat(b));
	  return a;
	}, []) },
      ],


      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: {
	series: { marker: { enabled: false  } },
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }


    const allocation_options = {
      navigation: {
	bindingsClassName: 'return'
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: false,
      tooltip: {
	pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },      
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },           
      credits: {
	enabled: false
      },      
      
      series: [
	{ name: 'Market allocation', type: 'pie', innerSize: '50%', data: allocation },
      ],


      stockTools: {
	gui: {
	  enabled: false
	}
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }


    const region_options = {
      navigation: {
	bindingsClassName: 'region'
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: false,
      tooltip: {
	pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },      
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },           
      credits: {
	enabled: false
      },      
      
      series: [
	{ name: 'Market allocation', colorByPoint: true, data: region },
      ],


      stockTools: {
	gui: {
	  enabled: false
	}
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }


    const sector_options = {
      navigation: {
	bindingsClassName: 'sector'
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: false,
      tooltip: {
	pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },      
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },           
      credits: {
	enabled: false
      },      
      
      series: [
	{ name: 'Market allocation', colorByPoint: true, data: sector },
      ],


      stockTools: {
	gui: {
	  enabled: false
	}
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }    

    const holdings_options = holdings.reduce((a,b) => {
      a.push({id: b[0], percentage: parseFloat(b[1]).toFixed(2) + ' %'});
      return a;
    }, [])
    
    this.setState({stock_options: stock_options, stock: data, return_options: return_options, allocation_options: allocation_options, region_options: region_options, sector_options: sector_options, holdings_options: holdings_options})    
  }
  
  componentDidUpdate(prevprops) {
    //console.log("componentDidUpdate")
    if(prevprops.stock != this.props.stock) {
      this.updateData(this.props.stock)
    }
  }
  
  
  componentDidMount() {
    //console.log("componentDidMount")
    this._isMounted = true;
    this.updateData(this.props.stock)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render () {
    const { classes } = this.props;
    
    const stock = this.state.stock;
    const overview = this.state.stock.metrics ? this.state.stock.metrics['overview'] : ''
    const allocation = this.state.stock.metrics ? stock['metrics']['allocation'] : ''
    const holdings = this.state.stock.metrics ? stock['metrics']['holdings'] : ''
    const region = this.state.stock.metrics ? stock['metrics']['region'] : ''
    const sector = this.state.stock.metrics ? stock['metrics']['sector'] : ''
    const valuation = this.state.stock.metrics ? stock['metrics']['valuation'] : ''

    const holdings_columns = [
      { field: 'percentage', headerName: 'Allocation', width: 150},
      { field: 'id', headerName: 'Asset', width: 300, renderCell: (params) => (
	<Link variant="body2" color="inherit" href={"/stocks/"+params.value}>{params.value}</Link>
      ), }
    ]
    
    
    function toPercentage(f,bottom=null, top=null) {
      if(f!=null && bottom!=null && f<=bottom) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && top!=null && f>top) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }      
    }

    function toNumber(f,bottom=null, top=null) {
      if (typeof f == 'string') { f = parseFloat(f) }

      if(!isNaN(f) && f!=null && top!=null && f>top) {
	return <span className="text-danger">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null && bottom!=null && f<bottom) {
	return <span className="text-success">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }
    }
    
    return (
      <React.Fragment>
	{ stock &&
	  <div className={classes.root}>
	    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
	      
	      <Grid item sm xs={12}> {/* colonne 1 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Summary
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small} gutterBottom>
			{ stock.detail.description }
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{ overview['Category']} / { stock.detail.country } 
		      </Typography>		      
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{ stock.detail.exchange } / { stock.detail.assettype && stock.detail.assettype.replace('_',' ').toUpperCase() } / {stock.detail.currency}
		      </Typography>
		      <i className="fa fa-xs fa-info-circle" aria-hidden="true"></i> <Link color="inherit" href={overview['ETF_URL']} variant="body2" className={classes.small}>More information</Link>
		    </Paper>
		  </Grid>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Snapshot
		      </Typography>

		      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={0}>
			<Grid item>
			  <Typography variant="body2">AUM : {toNumber(overview['TotalAssets']/1000000000)} billions</Typography>
			  <Typography variant="body2">Annual turnover : {toPercentage(overview['AnnualHoldingsTurnover'])}</Typography>
			  <Typography variant="body2">Current Price : {stock.price}</Typography>
			  <Typography variant="body2">Inception : {overview['Inception_Date']}</Typography>
			  <Typography variant="body2">Morning star rating : {overview['MorningStar']}/5</Typography>
			  <Typography variant="body2">Expense ratio : {toPercentage(overview['NetExpenseRatio'])}</Typography>
			  <Typography variant="body2">Dividend Yield : { toPercentage(stock.detail.dividendyield,0,null) }</Typography>
			  <Typography variant="body2">Beta : {overview['Beta']}</Typography>
			  <Typography variant="body2">Sharpe ratio (3y) : { overview['3y_SharpRatio'] }</Typography>
			  <Typography variant="body2">Volatility (3y) : { overview['3y_Volatility'] }</Typography>
			  <Typography variant="body2">Mom12m : {toPercentage(stock.mom12m,0,0)}</Typography>
			  <Typography variant="body2">Sma10m : {toPercentage(stock.proximitysma10m,0,0)}</Typography>
			  <Typography variant="body2">UPI : { toNumber(stock.upi,null,null) }</Typography>
			  <Typography variant="body2">Mom6m : {toPercentage(stock.mom6m,0,0)}</Typography>
			  <Typography variant="body2">Hi52 : {toPercentage(stock.proximityhigh52,null,null)}</Typography>
		      	</Grid>
			
			<Grid item>
			  <Typography variant="body2">P/S : { toNumber(valuation['Price/Sales'],2,4) }</Typography>
			  <Typography variant="body2">P/B : { toNumber(valuation['Price/Book'],3, null) }</Typography>
			  <Typography variant="body2">P/CF : { toNumber(valuation['Price/Cash Flow'],15, null) }</Typography>
			  <Typography variant="body2">Fw PER : { toNumber(valuation['Price/Prospective Earnings'], null,18) }</Typography>
			  <Typography variant="body2">Fw PEG : { toNumber(valuation['Price/Prospective Earnings']/valuation['Long-Term Projected Earnings Growth'], 1.0,1.0) }</Typography>
			</Grid>
			
		      </Grid>
		    </Paper>
		  </Grid>

		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Returns
		      </Typography>
		      { this.state.return_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.return_options}
			/>
		      }
		    </Paper>
		  </Grid>
		  
		  
		</Grid>
	      </Grid>

	      
	      <Grid item sm xs={12}> {/* colonne 2 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Price
		      </Typography>
		      { this.state.stock_options &&
			<HighchartsReact
			  constructorType="stockChart"
			  highcharts={Highcharts}
			  options={this.state.stock_options}
			/>
		      }
		    </Paper>
		  </Grid>


		  { holdings.length > 0 &&
		    <Grid item xs>
		      <Paper className={classes.paper} elevation={3}>
			<Typography component="h5" color="textPrimary" className={classes.bold}>
			  Holdings
			</Typography>
			<DataGrid density="compact" autoHeight pageSize={10} rows={this.state.holdings_options} columns={holdings_columns} />			
		      </Paper>
		    </Grid>
		  }
		  
		</Grid>
	      </Grid>


	      <Grid item sm xs={12}> {/* colonne 3 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Asset type allocation
		      </Typography>
		      { this.state.allocation_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.allocation_options}
			/>
		      }
		    </Paper>
		  </Grid>

		  { region.length > 0 &&
		    <Grid item xs>
		      <Paper className={classes.paper} elevation={3}>
			<Typography component="h5" color="textPrimary" className={classes.bold}>
			  Region
			</Typography>
			{ this.state.region_options &&
			  <HighchartsReact
			    constructorType="chart"
			    highcharts={Highcharts}
			    options={this.state.region_options}
			  />
			}
		      </Paper>
		    </Grid>
		  }

		  { sector.length > 0 &&
		    <Grid item xs>
		      <Paper className={classes.paper} elevation={3}>
			<Typography component="h5" color="textPrimary" className={classes.bold}>
			  Sector
			</Typography>
			{ this.state.sector_options &&
			  <HighchartsReact
			    constructorType="chart"
			    highcharts={Highcharts}
			    options={this.state.sector_options}
			  />
			}
		      </Paper>
		    </Grid>
		  }
		  
		</Grid>
	      </Grid>	      


	      
	    </Grid>
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(EtfCard); 
