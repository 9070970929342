import React from "react"
import PropTypes from "prop-types"
import {jStat} from 'jstat'

class Correlation extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  constructor(props) {
    super(props);
    this.state = {
      correlation: [[]],
      correlation_legend: [],
      dates: [],
      tickers: '',
      loading: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleChange(value) {
    this.setState({tickers: value})
  }

  handleCompute(e) {

    function create2DArray(rows) {
      var arr = [];

      for (var i=0;i<rows;i++) {
	arr[i] = [];
      }

      return arr;
    }
    
    this.setState({ loading: true });
    e.preventDefault();
    let body = JSON.stringify({tickers: this.state.tickers})

    fetch('/api/v1/correlation',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((data) => {
		     //console.log(data)
		     const entries = data.entries
		     const dates = data.dates
		     
		     // ex. {"TLT": ["10","20"], "QQQ": ["11","12"]}
		     
		     const tickers = Object.keys(entries)
		     const len = tickers.length
		     
		     var correlation = create2DArray(len)
		     //for(var x=0; x<=len-2; x++) {
		     //  for(var y=x+1; y <= len-1; y++) {		 
		     //  newentries[x][y] = jStat.corrcoeff( entries[tickers[x]], entries[tickers[y]] )
		     //  }
		     //}

		     for(var x=0;x<len;x++) {
		       for(var y=0;y<len;y++) {
			 correlation[x][y] = jStat.corrcoeff( entries[tickers[x]], entries[tickers[y]] ).toFixed(2)
		       }
		     }
		     
		     this.setState({ loading: false, correlation: correlation, correlation_legend: tickers, dates: dates });
		   })
    
    e.target.reset();
  }


  
  render () {

    const tickers = this.state.correlation_legend
    
    return (
      <React.Fragment>	
	<form onSubmit={(e) => this.handleCompute(e)}>
	  <div className="form-row mt-3">
	    <div className="form-group col-md-8"><input className="form-control" type="text" value={this.state.tickers} onChange={(e) => this.handleChange(e.target.value)} placeholder="Enter symbols : use space to separate multiple stocks" /></div>
	    <div className="form-group col-md-2">
	      { this.state.loading &&
		<div className="spinner-border" role="status">
		  <span className="sr-only">Loading...</span>
		</div>
	      }
	      { !this.state.loading &&
		<button className="btn btn-info">Calculate correlation</button>
	      }
	    </div>
	  </div>
	</form>

	{ this.state.correlation_legend.length > 0 &&
	  <div className="table-responsive-sm">	    
	    <p className="my-0"><small>Correlation coefficient (Pearson's Rho)</small></p>
	    <p className="my-0"><small>From {(new Date(this.state.dates[0])).toDateString()} to {(new Date(this.state.dates[this.state.dates.length-1])).toDateString()}</small></p>
	    <table className="table table-sm">
	      <thead>
		<tr>
		  <th></th>
		  {tickers.map((code,x) => (
		    <th style={{maxWidth: '3rem'}} key={x}>{code.replace(/:/gi, ' ')}</th>
		  ))}
		</tr>
	      </thead>
	      <tbody>
		{this.state.correlation.map((row, y) => (
		  <tr key={y}>
		    <th style={{maxWidth: '3rem'}}>{tickers[y].replace(/:/gi, ' ')}</th>
		    {row.map((col, x) => (
		      <td key={x}>{col}</td>
		    ))}
		  </tr>
		))}
	      </tbody>
	    </table>
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default Correlation
