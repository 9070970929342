import React from "react"
import PropTypes from "prop-types"
import StockDetail from "../components/StockDetail"
import StockSearch from "../components/StockSearch"

class WatchlistItem extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      direction: true,
      offset: 0,
      moredata: true,
      loading: false,
      stockdetail: '',
      btnclip: false,
      tickers: []
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("scroll", () => { requestAnimationFrame(() => { this.calculateScrollDistance() }) })
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("scroll", () => { requestAnimationFrame(() => { this.calculateScrollDistance() }) })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.watchlistId != this.props.watchlistId ) {
      //console.log("componentDidUpdate:"+prevProps.watchlistId+"=>"+this.props.watchlistId)
      this.setState({btnclip: false})
      const watchlistid = this.props.watchlistId
      fetch('/api/v1/watchlists/'+watchlistid+'/watchlist_items/offset/0.json')
			      .then((response) => {return response.json()})
			      .then((data) => {
				
				if (this._isMounted && data.status != 500) {
				  data.sort(
				    (b,a) => {
				      if(a.stock.mom6m < b.stock.mom6m) {
					return -1;
				      }
				      if(a.stock.mom6m > b.stock.mom6m) {
					return 1;
				      }
				      return 0;
				    }
				  )
				  
				  this.setState({ entries: data, offset: 0, moredata: true, direction: true })
				}
				
				if(data.status == 500) { this.setState({ entries: [], offset: 0, moredata: true, direction: true }) }
			      })      
    }
  }

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }


  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPosition = Math.floor(scrollTop / totalDocScrollLength * 100)

    //console.log('scrollPosition:'+scrollPosition)
    
    if (this._isMounted && this.state.moredata && scrollPosition == 100 ) {
      
      const newoffset = this.state.offset + 1
      fetch('/api/v1/watchlists/'+this.props.watchlistId+'/watchlist_items/offset/'+newoffset+'.json')
			    .then((response) => {return response.json()})
			    .then((data) => {
			      if (data.status != 500) {
				//console.log('offset:'+newoffset)
				//console.log(data)
				const entries = this.state.entries.slice()
				const ids = entries.reduce((a,b) => {a.push(b.id);return a}, [])
				var filterdata = data.filter((item) => ids.indexOf(item.id) < 0 )
				//console.log(filterdata)
				var newdata = entries.concat(filterdata)
				newdata.sort(
				  (b,a) => {
				    if(a.stock.mom6m < b.stock.mom6m) {
				      return -1;
				    }
				    if(a.stock.mom6m > b.stock.mom6m) {
				      return 1;
				    }
				    return 0;
				  }
				)
				const newmoredata = filterdata.length > 0 ? true : false
				this.setState({ offset: newoffset, entries: newdata , moredata: newmoredata})
			      }
			    })
      
    }
  }

  
  handleUpdateItemsFromParent() {
    let watchlistid = this.props.watchlistId
    //console.log("handleUpdateItemsFromParent")
    //console.log(this.state.entries)
    
    // updjson = { 2 => {"annotation" => "test" , "fees" => "1"}, 5 => {"annotation" => "test2", "fees" => "2" }}
    // WatchlistItem.update(updjson.keys, updjson.values)
    let updjson = {}
    this.state.entries.forEach(updatefield => {
      let wi = {}
      wi.annotation = updatefield.annotation
      wi.fees = parseFloat(updatefield.fees)

      if(isNaN(wi.fees)) { wi.fees = 0}
      
      updjson[updatefield.id] = wi
    })

    let body = JSON.stringify({watchlist_item: updjson})
    //console.log(body)
    fetch('/api/v1/watchlists/'+watchlistid+'/watchlist_items_bulk',
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((watchlistitem) => {
		     if(watchlistitem.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update watchlist items : '+watchlistitem.message, type: 'error' }) } else {

		       window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Watchlist saved', type: 'success' })
		       this.setState({
		         entries: watchlistitem
		       })
		     }
		   })    
  }
  
  
  handleAddItems(e) {      
    this.setState({ loading: true });
    //e.preventDefault();
    //let symbols = formfield.symbols.value
    const symbols = this.state.tickers.join(' ')
    let body = JSON.stringify({symbols: symbols} )
    
    fetch('/api/v1/watchlists/'+this.props.watchlistId+'/watchlist_items',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((watchlistitem) => {
		     this.setState({ loading: false });
		     if(watchlistitem.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot create entries : '+watchlistitem.message, type: 'error' }) } else {

		       let asked = symbols.split(/[ ]+/).map(x => x.toUpperCase())
		       let added = watchlistitem.map(x => x.stock.code)
		       let notfound = asked.filter(s => !added.includes(s))

		       if(notfound.length != 0) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: "Not found : "+notfound.toString(), type: 'error' }) }
		       //if(added.length != 0) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: "Added : "+added.toString(), type: 'success' }) }
		       
		       this.setState({
			 entries: this.state.entries.concat(watchlistitem)
		       })
		       
		       this.stocksearch && this.stocksearch.clear()
		       this.setState({tickers: []})
		     }
		   })

    //e.target.reset();
  }  

  handleDeleteItem(watchlistid,itemid) {
    //console.log('handleDeleteItem watchlistid=' + watchlistid + ' itemid=' + itemid);

    fetch('/api/v1/watchlists/'+watchlistid+'/watchlist_items/'+itemid,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((item) => {
       if(item.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+item.message, type: 'error' }) } else { 
	 var newentry = this.state.entries.filter((entry) => entry.id !== itemid)
	 this.setState({ entries: newentry})
       }
     })
  }

  toPercentage(f) {
    if(f!=null && f<0) {
      return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
    } else
    {
      return <span>{(f*100).toFixed(2)+' %'}</span>
    }
  }

  toMillions(val) {
    if(val != null) {
      return <span>{(val/1000000).toFixed(2) + " millions"}</span>
    }
  } 

  onSort(event, firstkey, sortKey) {
    const data = this.state.entries;
    var direction = this.state.direction;
    
    if(direction) {
      data.sort(
	(a,b) => {
	  if(firstkey!=null) {
	    a = a[firstkey]
	    b = b[firstkey]
	  }
	  
	  if(a[sortKey] < b[sortKey]) {
	    return -1;
	  }
	  if(a[sortKey] > b[sortKey]) {
	    return 1;
	  }
	  return 0;
	}
      )
    } else {
      data.sort(
	(b,a) => {
	  if(firstkey!=null) {
	    a = a[firstkey]
	    b = b[firstkey]
	  }
	  
	  if(a[sortKey] < b[sortKey]) {
	    return -1;
	  }
	  if(a[sortKey] > b[sortKey]) {
	    return 1;
	  }
	  return 0;
	}
      )
    }
    
    direction = !direction
    
    this.setState({entries: data, direction: direction})
  }


  handleChange(id,name,value) {
    //console.log("handleChange "+id+";"+name+";"+value)
    let newentries = this.state.entries.slice()
    let idx = newentries.findIndex(x => x.id == id)
    let newvalue = value
    
    newentries[idx][name] = newvalue
    //console.log(newvalue)
    this.setState({entries: newentries}); 
  }

  
  displaydetail(stock) {
    this.setState({stockdetail: stock})
  }

  handleStockSearch(e, ref) {
    this.stocksearch = ref
    this.setState( {tickers: e} )
  }

  updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip)
    this.setState({btnclip: true})
  }
  
  render () {
    const allcodes = (this.state.entries.reduce((a,b) => a + " " + b.stock.code, "")).trim()
    let entries = this.state.entries.map((entry) => {

      const annotation = entry.annotation == null ? '' : entry.annotation
      const fees = entry.fees == null ? '' : entry.fees
      
      return(
	<tr key={entry.id}>
	  <td className="align-middle"><button type="button" onClick={() => this.handleDeleteItem(entry.watchlist_id, entry.id)} className="btn btn-sm btn-danger">X</button></td>
	  <td className="align-middle">
	    <a href={'/stocks/'+entry.stock.code} className="btn btn-sm btn-light" onMouseEnter={() => this.displaydetail(entry.stock)} onMouseLeave={() => this.displaydetail('')}>
	      {entry.stock.code}
	    </a>
	  </td>
	  <td className="align-middle">{this.toPercentage(entry.stock.mom6m)}</td>
	  <td className="align-middle">{this.toPercentage(entry.stock.proximityhigh52)}</td>	  
	  <td className="align-middle"><input className="form-control form-control-sm" type="text" value={annotation} onChange={(e) => this.handleChange(entry.id,"annotation",e.target.value)} /></td>
	  <td className="align-middle"><div className="input-group input-group-sm w-75"><input type="text" className="form-control form-control-sm" value={fees} onChange={(e) => this.handleChange(entry.id,"fees",e.target.value)} /><div className="input-group-append"><span className="input-group-text">%</span></div></div></td>
	  <td className="align-middle">{this.toPercentage(entry.stock.proximitysma10m)}</td>
	  <td className="align-middle">{this.toPercentage(entry.stock.mom12m)}</td>	  
	  <td className="align-middle">{this.toMillions(entry.stock.volumeavg)}</td>
	</tr>	
      )
    })

    
    return (
      <React.Fragment>
      { (this.props.watchlistId != 0) ?
	<React.Fragment>
	<form>
	<div className="form-row mt-3">
	<div className="form-group col-md-8">
	
	<StockSearch onChange={(e, ref) => this.handleStockSearch(e, ref)} />
	
	</div>
	<div className="form-group col-md-2">
	{ this.state.loading &&
	  <div className="spinner-border" role="status">
	    <span className="sr-only">Loading...</span>
	  </div>
	}
	{ !this.state.loading &&
	  <div><button disabled={this.state.tickers.length == 0} className="btn btn-info" onClick={(e) => this.handleAddItems(e)}>Add to watchlist</button> <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#helpModal"><i className="fas fa-question-circle"></i> Help</button></div>
	}
	  </div>
	  </div>
	  <small id="symbolsHelp" className="form-text text-muted"></small>
	  
	  </form>
	  

	  <form className="form-inline">	
	    <div className="table-responsive">
	      <table className="table table-sm table-hover">
		<thead>
		  <tr>
		    <th>{!this.state.btnclip ? <button data-toggle="tooltip" title="Copy tickers to clipboard" className="btn btn-dark btn-sm" onClick={() => {this.updateClipboard(allcodes)}}><i className="fas fa-copy"/></button> : <button className="btn btn-dark btn-sm" disabled={true}><i className="fas fa-check"/></button>}</th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="Stock tickers" onClick={e => this.onSort(e, 'stock', 'code')}>Code [{this.state.entries.length}]</button></th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="6 months momentum" onClick={e => this.onSort(e, 'stock', 'mom6m')}>Mom6m</button></th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="Relative distance to the 52 weeks high" onClick={e => this.onSort(e, 'stock', 'proximityhigh52')}>52wk-hi</button></th>		    
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="Free annotation" onClick={e => this.onSort(e, null, 'annotation')}>Annotation</button></th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="Fees if any" onClick={e => this.onSort(e, null, 'fees')}>Fees</button></th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="% above or below the SMA 10 months" onClick={e => this.onSort(e, 'stock', 'proximitysma10m')}>Above sma10m</button></th>
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="12 months momentum" onClick={e => this.onSort(e, 'stock', 'mom12m')}>Mom12m</button></th>		    
		    <th className="align-middle"><button type="button" className="btn btn-link" data-toggle="tooltip" data-placement="auto" title="Average daily volume on 12 months" onClick={e => this.onSort(e, 'stock', 'volumeavg')}>Vol-avg</button></th>
		  </tr>
		</thead>

		<tbody>
		  {entries}
		</tbody>
	      </table>
	      
	    </div>
	  </form>
	</React.Fragment>
	: <React.Fragment/> }	

      
      {
	this.state.stockdetail && <div className="stockdetail"><StockDetail stock={this.state.stockdetail} /></div>
      }

      { /* help modal */ }
      <div className="modal fade" id="helpModal" tabIndex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
	<div className="modal-dialog" role="document">
	  <div className="modal-content">
	    <div className="modal-header">
	      <h5 className="modal-title" id="helpModalLabel">Help on watchlist</h5>
	      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	      </button>
	    </div>
	    <div className="modal-body">
	      <p className="card-text">A watchlist allows you to monitor a list of stocks. By having a watchlist, you can apply a filter by using the <a href="/screener">Screener</a>.</p>
	      <p className="card-text"><strong>Enter symbols to Add stocks : </strong>put the tickers here, you can add multiple tickers with a space. For cryptocurrency a ticker is represented by [SYMBOL]-[MARKET].CC</p>
	      <p className="card-text">Tickers example : </p>
	      <ul>
		<li>Regular stocks or ETF : AAPL MSFT</li>
		<li>Crypto currency : BTC-USD.CC ETH-USD.CC</li>
	      </ul>
	      <p className="card-text"><strong>Mom6m : </strong>this is the absolute momentum with a lookback period of 6 month. If it is positive with a high value, it means that the price may continue to rise.</p>
	      <p className="card-text"><strong>Annotation (optional) : </strong>write notes here about the asset if you need to.</p>
	      <p className="card-text"><strong>Fees (optional) : </strong>put here the fees about that asset. It can be usefull if it is an ETF for example.</p>
	      <p className="card-text"><strong>Above sma10m : </strong>how far the latest close if from the 10 months simple moving average. If it is negative, it means that the momentum of this asset is declining and the price may continue to decrease.</p>		
	      <p className="card-text"><strong>52wk-hi : </strong>how far the price is from the 52 weeks high. The closer it is, the stronger is the momentum.</p>
	      <p className="card-text"><strong>Vol-avg: </strong>average shares exchanged over the last 12 month.</p>

	    </div>
	    <div className="modal-footer">
	      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
	    </div>
	  </div>
	</div>
      </div>
      </React.Fragment>
    );
  }
}

export default WatchlistItem
