import React from "react"
import PropTypes from "prop-types"
class User extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filtusers: [],
      searchtext: '',
      currentuser: {email: '', name: '', expiration: '', role: '', plan: ''},
      currentuserid: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/admin/user.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  this.setState({ users: data })
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearch(value) {
    if(value) {
      const lcvalue = value.toLowerCase()
      
      var filtusers = this.state.users.filter(s => {
	const lcmail = s.email ? s.email.toLowerCase() : ''
	const lcname = s.name ? s.name.toLowerCase() : ''
	return lcmail.includes(lcvalue) || lcname.includes(lcvalue)
      })

      if(filtusers.length>10) {
	filtusers = filtusers.slice(0,10)
      }

      this.setState({searchtext: value, filtusers: filtusers})
    } else {
      this.setState({searchtext: '', filtusers: []})
    }
  }

  handleDelete(id) {
    fetch('/api/v1/admin/user/'+id,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((user) => {
       if(user.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+user.message, type: 'error' }) } else {

	 const currentusers = this.state.users.slice()
	 let newusers = currentusers.filter((p) => p.id !== id)

	 const currentfiltusers = this.state.filtusers.slice()
	 let newfiltusers = currentfiltusers.filter((p) => p.id !== id)
	 
	 this.setState( {users: newusers, filtusers: newfiltusers} )
	 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: user.email+' deleted', type: 'success' })
       }
     })
  }

  handleConnectas(id) {
    fetch('/api/v1/admin/user/connectas/'+id,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((user) => {
       if(user.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot connect as : '+user.message, type: 'error' }) } else {
	 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'You are now '+user.email, type: 'success' })
       }
     })
  }

  handleUpdateUser() {
    const body = JSON.stringify({user: this.state.currentuser})

    fetch('/api/v1/admin/user/'+this.state.currentuserid,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		     .then((user) => {
		       if(user.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update user :'+user.message, type: 'error' })
		       }
		       else {
			 const currentusers = this.state.users.slice()
			 var newusers = currentusers.filter((p) => p.id != user.id)
			 newusers.push(user)

			 const currentfiltusers = this.state.filtusers.slice()
			 var newfiltusers = currentfiltusers.filter((p) => p.id != user.id)
			 newfiltusers.push(user)

			 this.setState( {users: newusers, filtusers: newfiltusers} )
			 
			 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'User '+user.email+' updated', type: 'success' })
		       }
		     })
  }



  loadUser(id) {
    //console.log('loadUser '+id)
    const cu = this.state.users.find(u => u.id == id)
    //console.log(cu)
    var currentuser = {}
    currentuser['email'] = cu['email']
    currentuser['name'] = cu['name']
    currentuser['expiration'] = cu['expiration']
    currentuser['role'] = cu['role']
    currentuser['plan'] = cu['plan']

    this.setState( {currentuser: currentuser, currentuserid: id} )
  }
  
  handleChange(field, value) {
    //console.log('handleChange '+field+' '+value)
    var newcu = Object.assign({}, this.state.currentuser)
    newcu[field] = value
    this.setState( {currentuser: newcu} )    
  }

  
  
  render () {
    function displayuser(x) {
      const keys = Object.keys(x)
      const prop = keys.map((y) => {
	return <p key={y} className="my-0"><strong>{y}</strong> : {x[y]}</p>
      })

      
      return prop
    }
    
    return (
      <React.Fragment>
	<input type="text" className="form-control mt-2" placeholder="Email or name" value={this.state.searchtext} onChange={(e) => this.handleSearch(e.target.value)}/>
	{
	  this.state.filtusers.map((x) => {
	    return(
	      <div key={x.id} className="py-2">
		<div className="card">
		  <div className="card-body">
		    { displayuser(x) }
		    <button className="btn btn-sm btn-outline-dark" data-toggle="modal" data-target="#modifyModal" onClick={() => this.loadUser(x.id)}>Modify</button> <button className="btn btn-sm btn-outline-dark" onClick={() => this.handleConnectas(x.id)}>Connect as</button> <button className="btn btn-sm btn-outline-dark" onClick={() => window.confirm("Are you sure you wish to delete "+x.email+" ?") && this.handleDelete(x.id)}>Delete</button>
		  </div>
		</div>
	      </div>
	    )
	  })
	}

	<div className="modal fade" id="modifyModal" tabIndex="-1" role="dialog" aria-labelledby="modifyModalLabel" aria-hidden="true">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      
	      <div className="modal-header">
		<h5 className="modal-title" id="modifyModalLabel">Modify user</h5>
		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	      </div>

	      <div className="modal-body">
		<form>
		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Email</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentuser['email']} onChange={(e) => this.handleChange('email', e.target.value)} />
		    </div>
		  </div>

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Name</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentuser['name']} onChange={(e) => this.handleChange('name', e.target.value)} />
		    </div>
		  </div>		  

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Expiration</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentuser['expiration']} onChange={(e) => this.handleChange('expiration', e.target.value)} />
		    </div>
		  </div>		  

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Role</label>
		    <div className="col-sm-10">
		      <select className="form-control" value={this.state.currentuser['role']} onChange={(e) => this.handleChange('role', e.target.value)}>
			<option value="banned">banned</option>
			<option value="user">user</option>
			<option value="moderator">moderator</option>
			<option value="admin">admin</option>
		      </select>
		    </div>
		  </div>

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Plan</label>
		    <div className="col-sm-10">
		      <select className="form-control" value={this.state.currentuser['plan']} onChange={(e) => this.handleChange('plan', e.target.value)}>
			<option value="trial">trial</option>
			<option value="premium">premium</option>
		      </select>
		    </div>
		  </div>
		  
		  <div className="modal-footer">
		    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.handleUpdateUser()}>Save</button>
		    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
		  </div>
		</form>
	      </div>
	      
	    </div>
	  </div>
	</div>
      </React.Fragment>
    );
  }
}

export default User
