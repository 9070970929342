import React from "react"
import PropTypes from "prop-types"
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const useStyles = (theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },

  label: {
    color: '#3f50b5',
  },

  description: {
    fontSize: 12
  },
  
});

const WAIT_INTERVAL = 500

class StockSearch extends React.Component {
  _isMounted = false;
  timer = null;
  
  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      tickers: [],
      checked: false,
      acmultiplevalue: [],
      tfvalue: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleACMultipleValue(value) {
    //console.log('handleACMultipleValue')
    //console.log(value)
    const ntickers = value.reduce((a,b) => {
      a.push(b.code)
      return a
    }, [])
    this.setState({tickers: ntickers, acmultiplevalue: value})

    this.props.onChange && this.props.onChange(ntickers, this)
  }

  handleACSingleValue(value) {
    if(value) {
      const ntickers = [value.code]
      this.setState({tickers: ntickers})
      this.props.onChange && this.props.onChange(ntickers, this)
    }
  }

  
  setInputValue(value, reason) {
    if(value && reason == 'input') {
      //console.log('typing: '+value)
      clearTimeout(this.timer)
      this.timer = setTimeout(() => this.triggerResearch(value), WAIT_INTERVAL)
    }
  }


  
  triggerResearch(value) {
    //console.log('triggerResearch:'+value)
    
    fetch(encodeURI('/api/v1/stocks/search/'+value))
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  var nstocks = this.state.stocks.slice()
	  var filtered = nstocks.filter((s) => this.state.tickers.includes(s.code))
	  var datafiltered = data.filter((s) => !this.state.tickers.includes(s.code))
	  this.setState({ stocks: filtered.concat(datafiltered) })
	}
      })
  }


  handleSwitch(event) {
    this.setState({checked: event.target.checked, acmultiplevalue: [], tfvalue: ''})
  }

  handleTextField(event) {
    const text = event.target.value.trim().toUpperCase()
    
    const ntickers = text != '' ? text.split(' ') : []
    this.setState( {tickers: ntickers, tfvalue: event.target.value} )

    //console.log('text:'+text)
    //console.log(ntickers)
    
    this.props.onChange && this.props.onChange(ntickers, this)
  }
  
  clear() {
    this.setState({acmultiplevalue: [], tfvalue: ''})
  }

  
  render () {
    const { classes } = this.props;
    
    function labelfn(option) {
      return <Typography component="div"><Box className={classes.label} component="span">{option.code}</Box><Box className={classes.description}>{option.name+' | '+option.type+' | '+option.region}</Box></Typography>
    }


    
    return (
      <React.Fragment>
	<div className={classes.root}>
	  { this.props.single &&
	    <Autocomplete
	      onChange={(event, newValue) => this.handleACSingleValue(newValue)}
	      disabled={this.props.disabled}
	      fullWidth
	      options={this.state.stocks.sort((a,b) => -b.type.localeCompare(a.type))}
	      groupBy={(option) => option.type}
	      noOptionsText=""
	      size="small"
	      getOptionLabel={option => option.code ? option.code+' '+option.name : ''}
	      onInputChange={(event, newInputValue, reason) => this.setInputValue(newInputValue,reason)}
	      renderInput={(params) => <TextField {...params} variant="outlined"  placeholder="Search for symbols or companies" />}
	      renderOption={(option) => labelfn(option)}
	      clearOnEscape={true}
	    />
	  }
	  
	  { !this.props.single &&
	    <Grid container spacing={3}>
	      <Grid item xs={10}>
		{ !this.state.checked &&
		  <Autocomplete
		    value={this.state.acmultiplevalue}
		    fullWidth
		    multiple
		    size="small"
		    noOptionsText=""
		    onChange={(event, newValue) => this.handleACMultipleValue(newValue)}
		    onInputChange={(event, newInputValue, reason) => this.setInputValue(newInputValue, reason)}
		    options={this.state.stocks.sort((a,b) => -b.type.localeCompare(a.type))}
		    groupBy={(option) => option.type}
		    getOptionLabel={option => option.code+' '+option.name}
		    renderOption={(option) => labelfn(option)}
		    renderTags={(value, getTagProps) =>
		      this.state.tickers.map((code,index) => (
			<Chip variant="default" size="small" color={'primary'} label={code} {...getTagProps({ index })} />
		      ))
		    }
		    renderInput={(params) => (
		      <TextField {...params} variant="standard" label="Search for symbols or companies"  />
		    )}
		    getOptionSelected={(option) => this.state.tickers.includes(option.code)  }
		  />
		}

		{ this.state.checked &&
		  <TextField value={this.state.tfvalue} style={{width: '100%'}} fullWidth onChange={(e) => this.handleTextField(e)} label="Enter multiple stock tickers" helperText="Enter the tickers directly separated by a space"/>
		}
	      </Grid>

	      <Grid item xs>
		<FormControlLabel
		  control={
		    <Switch
		      checked={this.state.checked}
		      onChange={(e) => this.handleSwitch(e)}
		      name="switch"
		      color="primary"
		    />
		  }
		  labelPlacement="end"
		  label={
		    <Box component="div" fontSize={12}>
                      Advanced
		    </Box>
		  }	  
		/>
	      </Grid>
	    </Grid>
	  }
	  
	</div>	
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(StockSearch);  

// Specifies the default values for props:
StockSearch.defaultProps = {
  single: false,
  disabled: false
};
