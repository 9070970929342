import React from "react"
import PropTypes from "prop-types"
class Registry extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      registries: [],
      currentregistry: {key: '', role: '', value: ''},
      currentregistryid: '',
      refreshing: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/admin/registry.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  this.setState({ registries: data })
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  handleUpdateRegistry() {
    const body = JSON.stringify({registry: this.state.currentregistry})

    fetch('/api/v1/admin/registry/'+this.state.currentregistryid,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		     .then((registry) => {
		       if(registry.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update registry :'+registry.message, type: 'error' })
		       }
		       else {
			 const currentregistries = this.state.registries.slice()
			 var newregistries = currentregistries.filter((p) => p.id != registry.id)
			 newregistries.push(registry)
			 newregistries.sort((a,b) => a.id-b.id)

			 this.setState( {registries: newregistries} )
		       }
		     })
  }



  loadRegistry(id) {
    const cr = this.state.registries.find(r => r.id == id)
    var currentregistry = {}
    currentregistry['key'] = cr['key']
    currentregistry['role'] = cr['role']
    currentregistry['value'] = cr['value']


    this.setState( {currentregistry: currentregistry, currentregistryid: id} )
  }


  handleChange(field, value) {
    var newcr = Object.assign({}, this.state.currentregistry)
    newcr[field] = value
    this.setState( {currentregistry: newcr} )    
  }  


  handleLaunchUpdate() {
    this.setState( {refreshing: true} )
    const body = ""

    fetch('/api/v1/admin/updateregistries',
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		     .then((registries) => {
		       if(registries.status == 500) {
			 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update registries :'+registries.message, type: 'error' })
			 this.setState( {refreshing: false} )
		       }
		       else {
			 this.setState( {registries: registries, refreshing: false} )
		       }
		     })
  }

  
  
  render () {
    function displaydate(d) {
      return (new Date(d)).toLocaleString()
    }

    
    return (
      <React.Fragment>
	<table className="table table-sm table-hover">
	  <thead>
	    <tr><th>{this.state.refreshing ? <button className="btn btn-sm btn-success" disabled={true}><i className="fas fa-sync fa-spin"/></button> : <button className="btn btn-sm btn-success" data-toggle="tooltip" title="Launch update all" onClick={() => this.handleLaunchUpdate()}><i className="fas fa-sync"/></button>}</th><th>Id</th><th>Created</th><th>Updated</th><th>Key</th><th>Role</th><th>Value</th></tr>
	  </thead>
	  <tbody>
	    {
	      this.state.registries.map((x) => {

		return(
		  <tr key={x.id}><td><button disabled={this.state.refreshing} className="btn btn-sm btn-dark fas fa-edit" data-toggle="modal" data-target="#modifyModal" onClick={() => this.loadRegistry(x.id)}/></td><td>{x.id}</td><td>{displaydate(x.created_at)}</td><td>{displaydate(x.updated_at)}</td><td>{x.key}</td><td>{x.role}</td><td>{x.value}</td></tr>
		)
	      })
	    }
	  </tbody>
	</table>


	<div className="modal fade" id="modifyModal" tabIndex="-1" role="dialog" aria-labelledby="modifyModalLabel" aria-hidden="true">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">

	      <div className="modal-header">
		<h5 className="modal-title" id="modifyModalLabel">Modify registry</h5>
		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	      </div>

	      <div className="modal-body">
		<form>
		  
		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Key</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentregistry['key']} onChange={(e) => this.handleChange('key', e.target.value)} />
		    </div>
		  </div>

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Role</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentregistry['role']} onChange={(e) => this.handleChange('role', e.target.value)} />
		    </div>
		  </div>

		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Value</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentregistry['value']} onChange={(e) => this.handleChange('value', e.target.value)} />
		    </div>
		  </div>

		  <div className="modal-footer">
		    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.handleUpdateRegistry()}>Save</button>
		    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
		  </div>		  
		</form>
	      </div>
	      
	    </div>
	  </div>
	</div>
	
      </React.Fragment>
    );
  }
}

export default Registry
