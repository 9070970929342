import React from "react"
import PropTypes from "prop-types"
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = (theme) => ({
  root: {
    //width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
});

class SearchStock extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  /* stocks = id,code,name */
  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      tickers: [],
      results: [],
      acvalue: [],
      currentstock: {code: ''},
      currentstockid: '',      
      updating: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/admin/stock.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  
	  data.sort(function (a, b) {
	      return (a.code).localeCompare(b.code);
	  });
	  this.setState({ stocks: data })
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeValue(value) {
    //console.log(value)
    const newValue = value.reduce((a,b) => {
      a.push(b.code || b.toUpperCase())
      return a
    }, [])
    this.setState({tickers: newValue, acvalue: value})
    this.state.results = []

    value.forEach(stock => {
      fetch('/api/v1/admin/stock/'+stock.id)
	.then((response) => {return response.json()})
	.then((data) => {
	  if (this._isMounted && data.status != 500) {
	    const newresults = this.state.results.slice()
	    newresults.push(data)
	    newresults.sort(function (a, b) {
	      return (a.code).localeCompare(b.code);
	    });
	    
	    this.setState({ results: newresults })
	  }
	})
    })
  }

  handleDelete(id) {
    fetch('/api/v1/admin/stock/'+id,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((stock) => {
       if(stock.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+stock.message, type: 'error' }) } else {

	 const stocks = this.state.stocks.slice()
	 var newstocks = stocks.filter((s) => s.id != stock.id)

	 const tickers = this.state.tickers.slice()
	 var newtickers = tickers.filter((s) => s != stock.code)

	 const results = this.state.results.slice()
	 var newresults = results.filter((s) => s.id != stock.id)

	 const acvalue = this.state.acvalue.slice()
	 var newacvalue = acvalue.filter((s) => s.code != stock.code)
	 
	 
	 this.setState( {stocks: newstocks, tickers: newtickers, results: newresults, acvalue: newacvalue} )

       }
     })
  }

  handleForceUpdate(id) {
    this.setState( {updating: true} )
    fetch('/api/v1/admin/forceupdatestock/'+id,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((stock) => {
       if(stock.status == 500) {
	 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update : '+stock.message, type: 'error' })
	 this.setState( {updating: false} )
       } else {
	 const stocks = this.state.stocks.slice()
	 var newstocks = stocks.filter((s) => s.id != stock.id)
	 newstocks.push({id: stock.id, code: stock.code, name: stock.name})
	 
	 const tickers = this.state.tickers.slice()
	 var newtickers = tickers.filter((s) => s != stock.code)
	 newtickers.push(stock.code)

	 const results = this.state.results.slice()
	 var newresults = results.filter((s) => s.id != stock.id)
	 newresults.push(stock)
	 newresults.sort(function (a, b) {
	   return (a.code).localeCompare(b.code);
	 });
	 
	 this.setState( {stocks: newstocks, tickers: newtickers, results: newresults, updating: false} )
       }
     })
  }

  handleUpdateStock() {
    const body = JSON.stringify({stock: this.state.currentstock})

    fetch('/api/v1/admin/stock/'+this.state.currentstockid,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		     .then((stock) => {
		       if(stock.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update stock :'+stock.message, type: 'error' })
		       }
		       else {
			 const stocks = this.state.stocks.slice()
			 var newstocks = stocks.filter((s) => s.id != stock.id)
			 newstocks.push({id: stock.id, code: stock.code, name: stock.name})
			 
			 const tickers = this.state.tickers.slice()
			 var newtickers = tickers.filter((s) => s != stock.code)
			 newtickers.push(stock.code)

			 const results = this.state.results.slice()
			 var newresults = results.filter((s) => s.id != stock.id)
			 newresults.push(stock)
			 newresults.sort(function (a, b) {
			   return (a.code).localeCompare(b.code);
			 });
			 
			 this.setState( {stocks: newstocks, tickers: newtickers, results: newresults, updating: false} )
		       }
		     })
  }
  
  loadStock(id) {
    const cs = this.state.stocks.find(s => s.id == id)
    var currentstock = {}
    currentstock['code'] = cs['code']

    this.setState( {currentstock: currentstock, currentstockid: id} )
  }

  handleChange(field, value) {
    var newcs = Object.assign({}, this.state.currentstock)
    newcs[field] = value
    this.setState( {currentstock: newcs} )    
  }
  
  render () {
    const { classes } = this.props;

    function displaydate(d) {
      return (new Date(d)).toLocaleString()
    }
    
    function displaystock(x) {
      const keys = (Object.keys(x)).sort()
      const prop = keys.map((y) => {

	if(y == 'closes' || y == 'volumes' || y == 'ohlc') {
	  return(
	    <div key={y} className="my-0">
	      <a href="#" className="text-dark" data-toggle="collapse" data-target={"#collapse"+y+x.id} aria-expanded="true" aria-controls={"collapse"+y+x.id}>
		<strong>{y}</strong> : <i className="fa fa-eye"/>
              </a>
	      <div id={"collapse"+y+x.id} className="collapse" aria-labelledby={"#collapse"+y+x.id} data-parent={"#collapse"+y+x.id}>
		{x[y]}
	      </div>	    
	    </div>)
	} else if(y == 'created_at' || y == 'updated_at') {
	  return <p key={y} className="my-0"><strong>{y}</strong> : {displaydate(x[y])}</p>
	}
	else {
	  return <p key={y} className="my-0"><strong>{y}</strong> : {x[y]}</p>
	}
      })

      
      return prop
    }


    //console.log(this.state.stocks)
    //console.log(this.state.tickers)
    //console.log(this.state.acvalue)
    
    return (
      <React.Fragment>
	<div className={classes.root}>
	  <Autocomplete
	    value={this.state.acvalue}
	    multiple
	    size="small"
	    id="tags-standard"
	    onChange={(event, newValue) => this.changeValue(newValue)}
	    options={this.state.stocks}
	    getOptionLabel={option => option.code+' ('+option.name+')'}
	    renderOption={(option) => <span>{option.code+' ('+option.name+')'}</span>}
	    renderTags={(value, getTagProps) =>
	      this.state.tickers.map((code,index) => (
		<Chip variant="default" size="small" color={'primary'} label={code} {...getTagProps({ index })} />
	      ))
	    }
	    renderInput={(params) => (
              <TextField {...params} variant="standard" label="Stock tickers" placeholder="Enter your tickers" />
	    )}
	    getOptionSelected={(option) => this.state.tickers.includes(option.code)  }
	  />
	</div>


	{
	  this.state.results.map((x) => {
	    return(
	      <div key={x.id} className="py-2">
		<div className="card">
		  <div className="card-body">
		    { displaystock(x) }

		    {
		      this.state.updating &&
		      <div className="mt-2"><span><i className="fas fa-spinner fa-pulse"></i> Please wait...</span></div>
		    }
		    { !this.state.updating &&
		      <div className="mt-2"><button className="btn btn-sm btn-outline-dark" data-toggle="modal" data-target="#modifyModal" onClick={() => this.loadStock(x.id)}>Modify</button> <button className="btn btn-sm btn-outline-dark" onClick={() => this.handleForceUpdate(x.id)}>Force Update</button> <button className="btn btn-sm btn-outline-dark" onClick={() => window.confirm("Are you sure you wish to delete "+x.code+" ?") && this.handleDelete(x.id)}>Delete</button></div>
		    }
		  </div>
		</div>
	      </div>
	    )
	  })
	}

	<div className="modal fade" id="modifyModal" tabIndex="-1" role="dialog" aria-labelledby="modifyModalLabel" aria-hidden="true">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      
	      <div className="modal-header">
		<h5 className="modal-title" id="modifyModalLabel">Modify stock</h5>
		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	      </div>

	      <div className="modal-body">
		<form>
		  <div className="form-group row">
		    <label className="col-sm-2 col-form-label">Code</label>
		    <div className="col-sm-10">
		      <input type="text" className="form-control" value={this.state.currentstock['code']} onChange={(e) => this.handleChange('code', e.target.value)} />
		    </div>
		  </div>	  
		  
		  <div className="modal-footer">
		    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.handleUpdateStock()}>Save</button>
		    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
		  </div>
		</form>
	      </div>
	      
	    </div>
	  </div>
	</div>	

	
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(SearchStock);
