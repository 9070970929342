import React from "react"
import PropTypes from "prop-types"
import ScreenerItem from "../components/ScreenerItem"

class Screener extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  constructor(props) {
    super(props);
    this.state = {
      watchlists: [],
      watchlistid: 0,
      help: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    
    fetch('/api/v1/watchlists.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted) {
	  this.updateState(data)
	}
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangeTab(watchlistid) {
    const watchlist = this.state.watchlists.find(p => p.id == watchlistid)
    this.setState({ watchlistid: watchlistid })
  }
  
  updateState(data) {
    // sort by name
    data.sort((function (a, b) {
      return a.name.localeCompare(b.name);
    }))

    let watchlistid = this.state.watchlistid
    
    // initialize with the first tab
    if(watchlistid == 0 && data != null && data.length != 0)
    {
      watchlistid = data.find(e => true).id
    }

    let help = ''
    if(data.length == 0) {
      help =
	<div className="alert alert-secondary" role="alert">
	  You need to define at least one <a href="/watchlists">watchlist</a> in order to have stocks to screen
	</div>	
    }
    
    this.setState({ watchlists: data, watchlistid: watchlistid, help: help })
  }

  
  render () {
    const watchlists = this.state.watchlists.map((watchlist) => {
      let classname = "btn btn-link mx-1 my-1 shadow-none text-decoration-none"
      if(watchlist.id == this.state.watchlistid) {
	classname = "btn btn-primary mx-1 my-1 shadow-none"
      }
      
      return(
	<li key={watchlist.id} className="nav-item">
	  <button type="button" className={classname} href="#" onClick={() => this.handleChangeTab(watchlist.id)}>{watchlist.name}</button>
	</li>
      )
    }
    )
    
    return (
      <React.Fragment>
	{this.state.help}
	<ul className="nav nav-pills">
	  {watchlists}
	</ul>

	<ScreenerItem watchlistId={this.state.watchlistid} />
      </React.Fragment>
    );
  }
}

export default Screener
