import React from "react"
import PropTypes from "prop-types"
import Alert from "../components/Alert"

// https://medium.com/@veelenga/displaying-rails-flash-messages-with-react-5f82982f241c
class FlashMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: props.messages };
    window.flash_messages = this;
  }

  /* addMessage(message) {
   *   this.setState({ messages: this.state.messages.concat(message) });
   * } */

  addMessage(message) {
    let newmessages = this.state.messages.slice();
    newmessages.push(message)
    this.setState({messages: newmessages})
  }

  /* removeMessage(message) {
   *   let newmessages = this.state.messages.slice();
   *   const index = newmessages.indexOf(message)    
   *   if(index != -1) { newmessages.splice(index, 1) }    
   *   this.setState({messages: newmessages})
   * } */
  
  render () {

    return(
      <div>
        {this.state.messages.map( message =>
	  <Alert key={ message.id } message={ message } />
	)}
	</div>
    );
  }
}

export default FlashMessages
