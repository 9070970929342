import React from "react"
import PropTypes from "prop-types"
import nextId from "react-id-generator"

// cycle d initialisation : https://fr.reactjs.org/docs/react-component.html
class CheckBoxes extends React.Component {
  _isMounted = false;

  // data : en provenance de this.props.data
  // checkboxes : clef=id valeur=true/false
  // ids : les ids selectionnes
  // disabled : les ids a griser
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      checkboxes: {},
      ids: [],
      disabled: []
    }
  }
    
  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.data != this.props.data) {
      const checkboxes = this.props.data.reduce(function(x,i) {x[i.id] = false ; return x}, {})
      this.setState( {checkboxes: checkboxes, data: this.props.data.slice()} )
    }

    if(prevProps.disabled != this.props.disabled) {
      this.setState( {disabled: this.props.disabled} )
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(id, value) {
    let newcheckboxes =  Object.assign({}, this.state.checkboxes);
    newcheckboxes[id] = value
    const ids = Object.entries(newcheckboxes).filter(x => x[1] == true).map(x => x[0])    
    this.setState({ checkboxes: newcheckboxes, ids: ids })
    this.props.onChange(ids)
  }
  
  render () {   
    const display_list = this.state.data.map((obj) => {
      const isDisabled = this.state.disabled.includes(obj.id)      
      return(
	<div key={obj.id} className="form-check text-break">
	  <input name="checkboxes" type="checkbox" checked={this.state.checkboxes[obj.id] } onChange={(e) => this.handleChange(obj.id, e.target.checked)} className="form-check-input" id={nextId()} disabled={isDisabled} />
	  <label className="form-check-label" htmlFor={obj.id}><span className={isDisabled ? '' : 'text-primary'}>{obj.name}</span></label>
	</div>
      )
    }
    )

    
    return (
      <React.Fragment>
	{ display_list }
      </React.Fragment>
    );
  }
}

export default CheckBoxes
