import React from "react"
import PropTypes from "prop-types"

class Alert extends React.Component {

  componentDidMount() {
    this.timer = setTimeout(function() {
      $(".alert").alert('close');
    }, 7000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }
  
  alertClass (type) {
    let classes = {
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info',
      success: 'alert-success'
    };
    return classes[type] || classes.success;
  }

  render() {
    const message = this.props.message;
    const alertClassName = `alert ${ this.alertClass(message.type) } alert-dismissible fade show`;
        
    return(
      <React.Fragment>
	<div className={ alertClassName } role="alert">
	  { message.text }
          <button type="button" className='close' data-dismiss='alert' aria-label="Close">
	    <span aria-hidden="true">&times;</span>
	  </button>
	</div>
      </React.Fragment>
    );
  }
}

export default Alert
