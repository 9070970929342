import React from "react"
import PropTypes from "prop-types"
class Sample extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/sample.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  console.log(data)
	  this.setState({  })
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render () {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

export default Sample
