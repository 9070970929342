import React from "react"
import PropTypes from "prop-types"
import StockCard from "../components/StockCard"
import EtfCard from "../components/EtfCard"
import FundCard from "../components/FundCard"
import CryptoCard from "../components/CryptoCard"


class StockShow extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      stock: '',
      loading: true,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ stock: this.props.stock, loading: false })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  
  render () {
    return (
      <React.Fragment>
	{
	  this.state.loading && <div className="row justify-content-center mt-2"><span><i className="fas fa-spinner fa-pulse"></i> Loading...</span></div>
	}

	{ this.state.stock && this.state.stock.detail.assettype == 'common_stock' && !this.state.loading &&
	  <StockCard stock={this.state.stock} />
	}
	{ this.state.stock && this.state.stock.detail.assettype == 'etf' && !this.state.loading &&
	  <EtfCard stock={this.state.stock} />
	}
	{ this.state.stock && this.state.stock.detail.assettype == 'fund' && !this.state.loading &&
	  <FundCard stock={this.state.stock} />
	}
	{ this.state.stock && this.state.stock.detail.assettype == 'crypto' && !this.state.loading &&
	  <CryptoCard stock={this.state.stock} />
	}
      </React.Fragment>
    );
  }
}

export default StockShow
