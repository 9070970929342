import React from "react"
import PropTypes from "prop-types"

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CheckoutForm from '../components/CheckoutForm'
import CancelSubscription from '../components/CancelSubscription'

class Subscription extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      stripePromise: loadStripe(this.props.stripe_public_key),
      customerId: this.props.customer_id,
      priceId: this.props.price_id,
      planStatus: '',
      expiration: '',
      cbBrand: '',
      cbLast4: '',
      subscriptionId: '',
      loading: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateSubscriptionState()
  }

  updateSubscriptionState() {
    //console.log('updateSubscriptionState')
    
    fetch('/api/v1/subscription')
	   .then((response) => {return response.json()})
	   .then((data) => {
	     if (this._isMounted) {
	       //console.log(data)
	       const local_expiration = (new Date(data.expiration)).toLocaleString()
	       this.setState({ planStatus: data.planStatus, expiration: local_expiration, cbBrand: data.cbBrand, cbLast4: data.cbLast4, subscriptionId: data.subscriptionId })
	     }
	   })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  
  
  render () {    
    let planStatusView
    //console.log(this.state.cbLast4)
    if(this.state.planStatus == 'trial') {
      planStatusView =
	<React.Fragment>
	  <div className="d-flex flex-row">
	    <div className="flex-grow-1">Ending date</div>
	    <div className="font-weight-bold text-secondary">{this.state.expiration}</div>
	  </div>

	  <Elements stripe={this.state.stripePromise}>
	    <CheckoutForm customerId={this.state.customerId} priceId={this.state.priceId} updateSubscriptionState={this.updateSubscriptionState.bind(this)} parent={this} />
	  </Elements>
	</React.Fragment>
    }

    if(this.state.planStatus == 'premium' && this.state.cbLast4 != null) {
      planStatusView =
	<React.Fragment>
	  <div className="d-flex flex-row">
	    <div className="flex-grow-1 text-secondary">Credit card</div>
	    <div className="text-capitalize font-weight-bold text-secondary">{this.state.cbBrand} .... {this.state.cbLast4}</div>
	  </div>
	  <CancelSubscription subscription_id={this.state.subscriptionId} updateSubscriptionState={this.updateSubscriptionState.bind(this)}/>
	</React.Fragment>
    }

    if(this.state.planStatus == 'expired') {
      planStatusView =
	<React.Fragment>
	  <Elements stripe={this.state.stripePromise}>
	    <CheckoutForm customerId={this.state.customerId} priceId={this.state.priceId} updateSubscriptionState={this.updateSubscriptionState.bind(this)} parent={this} />
	  </Elements>
	</React.Fragment>
    }
    
    return (
      <React.Fragment>
	<div className="mt-4 border rounded p-4 w-50">
	  <div className="mb-2 font-weight-bold">Account</div>
	  <div className="d-flex flex-row">
	    <div className="flex-grow-1 text-secondary">Current plan</div>
	    <div className="text-capitalize font-weight-bold text-secondary">{this.state.planStatus}</div>
	  </div>

	  
	  { planStatusView }

	</div>
      </React.Fragment>
    );
  }
}

export default Subscription
