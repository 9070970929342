import React from "react"
import PropTypes from "prop-types"
class Repair extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      tickers_repaired: '',
      updating: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleUpdateFailed() {
    this.setState( {updating: true} )
    const body = ""
    fetch('/api/v1/admin/updatefailedstock',
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		     .then((stocks) => {
		       if(stocks.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot repair stocks :'+stocks.message, type: 'error' })
			 this.setState( {updating: false} )
		       }
		       else {
			 var tickers = stocks.reduce((a,b) => {
			   a.push(b.code)
			   return a
			 },[])
			 
			 this.setState( {updating: false, tickers_repaired: tickers.join(' ')} )
		       }
		     })    
  }

  
  render () {
    return (
      <React.Fragment>
	<div className="py-2">
	  <div className="card">
	    <div className="card-body">
	      <h5>Repair tools</h5>
	      { this.state.updating  ? <span><i className="fas fa-spinner fa-pulse"></i> Please wait...</span> : <button className="btn btn-sm btn-outline-dark" onClick={() => this.handleUpdateFailed()}>Auto update failed stocks</button>} {this.state.tickers_repaired != '' && <span>Updated : {this.state.tickers_repaired}</span>}
	    </div>
	  </div>
	</div>
      </React.Fragment>
    );
  }
}

export default Repair
