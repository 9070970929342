import React from "react"
import PropTypes from "prop-types"
import Zoomable from "../components/Zoomable"

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
  Area,
  Bar,
  ResponsiveContainer
} from "recharts";



class Rainbow {
  constructor(size) {
    this.size = size
  }

  getcolor() {
    function sin_to_hex(i, phase) {
      var sin = Math.sin(Math.PI / size * 2 * i + phase);
      var int = Math.floor(sin * 127) + 128;
      var hex = int.toString(16);

      return hex.length === 1 ? "0"+hex : hex;
    }
    
    let rainbow = new Array(this.size);
    for (var i=0; i<size; i++) {
      var red   = sin_to_hex(i, 0 * Math.PI * 2/3); // 0   deg
      var blue  = sin_to_hex(i, 1 * Math.PI * 2/3); // 120 deg
      var green = sin_to_hex(i, 2 * Math.PI * 2/3); // 240 deg
      rainbow[i] = "#"+ red + green + blue;
    }
    
    return rainbow
  }
}



class BacktestChart extends React.Component {
  _isMounted = false;
  _jsondata = '/api/v1/backtest/chart.json'

  constructor(props) {
    super(props);
    
    this.state = {
      identity: [],
      data: [],
      leftaxis: '',
      rightaxis: '',
      help: '',
      loading: true
    }
  }

  updateState(data) {  
    // identity: [ {id:, name:} ...]
    const identity = data.identity
    

    // data example : [ {"date": "xx", "portfolio1": "xx", "portfolio2": "xx"},  {"date": "yy", "portfolio1": "yy", "portfolio2": "yy"}]
    const newdata = data.data.map(x => {
      const newdate = Date.parse(x.date)
      x.date = newdate
      return x
    })

    let help = ''
    if(identity.length == 0) {
      help =
	<div className="alert alert-secondary" role="alert">
	  Create at least two <a href="/portfolios">portfolios</a> in order to compare
	</div>	
    }    
    
    this.setState({ identity: identity, data: newdata, help: help, loading: false })
  }
  

  componentDidMount() {
    this._isMounted = true;
    
    fetch(this._jsondata)
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted) {
	  //console.log(data)
	  this.updateState(data)
	}
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toPercentage(f) {
    if(f!=null && f>=0) {
      return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
    } else
    {
      return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
    }
  }

  handleChange(axis, value) {
    let newaxis = {}
    newaxis[axis] = value
    this.setState(newaxis);
  }
  
  
  render () {

    const selectoptions = this.state.identity.map((x) => {
      const name = x['name']
      return(
	<option key={x.id} value={name}>{name}</option>
      )
    })

    
    return (
      <React.Fragment>
	{this.state.help}

	{ this.state.loading && <div className="row justify-content-center mt-2"><span><i className="fas fa-spinner fa-pulse"></i> Loading...</span></div> }
	{ ! this.state.loading && this.state.help == '' &&
	  <div className="mt-2">
	    <div className="row mb-2">
	      <div className="col-sm">
		<select value={this.state.leftaxis} className="custom-select custom-select-sm" onChange={(e) => this.handleChange("leftaxis", e.target.value)}>
		  <option value="">Select one portfolio</option>
		  {selectoptions}
		</select>
	      </div>
	      <div className="col-sm">
		<select value={this.state.rightaxis} className="custom-select custom-select-sm" onChange={(e) => this.handleChange("rightaxis", e.target.value)}>
		  <option value="">Select another portfolio to compare</option>
		  {selectoptions}
		</select>
	      </div>	  
	    </div>	
	    
	    <ResponsiveContainer>
	      <Zoomable data={this.state.data} leftaxis={this.state.leftaxis} rightaxis={this.state.rightaxis} />
	    </ResponsiveContainer>
	  </div> 
	}
      </React.Fragment>
    );
  }
}

export default BacktestChart
