import React from "react"
import PropTypes from "prop-types"

class StockDetail extends React.Component {

  
  render () {
    function toPercentage(f,bottom, top) {
      if(f!=null && bottom!=null && f<bottom) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && top!=null && f>top) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }      
    }

    function toNumber(f,bottom, top) {
      if(f!=null && top!=null && f>top) {
	return <span className="text-danger">{f.toFixed(2)}</span>
      } else if(f!=null && bottom!=null && f<bottom) {
	return <span className="text-success">{f.toFixed(2)}</span>
      } else if(f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }
    }
    
    const stock = this.props.stock
    return (
      <React.Fragment>
	{ stock.detail &&
	  <div key={stock.id} className="row">
	    <div className="col-sm-8">
	      <div className="card bg-light">

		<div className="card-body">
		  <h5 className="card-title">{ stock.detail.name }</h5>
		  <p className="card-text small">{ stock.detail.description }</p>
		  <p className="card-text small">{ stock.detail.address }</p>
		  <p className="card-text small text-uppercase">{ stock.detail.exchange } {(stock.detail.assettype) ? '/' : ''} { stock.detail.assettype && stock.detail.assettype.replace('_',' ') } {(stock.detail.currency) ? '/' : ''} {stock.detail.currency}</p>
		  <p className="card-text small">{ stock.detail.sector } {(stock.detail.industry) ? '/' : ''} { stock.detail.industry }</p>
		</div>
	      </div>
	    </div>

	    { (stock.detail.assettype) ?
	      <div className="col-sm-4">
		<div className="card bg-light">
		  <div className="card-body">
		    <table className="table table-borderless table-sm">
		      <thead>
			<tr>
			  <th scope="col"/>
			  <th scope="col"/>
			</tr>
		      </thead>
		      <tbody>
			<tr>
			  <th scope="row">EV To Ebitda</th>
			  <td>{ toNumber(stock.detail.evtoebitda,10,14) }</td>
			</tr>
			<tr>
			  <th scope="row">PEG</th>
			  <td>{ toNumber(stock.detail.pegratio,1.0,1.01) }</td>
			</tr>
			<tr>
			  <th scope="row">Price To Sales TTM</th>
			  <td>{ toNumber(stock.detail.pricetosalesratiottm,2,4) }</td>
			</tr>
			<tr>
			  <th scope="row">Operating Margin</th>
			  <td>{ toPercentage(stock.detail.operatingmarginttm,0,null) }</td>
			</tr>
			<tr>
			  <th scope="row">Profit Margin</th>
			  <td>{ toPercentage(stock.detail.profitmargin,0,null) }</td>
			</tr>		    
			<tr>
			  <th scope="row">Return On Equity TTM</th>
			  <td>{ toPercentage(stock.detail.returnonequityttm,0.10,0.14) }</td>
			</tr>		    
			<tr>
			  <th scope="row">Return On Assets TTM</th>
			  <td>{ toPercentage(stock.detail.returnonassetsttm,0,null) }</td>
			</tr>
			<tr>
			  <th scope="row">Price To Book</th>
			  <td>{ toNumber(stock.detail.pricetobookratio,3, null) }</td>
			</tr>		    
			<tr>
			  <th scope="row">PER</th>
			  <td>{ toNumber(stock.detail.perratio,null,null) }</td>
			</tr>
			<tr>
			  <th scope="row">Dividend Yield</th>
			  <td>{ toPercentage(stock.detail.dividendyield,0,null) }</td>
			</tr>
			<tr>
			  <th scope="row">UPI</th>
			  <td>{ toNumber(stock.upi,null,null) }</td>
			</tr>		      
		      </tbody>		  
		    </table>
		  </div>
		</div>
	      </div>
	      : <div className="col-sm-4"/>
	    }
	    
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default StockDetail
