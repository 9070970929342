import React from "react"
import PropTypes from "prop-types"
import StockDetail from "../components/StockDetail"

class ScreenerItem extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted
  _rules = ['rule1','rule2','rule3','rule4','rule5'];

  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      entries_filtered: [],
      stock_list: [],
      rule1: '',
      rule2: '',
      rule3: '',
      rule4: '',
      rule5: '',
      limit: '',
      saved: '',
      stockdetail: '',
      loading: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.watchlistId != this.props.watchlistId ) {
      //console.log("componentDidUpdate:"+prevProps.watchlistId+"=>"+this.props.watchlistId)
      this.updateScreenerState()
    }
  }
  
  updateScreenerState() {
    this.setState( {entries: [], entries_filtered: [], stock_list: [], rule1: '', rule2: '', rule3: '', rule4: '', rule5: '', limit: '', saved: '', stockdetail: '', loading: true} )
    
    const watchlistid = this.props.watchlistId
    fetch('/api/v1/watchlists/'+watchlistid+'/screener')
			    .then((response) => {return response.json()})
			    .then((data) => {

			      if (this._isMounted && data.status != 500) {

				let newentries = data.entries			  
				newentries.sort(
				  (b,a) => {
				    if(a.stock.mom6m < b.stock.mom6m) {
				      return -1;
				    }
				    if(a.stock.mom6m > b.stock.mom6m) {
				      return 1;
				    }
				    return 0;
				  }
				)

				
				let currentstate = this.state
				currentstate.entries = newentries
				currentstate.rule1 = data.rule1
				currentstate.rule2 = data.rule2
				currentstate.rule3 = data.rule3
				currentstate.rule4 = data.rule4
				currentstate.rule5 = data.rule5
				currentstate.limit = data.limit
				currentstate.saved = data.saved

				this.updateState(currentstate)
			      }
			    })    
  }
  
  
  handleChange(name, value) {
    //console.log("handleChange "+name+" => "+value)

    let currentstate = this.state
    currentstate[name] = value
    this.updateState(currentstate)
  }

  displaydetail(stock) {
    this.setState({stockdetail: stock})
  }

  //copyToClipboard() {
  //  console.log('copyToClipboard '+this.state.stock_list)
  //  navigator.clipboard.writeText(this.state.stock_list)
  //}

  
  updateState(currentstate) {   
    function ruleToJS(rule) {
      const regex1 = /mom12m/gi
      const regex2 = /proximitysma10m/gi
      const regex3 = /evtoebitda/gi
      const regex4 = /pegratio/gi
      const regex5 = /pricetosalesratiottm/gi

      const regex6 = /upi/gi
      const regex7 = /profitmargin/gi
      const regex8 = /operatingmarginttm/gi
      const regex9 = /returnonequityttm/gi
      const regex10 = /dividendyield/gi
      const regex11 = /perratio/gi
      const regex12 = /returnonassetsttm/gi
      const regex13 = /pricetobookratio/gi
      const regex14 = /proximityhigh52/gi
      const regex15 = /mom6m/gi
      
      const regex100 = / and /gi
      const regex101 = / or /gi
      

      
      const js1 = rule.replace(regex1,'x.stock.mom12m')
      const js2 = js1.replace(regex2,'x.stock.proximitysma10m')
      const js3 = js2.replace(regex3,'x.stock.detail.evtoebitda')
      const js4 = js3.replace(regex4,'x.stock.detail.pegratio')
      const js5 = js4.replace(regex5,'x.stock.detail.pricetosalesratiottm')
      const js6 = js5.replace(regex6,'x.stock.upi')
      const js7 = js6.replace(regex7,'x.stock.detail.profitmargin')
      const js8 = js7.replace(regex8,'x.stock.detail.operatingmarginttm')
      const js9 = js8.replace(regex9,'x.stock.detail.returnonequityttm')
      const js10 = js9.replace(regex10,'x.stock.detail.dividendyield')
      const js11 = js10.replace(regex11,'x.stock.detail.perratio')
      const js12 = js11.replace(regex12,'x.stock.detail.returnonassetsttm')
      const js13 = js12.replace(regex13,'x.stock.detail.pricetobookratio')
      const js14 = js13.replace(regex14,'x.stock.proximityhigh52')
      const js15 = js14.replace(regex15,'x.stock.mom6m')
      
      const js100 = js15.replace(regex100,' && ')
      const js101 = js100.replace(regex101,' || ')
      //console.log(js101)
      return js101
    }

    let entries_filtered = currentstate.entries.slice()  
    
    try {
      this._rules.forEach(rule => {
	if(currentstate[rule]) {
	  entries_filtered = entries_filtered.filter( x => eval(ruleToJS(this.state[rule])) )
	}
      })
    } catch (error) {
      console.log(error.message)
    }

    const stock_list = entries_filtered.map(x => x.stock.code)  

    currentstate.entries_filtered = entries_filtered.slice(0,this.state.limit)
    currentstate.stock_list = stock_list.slice(0,this.state.limit)
    currentstate.loading = false

    //navigator.clipboard.writeText(stock_list.join(' '))
    this.setState(currentstate)
  }

  
  handleExportToPortfolio() {
    const tickers = this.state.entries_filtered.reduce(function(ac,entry) {ac.push(entry.stock.code);return ac;}, [])
    const body = JSON.stringify({screener: {tickers: tickers.join(' ')} })

    fetch('/api/v1/watchlists/'+this.props.watchlistId+'/export_screener_to_portfolio',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()}).then((res) => {
      if(res.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot export screener :'+res.message, type: 'error' }) } else { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Portfolio created', type: 'success' }) } 
    })
  }

  
  handleSaveRules() {
    this.setState({saved: true})
    const tickers = this.state.entries_filtered.reduce(function(ac,entry) {ac.push(entry.stock.code);return ac;}, [])
    const body = JSON.stringify({screener: {rule1: this.state.rule1, rule2: this.state.rule2, rule3: this.state.rule3, rule4: this.state.rule4, rule5: this.state.rule5, limit: this.state.limit, tickers: tickers.join(' ')} })

    fetch('/api/v1/watchlists/'+this.props.watchlistId+'/screener',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()}).then((res) => {
      if(res.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot save screener :'+res.message, type: 'error' }) } 
    })
  }

  handleResetRules() {
    this.setState({saved: false})
    fetch('/api/v1/watchlists/'+this.props.watchlistId+'/screener/0',
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
	.then((list) => {
	  if(list.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot reset screener : '+list.message, type: 'error' }) } else {
	    this.updateScreenerState()
	  }
	}) 
  }
  
  render () {   
    function toPercentage(f) {
      if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <span/>
      }      
    }

    function toNumber(f) {
      if(f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <span/>
      }
    }    
    
    const entries_table = this.state.entries_filtered.map((entry) => {
      return(
	<tr key={entry.id}>
	  <td>
	    <a href={'/stocks/'+entry.stock.code} className="btn btn-sm btn-light" onMouseEnter={() => this.displaydetail(entry.stock)} onMouseLeave={() => this.displaydetail('')}>
	      {entry.stock.code}
	    </a>
	  </td>
	  <td className="align-middle">{toPercentage(entry.stock.mom6m)}</td>
	  <td className="align-middle">{toPercentage(entry.stock.proximitysma10m)}</td>
	  <td className="align-middle">{toNumber(entry.stock.detail.evtoebitda)}</td>
	  <td className="align-middle">{toNumber(entry.stock.detail.pegratio)}</td>
	  <td className="align-middle">{toNumber(entry.stock.detail.pricetosalesratiottm)}</td>
	</tr>
      )
    })
    
    return (
      <React.Fragment>
	<div className="container-fluid">
	  <div className="form-group row">
	    <label className="col-sm-1 col-form-label">Rule #1</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="text" value={this.state.rule1} onChange={(e) => this.handleChange('rule1', e.target.value)}/></div>

	    <label className="col-sm-1 col-form-label">Rule #2</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="text" value={this.state.rule2} onChange={(e) => this.handleChange('rule2', e.target.value)}/></div>
	  </div>


	  <div className="form-group row">
	    <label className="col-sm-1 col-form-label">Rule #3</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="text" value={this.state.rule3} onChange={(e) => this.handleChange('rule3', e.target.value)}/></div>

	    <label className="col-sm-1 col-form-label">Rule #4</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="text" value={this.state.rule4} onChange={(e) => this.handleChange('rule4', e.target.value)}/></div>
	  </div>
	  
	  <div className="form-group row">
	    <label className="col-sm-1 col-form-label">Rule #5</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="text" value={this.state.rule5} onChange={(e) => this.handleChange('rule5', e.target.value)}/></div>

	    <label className="col-sm-1 col-form-label">Max results</label> <div className="col-sm-4"><input disabled={this.state.saved} className="form-control" type="number" value={this.state.limit} onChange={(e) => this.handleChange('limit', e.target.value)}/></div>
	  </div>
	</div>

	<div className="my-2">
	  <button disabled={this.state.saved} className="btn btn-secondary btn-sm" onClick={() => this.handleSaveRules()} >Save rules</button> <button disabled={!this.state.saved} className="btn btn-secondary btn-sm" onClick={() => this.handleResetRules()}>Reset rules</button> <button disabled={!this.state.saved} className="btn btn-secondary btn-sm" onClick={() => this.handleExportToPortfolio()}>Export to portfolio</button> <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#helpModal"><i className="fas fa-question-circle"></i> Help</button> { this.state.loading && <span><i className="fas fa-spinner fa-pulse"></i> Loading...</span> }
	</div>

	
	{ !this.state.loading &&
	  <div className="table-responsive">
	    <table className="table table-sm table-hover">
	      <thead>
		<tr>
		  <th>Code #{ this.state.entries_filtered.length }</th>
		  <th data-toggle="tooltip" data-placement="right" title="mom6m>0 is a positive trend">Mom6m</th>
		  <th data-toggle="tooltip" data-placement="right" title="above-sma10m>0 is a positive trend">Above sma10m</th>
		  <th data-toggle="tooltip" data-placement="right" title="ev-to-ebitda<10 is healthy">EV To Ebitda</th>
		  <th data-toggle="tooltip" data-placement="right" title="peg<1 is healthy">PEG</th>
		  <th data-toggle="tooltip" data-placement="right" title="p-to-s<2 is healthy">Price To Sales TTM</th>
		</tr>
	      </thead>
	      <tbody>
		{ entries_table }
	      </tbody>
	    </table>
	  </div>
	}
	
	{
	  this.state.stockdetail && <div className="stockdetail"><StockDetail stock={this.state.stockdetail} /></div>
	}

	{ /* help modal */ }
	<div className="modal fade" id="helpModal" tabIndex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      <div className="modal-header">
		<h5 className="modal-title" id="helpModalLabel">Help on Screener</h5>
		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	      </div>
	      <div className="modal-body">
		<p className="card-text">Use the screener to apply filters on your watchlist</p>
		<p className="card-text">Once you have a combination of rules, click on the <strong>save rules</strong> button, then, you will be able to create a portfolio from the stocks you found with the <strong>export to portfolio</strong> button</p>
		
		<h5>You can combine any of these filters</h5>
		<ul>
		  <li>mom12m</li>
		  <li>mom6m</li>
		  <li>proximitysma10m</li>
		  <li>evtoebitda</li>
		  <li>pegratio</li>
		  <li>pricetosalesratiottm</li>
		  <li>upi</li>
		  <li>profitmargin</li>
		  <li>operatingmarginttm</li>
		  <li>returnonequityttm</li>
		  <li>pricetosalesratiottm</li>
		  <li>dividendyield</li>
		  <li>perratio</li>
		  <li>returnonassetsttm</li>
		  <li>pricetobookratio</li>
		  <li>proximityhigh52</li>
		  <li>and</li>
		  <li>or</li>
		</ul>
	      </div>
	      <div className="modal-footer">
		<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
	      </div>
	    </div>
	  </div>
	</div>
	
      </React.Fragment>
    );
  }
}

export default ScreenerItem
