import React from "react"
import PropTypes from "prop-types"
import WatchlistItem from "../components/WatchlistItem"

class WatchlistIndex extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  /*
   * watchlists : curl http://localhost:3000/api/v1/watchlists.json | jq .
   * ref : hash of watchlist id => ref to child component
   */
  constructor(props) {
    super(props);
    this.state = {
      watchlists: [],
      ref: React.createRef(),
      watchlistid: 0,
      name: '',
      newname: '',
      help: '',
      editwatchlistid: 0
    }
  }

  updateStateAndRef(data, mywatchlistid=0) {   
    let myref = React.createRef()

    // sort by name
    data.sort((function (a, b) {
      return a.name.localeCompare(b.name);
    }))

    let watchlistid = mywatchlistid
    
    // initialize with the first tab
    if(watchlistid == 0 && data != null && data.length != 0)
    {
      watchlistid = data.find(e => true).id
    }

    let name = ''
    const newname = ''
    const watchlist = data.find(p => p.id == watchlistid)


    const help = <div className="alert alert-secondary" role="alert">Add your first watchlist : type a name in the field below and press ENTER</div>	


    if(watchlist != null) {
      name = watchlist.name
      this.setState({ watchlists: data, ref: myref, watchlistid: watchlistid, name: name, newname: newname, help: '', editwatchlistid: 0 })
    } else { this.setState({ watchlists: [], ref: myref, watchlistid: 0, name: '', newname: '', help: help, editwatchlistid: 0 }) }
  }
  
  componentDidMount() {
    this._isMounted = true;
    
    fetch('/api/v1/watchlists.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted) {
	  this.updateStateAndRef(data)
	}
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  

  handleCreate(e) {
    e.preventDefault();
    const name = this.state.newname
    //console.log('handleCreate ' + name);

    let body = JSON.stringify({watchlist: {name: name} })
    
    fetch('/api/v1/watchlists',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((watchlist) => {
		     if(watchlist.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot create watchlist : '+watchlist.message, type: 'error' }) } else {
		       
		       let newWatchlists = this.state.watchlists.slice().concat(watchlist)
		       newWatchlists.sort((function (a, b) {
			 return a.name.localeCompare(b.name);
		       }))

		       this.updateStateAndRef(newWatchlists, watchlist.id)
		     }
		   })
    e.target.reset();
  }


  handleUpdateWatchlist(e) {
    e.preventDefault();
    //console.log("handleUpdateWatchlist")

    const watchlist_id = this.state.watchlistid
    const name = this.state.name
    let body = JSON.stringify({watchlist: {name: name} })

    fetch('/api/v1/watchlists/'+watchlist_id,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((watchlist) => {
		     if(watchlist.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update watchlist : '+watchlist.message, type: 'error' }) } else {

		       let newWatchlists = this.state.watchlists.filter((p) => p.id != watchlist_id)
		       newWatchlists.push(watchlist)
		       newWatchlists.sort((function (a, b) {
			 return a.name.localeCompare(b.name);
		       }))

		       this.updateStateAndRef(newWatchlists, watchlist_id)
		     }
		   }) 
    
    if(e.type == 'submit') { e.target.reset() }
  }

  

  handleDelete(id) {
    //console.log('handleDelete ' + id);

    fetch('/api/v1/watchlists/'+id,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((watchlist) => {
       if(watchlist.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+watchlist.message, type: 'error' }) } else {
	 let newWatchlists = this.state.watchlists.slice().filter((watchlist) => watchlist.id !== id)
	 this.updateStateAndRef(newWatchlists)
       }
     })    
  }  


  handleChange(name, value) {
    //console.log("handleChange "+name+" => "+value)
    let newstate = {}
    newstate[name] = value    
    this.setState(newstate)
  }
  
  handleChangeTab(watchlistid) {
    const watchlist = this.state.watchlists.find(p => p.id == watchlistid)
    const newname = ''
    const name = watchlist.name
    
    this.setState({ watchlistid: watchlistid, newname: newname, name: name, editwatchlistid: 0 })
  }

  toggleEditName(watchlistid) {
    this.setState( {editwatchlistid: watchlistid} )
  }  
  
  render () {
    const watchlists = this.state.watchlists.map((watchlist) => {
      let classname = "btn btn-link mx-1 my-1 shadow-none text-decoration-none"
      if(watchlist.id == this.state.watchlistid) {
	classname = "btn btn-primary mx-1 my-1 shadow-none"
      }
      
      return(
	<li key={watchlist.id} className="nav-item">
	  { this.state.editwatchlistid != watchlist.id &&
	    <button type="button" className={classname} href="#" onClick={() => this.handleChangeTab(watchlist.id)} onDoubleClick={() => this.toggleEditName(watchlist.id)}>{watchlist.name}</button>
	  }
	  {
	    this.state.editwatchlistid == watchlist.id &&
	    <form className="form-inline" onSubmit={(e) => this.handleUpdateWatchlist(e)}><input type="text" className={classname+" form-control"} value={this.state.name} onChange={(e) => this.handleChange('name', e.target.value)} /></form>
	  }	  
	</li>
      )
    }
    )



    return (
      <React.Fragment>
	{this.state.help}
	<ul className="nav nav-pills">
	  {watchlists}
	  <li className="nav-item">
	    <form className="mx-2 my-1 form-inline" onSubmit={(e) => this.handleCreate(e)}>
	      <input type="text" className="form-control form-control-sm w-50" id="watchlistName" placeholder="Add new" value={this.state.newname} onChange={(e) => this.handleChange('newname',e.target.value)}/>
	    </form>
	  </li>
	</ul>
	
	<WatchlistItem ref={this.state.ref} watchlistId={this.state.watchlistid} />

	{ (this.state.watchlistid != 0) ?
	  <React.Fragment>
	    <button type="button" className="mx-2 btn btn-sm btn-outline-primary" onClick={(e) => this.state.ref.current.handleUpdateItemsFromParent()}>Save</button>
	    
	    <button type="button" className="mx-2 btn btn-sm btn-outline-danger" onClick={ () => window.confirm("Are you sure you wish to delete watchlist "+this.state.name+" ?") && this.handleDelete(this.state.watchlistid) }>Delete '{this.state.name}'</button>
	  </React.Fragment>
	  : <React.Fragment/> }
	
      </React.Fragment>
    );
  }
}

export default WatchlistIndex
