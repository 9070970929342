import React, { PureComponent } from 'react';
import {
  Label, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea, Legend
} from 'recharts';
import {sortino,maxdrawdown} from "../components/Ratio"



export default class Zoomable extends PureComponent {

  constructor(props) {
    super(props);

    const initialState = {
      graphdata: [],
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: 'dataMax',
      bottom: 'dataMin',
      top2: 'dataMax',
      bottom2: 'dataMin',
      animation: true,
      sortino1: 0,
      sortino2: 0,
      maxdd1: 0,
      maxdd2: 0
    };
    
    this.state = initialState;
  }



  
  /*
     from: from date
     to: to date
     ref: portfolio name
     https://www.fool.com/knowledge-center/how-to-convert-daily-returns-to-annual-returns.aspx
   */
  getSortino(from, to, ref) {
    let indexfrom = 0
    let indexto = this.props.data.length

    if(from != 'dataMin') {
      indexfrom = this.props.data.findIndex(item => item.date == from)
    }

    if(to != 'dataMax') {
      indexto = this.props.data.findIndex(item => item.date == to) +1
    }

    const refData = this.props.data.slice(indexfrom, indexto);
    const values = refData.map(x => x[ref])
    let returns = []
    for(let i=1; i<values.length;i++) {
      returns.push((values[i]-values[i-1])*100/values[i-1])
    }

    //const safereturn = (Math.pow(1+2.5/100, 1/365)-1)*100

    return sortino(returns)
  }

  getMaxDrawdown(from, to, ref) {
    let indexfrom = 0
    let indexto = this.props.data.length

    if(from != 'dataMin') {
      indexfrom = this.props.data.findIndex(item => item.date == from)
    }

    if(to != 'dataMax') {
      indexto = this.props.data.findIndex(item => item.date == to) +1
    }
    const refData = this.props.data.slice(indexfrom, indexto);
    const values = refData.map(x => x[ref])
    return maxdrawdown(values)
  }

  
  
  getAxisYDomain (from, to, ref) {
    const indexfrom = this.state.graphdata.findIndex(item => item.date == from)
    const indexto = this.state.graphdata.findIndex(item => item.date == to) +1
    const refData = this.state.graphdata.slice(indexfrom, indexto);
    return this.bottom_top(refData, ref)
  }

  bottom_top(refData, ref) {   
    const offset = 2

    if(refData.length == 0) {
      return ['dataMin', 'dataMax']
    }
    
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });


    return [bottom-offset, top+offset];
  }  


  recalculateGraphData(from, to) {
    //console.log("recalculateGraphData from:"+from+" to:"+to)
    
    let indexfrom = 0
    let indexto = this.props.data.length
    
    if(from != 'dataMin') {
      indexfrom = this.props.data.findIndex(item => item.date == from)
    }

    if(to != 'dataMax') {
      indexto = this.props.data.findIndex(item => item.date == to) +1
    }
    const refData = this.props.data.slice(indexfrom, indexto);

    // data example : [ {"date": "xx", "portfolio1": "xx", "portfolio2": "xx"},  {"date": "yy", "portfolio1": "yy", "portfolio2": "yy"}]
    let newgraphdata = refData.map(x => {
      let newobj = {}
      for(const key in x) {
	if(key == "date") { newobj.date = x["date"] } else
	{ newobj[key] =  (x[key] - refData[0][key])*100/refData[0][key] }
      }
      return newobj
    })

    this.state.graphdata = newgraphdata
    //this.setState( {graphdata: newgraphdata} )
  }


  recalibrateGraph(month, keep=false) {
    //console.log("recalibrate graphdata:"+month)   
    
    const fulldata = this.props.data.slice()
    const fulldatarev = fulldata.slice().reverse()

    var leftdate = 0
    var rightdate = 0
    if(this.props.leftaxis) {
      const leftindex = fulldata.findIndex(x => x[this.props.leftaxis]>0)
      if(leftindex != -1) { leftdate = fulldata[leftindex]['date'] }
    }
    if(this.props.rightaxis) {
      const rightindex = fulldata.findIndex(x => x[this.props.rightaxis]>0)
      if(rightindex != -1) { rightdate = fulldata[rightindex]['date'] }
    }
    var begindate = leftdate > rightdate ? leftdate : rightdate
    
    leftdate = 0
    rightdate = 0
    if(this.props.leftaxis) {
      const leftindex = fulldatarev.findIndex(x => x[this.props.leftaxis]>0)
      if(leftindex != -1) { leftdate = fulldatarev[leftindex]['date'] }
    }
    if(this.props.rightaxis) {
      const rightindex = fulldatarev.findIndex(x => x[this.props.rightaxis]>0)
      if(rightindex != -1) { rightdate = fulldatarev[rightindex]['date'] }
    }      
    var enddate = leftdate > rightdate ? leftdate : rightdate

    // reducing timeframe
    if(month > 0) {
      var date = new Date();
      //date.setFullYear(date.getFullYear() - year);
      date.setMonth(date.getMonth() - month)
      const timems = date.getTime();
      
      leftdate = 0
      rightdate = 0
      if(this.props.leftaxis) {
	const leftindex = fulldata.findIndex(x => x['date'] >= timems)
	if(leftindex != -1) { leftdate = fulldata[leftindex]['date'] }
      } else if(this.props.rightaxis) {
	const rightindex = fulldata.findIndex(x => x['date'] >= timems)
	if(rightindex != -1) { rightdate = fulldata[rightindex]['date'] }
      }
      if(leftdate > begindate) { /*console.log("begindate "+begindate+"->"+leftdate) ;*/ begindate = leftdate }
      if(rightdate > begindate) { /*console.log("begindate "+begindate+"->"+rightdate) ;*/ begindate = rightdate }
    }
    //

    // try to keep actual timeframe
    if(keep) {
      if(this.state.left != 'dataMin' && this.state.left > begindate) { begindate = this.state.left}
      if(this.state.right != 'dataMax' && this.state.right < enddate) { enddate = this.state.right}
    }
    //
    
    //console.log("begindate:"+begindate+" enddate:"+enddate)
    
    this.recalculateGraphData(begindate, enddate)
    this.state.left = begindate == 0 ? 'dataMin' : begindate
    this.state.right = enddate == 0 ? 'dataMax' : enddate

    const gd = this.state.graphdata.slice()

    const [bottom1,top1] = this.bottom_top(gd, this.props.leftaxis)
    const sortino1 = this.props.leftaxis ? this.getSortino(this.state.left,this.state.right,this.props.leftaxis) : 0
    const maxdd1 = this.props.leftaxis ? this.getMaxDrawdown(this.state.left,this.state.right,this.props.leftaxis) : 0
    const [bottom2,top2] = this.bottom_top(gd, this.props.rightaxis)
    const sortino2 = this.props.rightaxis ? this.getSortino(this.state.left,this.state.right,this.props.rightaxis) : 0
    const maxdd2 = this.props.rightaxis ? this.getMaxDrawdown(this.state.left,this.state.right,this.props.rightaxis) : 0
    this.setState( {bottom: bottom1, top: top1, sortino1: sortino1, maxdd1: maxdd1, bottom2: bottom2, top2: top2, sortino2: sortino2, maxdd2: maxdd2} )
  }
  

  
  //exemple this.props.data = [{date: 941414400000, dia: 1, qqq: 1, spy: 1}, {date: 941500800000, dia: 0.9924882629116081, qqq: 1.000764525995137, spy: 0.9928535103736967}]
  componentDidUpdate(prevProps) {
    //console.log('this.state.graphdata.length:'+this.state.graphdata.length)
    //console.log('this.props.data.length:'+this.props.data.length)
    //console.log('prevProps.leftaxis:'+prevProps.leftaxis)
    //console.log('prevProps.rightaxis:'+prevProps.rightaxis)

    
    if(this.state.graphdata.length == 0 && this.props.data.length > 0) {
      this.recalculateGraphData(this.state.left, this.state.right)
      
    } else if(prevProps.leftaxis != this.props.leftaxis || prevProps.rightaxis != this.props.rightaxis) {

      if(this.props.leftaxis || this.props.rightaxis) {
	this.recalibrateGraph(0, true)
      } else {
	const sortino1 = this.props.leftaxis ? this.state.sortino1 : 0
	const maxdd1 = this.props.leftaxis ? this.state.maxdd1 : 0
	const sortino2 = this.props.rightaxis ? this.state.sortino2 : 0
	const maxdd2 = this.props.rightaxis ? this.state.maxdd2 : 0
	this.setState( {sortino1: sortino1, sortino2: sortino2, maxdd1: maxdd1, maxdd2: maxdd2} )
      }
    }
  }
  
  zoom() {    
    let { refAreaLeft, refAreaRight } = this.state;
    
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      //[refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      let newrefAreaLeft = refAreaRight
      let newrefAreaRight = refAreaLeft
      refAreaLeft = newrefAreaLeft
      refAreaRight = newrefAreaRight
    }

    this.recalculateGraphData(refAreaLeft, refAreaRight)
    
    // yAxis domain
    const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.props.leftaxis);
    const [bottom2, top2] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.props.rightaxis);


    let sortino1 = this.state.sortino1
    let maxdd1 = this.state.maxdd1
    let sortino2 = this.state.sortino2
    let maxdd2 = this.state.maxdd2
    
    if(this.props.leftaxis) {
      sortino1 = this.getSortino(refAreaLeft,refAreaRight,this.props.leftaxis)
      maxdd1 = this.getMaxDrawdown(refAreaLeft,refAreaRight,this.props.leftaxis)
    }
    if(this.props.rightaxis) {
      sortino2 = this.getSortino(refAreaLeft,refAreaRight,this.props.rightaxis)
      maxdd2 = this.getMaxDrawdown(refAreaLeft,refAreaRight,this.props.rightaxis)
    }
    
    
    this.setState(() => ({
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      bottom2,
      top2,
      sortino1: sortino1,
      sortino2: sortino2,
      maxdd1: maxdd1,
      maxdd2: maxdd2
    }));

    
  }

  zoomMonth(month) {
    if(this.props.leftaxis || this.props.rightaxis) {
      this.recalibrateGraph(month)
    }
  }

      
  toPercentage(f) {
    if(f!=null && f>=0) {
      return <span>{(f*100).toFixed(2)+' %'}</span>
    } else
    {
      return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
    }
  }


  toNumber(f) {
    if(f!=null && f>=0) {
      return <span>{(f).toFixed(2)}</span>
    } else
    {
      return <span className="text-danger">{(f).toFixed(2)}</span>
    }
  }
  
  render() {   
    const {
      barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2,
    } = this.state;
    
    //const width = 800
    //const height = 400
    const width = window.innerWidth*0.75
    const height = width*0.5

    let minbottom = bottom
    let maxtop = top
    if(bottom2 != "dataMin" && bottom2 < bottom) { minbottom = bottom2 }
    if(top2 != "dataMax" && top2 > top) { maxtop = top2 }

    const disabled = !(this.props.leftaxis || this.props.rightaxis)
    
    return (
      <React.Fragment>

	<div className="my-1">
	  <i className="fas fa-lg fa-search-plus"></i> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(0)}>Full</button> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(3)}>3m</button> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(6)}>6m</button> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(12)}>1y</button> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(36)}>3y</button> <button disabled={disabled} type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.zoomMonth(60)}>5y</button>
	</div>
	
	<div className="highlight-bar-charts" style={{ userSelect: 'none' }}>
	  <LineChart width={width} height={height} data={this.state.graphdata} onMouseDown={e => this.setState({ refAreaLeft: e.activeLabel })} onMouseMove={e => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })} onMouseUp={this.zoom.bind(this)}>
	    <XAxis allowDataOverflow dataKey="date" domain={[left, right]} type="number" tickFormatter={v => (new Date(v)).toLocaleDateString()}/>
	    <YAxis allowDataOverflow unit="%" domain={[minbottom, maxtop]} type="number" tickFormatter={v => v.toFixed()}/>

	    <Tooltip position={{x: width, y: height*0.01}} labelFormatter={v => (new Date(v)).toLocaleDateString()} formatter={(value,name,props) => {return [value.toFixed(2)+"%",name]} }  />
	    <Line type="monotone" dataKey={this.props.leftaxis} stroke="#8884d8" animationDuration={300} dot={false}/>
	    <Line type="monotone" dataKey={this.props.rightaxis} stroke="#82ca9d" animationDuration={300} dot={false}/>
	    { (refAreaLeft && refAreaRight) ? (<ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3}/>) : null }
	    <Legend />
	  </LineChart>
	</div>
	
	<table className="table table-sm">
	  <thead>
	    <tr><th className="w-25" /><th className="w-25">Sortino</th><th className="w-25">MaxDD</th></tr>
	  </thead>
	  <tbody>
	    <tr><td>{(this.props.leftaxis) ? this.props.leftaxis : '-'}</td><td>{this.toNumber(this.state.sortino1)}</td><td>{this.toPercentage(this.state.maxdd1)}</td></tr>
	    <tr><td>{(this.props.rightaxis) ? this.props.rightaxis : '-' }</td><td>{this.toNumber(this.state.sortino2)}</td><td>{this.toPercentage(this.state.maxdd2)}</td></tr>
	  </tbody>
	</table>
	
      </React.Fragment>
    )
  }
}
