import React from "react"
import PropTypes from "prop-types"
import PortfolioItem from "../components/PortfolioItem"

// https://css-tricks.com/react-forms-using-refs/
class PortfolioIndex extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted

  /*
   * portfolios : curl http://localhost:3000/api/v1/portfolios.json | jq .
   * ref : hash of portolio id => ref to child component
   */
  constructor(props) {
    super(props);
    this.state = {
      portfolios: [],
      ref: React.createRef(),
      portfolioid: 0,
      beginnav: 0,
      cash: 0,
      name: '',
      newname: '',
      help: '',
      editportfolioid: 0
    }
  }

  updateStateAndRef(data, myportfolioid=0) {
    //console.log('updateStateAndRef myportfolioid=' + myportfolioid)
    //console.log(data)
    
    let myref = React.createRef()

    // sort by name
    data.sort((function (a, b) {
      return a.name.localeCompare(b.name);
    }))

    let portfolioid = myportfolioid
    
    // initialize with the first tab
    if(portfolioid == 0 && data != null && data.length != 0)
    {
      portfolioid = data.find(e => true).id
    }



    let beginnav = 0
    let cash = 0
    let name = ''
    const newname = ''
    
    const portfolio = data.find(p => p.id == portfolioid)
    if(portfolio != null) {
      beginnav = portfolio.beginnav
      cash = portfolio.cash
      name = portfolio.name
    }

    let help = ''
    if(data.length == 0) {
      help =
	<div className="alert alert-secondary" role="alert">
	  Add your first portfolio : type a name in the field below and press ENTER
	</div>	
    }  
    
    
    this.setState({ portfolios: data, ref: myref, portfolioid: portfolioid, beginnav: beginnav, cash: cash, newname: newname, name: name, help: help, editportfolioid: 0 })
  }

  
  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/portfolios.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted) {
	  this.updateStateAndRef(data)
	}
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  
  handleCreate(e) {   
    e.preventDefault();
    const name = this.state.newname
    //console.log('handleCreate ' + name);

    let body = JSON.stringify({portfolio: {name: name, beginnav: 100000, cash: 0} })
    
    fetch('/api/v1/portfolios',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((portfolio) => {
		     if(portfolio.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot create portfolio : '+portfolio.message, type: 'error' }) } else {

		       const currentportfolio = this.state.portfolios.slice()
		       let newPortfolios = currentportfolio.concat(portfolio)
		       newPortfolios.sort((function (a, b) {
			 return a.name.localeCompare(b.name);
		       }))

		       this.updateStateAndRef(newPortfolios, portfolio.id)
		     }
		   })
    e.target.reset();
  }
  


  handleDelete(id) {
    //console.log('handleDelete ' + id);

    fetch(`/api/v1/portfolios/${id}`,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((portfolio) => {
       if(portfolio.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+portfolio.message, type: 'error' }) } else {

	 const currentportfolios = this.state.portfolios.slice()
	 let newPortfolios = currentportfolios.filter((portfolio) => portfolio.id !== id)
	 
	 this.updateStateAndRef(newPortfolios)
       }
     })    
  }

  handleDuplicate() {
    //console.log('handleDuplicate');

    fetch('/api/v1/portfolios/'+this.state.portfolioid+'/duplicate',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((portfolio) => {
       if(portfolio.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+portfolio.message, type: 'error' }) } else {

	 var currentportfolios = this.state.portfolios.slice()
	 currentportfolios.push(portfolio)	 
	 this.updateStateAndRef(currentportfolios)
       }
     })
  }


  
  handleUpdatePortfolio(e) {
    e.preventDefault();
    //console.log('handleUpdatePortfolio');

    const newcash = this.state.cash || 0
    const newbeginnav = this.state.beginnav || 0
    const portfolio_id = this.state.portfolioid
    const name = this.state.name

    if(isNaN(newcash)) { newcash = 0 }
    if(isNaN(newbeginnav)) { newbeginnav = 0 }
    
    
    let body = JSON.stringify({portfolio: {cash: newcash, beginnav: newbeginnav, name: name} })

    fetch('/api/v1/portfolios/'+portfolio_id,
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((portfolio) => {
		     if(portfolio.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update portfolio : '+portfolio.message, type: 'error' }) } else {

		       let newPortfolios = this.state.portfolios.filter((p) => p.id != portfolio_id)
		       newPortfolios.push(portfolio)
		       newPortfolios.sort((function (a, b) {
			 return a.name.localeCompare(b.name);
		       }))

		       this.updateStateAndRef(newPortfolios, portfolio_id)
		     }
		   })

    this.state.ref.current.handleUpdateItemsFromParent() // call child method

    // button save clicked
    if(e.type == 'click') { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Portfolio saved', type: 'success' }) }

    // pressed enter to change the name
    if(e.type == 'submit') { e.target.reset() }
  }
  

  numberToMoney(n) {
    let formatter = new Intl.NumberFormat(undefined);
    return formatter.format(n); 
  }


  handleChangeTab(portfolioid) {
    const portfolio = this.state.portfolios.find(p => p.id == portfolioid)
    const beginnav = portfolio.beginnav
    const cash = portfolio.cash
    const newname = ''
    const name = portfolio.name
    
    this.setState({ portfolioid: portfolioid, beginnav: beginnav, cash: cash, newname: newname, name: name, editportfolioid: 0 })
  }


  handleChange(name, value) {
    //console.log("handleChange "+name+" => "+value)
    let newstate = {}
    newstate[name] = value    
    this.setState(newstate)
  }

  
  toggleEditName(portfolioid) {
    this.setState( {editportfolioid: portfolioid} )
  }
  


  
  render () {   
    const portfolios = this.state.portfolios.map((portfolio) => {
      let classname = "btn btn-link mx-1 my-1 shadow-none text-decoration-none"
      if(portfolio.id == this.state.portfolioid) {
	classname = "btn btn-primary mx-1 my-1 shadow-none"
      }
      
      return(
	<li key={portfolio.id} className="nav-item">
	  { this.state.editportfolioid != portfolio.id &&
	    <button type="button" className={classname} href="#" onClick={() => this.handleChangeTab(portfolio.id)} onDoubleClick={() => this.toggleEditName(portfolio.id)}>{portfolio.name}</button>
	  }
	  {
	    this.state.editportfolioid == portfolio.id &&
	    <form className="form-inline" onSubmit={(e) => this.handleUpdatePortfolio(e)}><input type="text" className={classname+" form-control"} value={this.state.name} onChange={(e) => this.handleChange('name', e.target.value)} /></form>
	  }
	</li>
      )
    }
    )

    
    
    return (
      <React.Fragment>
	{this.state.help}
	<ul className="nav nav-pills">
	  {portfolios}
	  <li className="nav-item">
	    <form className="mx-2 my-1 form-inline" onSubmit={(e) => this.handleCreate(e)}>
	      <input type="text" className="form-control form-control-sm w-50" id="portfolioName" placeholder="Add new" value={this.state.newname} onChange={(e) => this.handleChange('newname', e.target.value)} />
	    </form>
	  </li>
	</ul>
	


	{ (this.state.portfolioid != 0) ?
	  <div>
	    <form id="nav_cash_form" className="form-inline">
	      
	      <div className="form-group mr-2">
		<label className="bg-light text-dark rounded p-1 mr-2 mt-2" data-toggle="tooltip" data-placement="right" title="Beginning net asset value (used to calculate your performance)">Beginning NAV</label>
		<input type="text" className="form-control form-control-sm mt-2" value={this.state.beginnav} onChange={(e) => this.handleChange('beginnav', e.target.value)}/>
	      </div>

	      <div className="form-group mr-2">
		<label className="bg-light text-dark rounded p-1 mr-2 mt-2" data-toggle="tooltip" data-placement="right" title="Cash available">Cash</label>
		<input type="text" className="form-control form-control-sm mt-2" value={this.state.cash} onChange={(e) => this.handleChange('cash', e.target.value)}/>
	      </div>
	      
	    </form>
	  </div>
	  : <React.Fragment/> }
	

	
	<PortfolioItem ref={this.state.ref} portfolioId={this.state.portfolioid} cash={this.state.cash} beginnav={this.state.beginnav}/>


	{ (this.state.portfolioid != 0) ?
	  <React.Fragment>
	    <button type="button" className="mx-2 btn btn-sm btn-outline-primary" onClick={(e) => this.handleUpdatePortfolio(e)}>Save</button>

	    <button type="button" className="mx-2 btn btn-sm btn-outline-danger" onClick={ () => window.confirm("Are you sure you wish to delete portfolio "+this.state.name+" ?") && this.handleDelete(this.state.portfolioid) }>Delete '{this.state.name}'</button>

	    <button type="button" className="mx-2 btn btn-sm btn-outline-dark" onClick={() => this.handleDuplicate()}>Duplicate</button>	    
	  </React.Fragment>
	  : <React.Fragment/> }


      </React.Fragment>
    );
  }
}

export default PortfolioIndex
