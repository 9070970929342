import React from "react"
import PropTypes from "prop-types"
import CheckBoxes from "../components/CheckBoxes"

class BackupRestore extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted


  constructor(props) {
    super(props);
    this.state = {
      watchlists: [],
      portfolios: [],
      watchlists_todl: [],
      portfolios_todl: [],
      files: []
    }
  }

  handleUpload(e) {
    e.preventDefault();
    if(this.state.files.length > 0) { 

      const formData = new FormData();
      formData.append('body', this.state.files[0]);
      
      //fetch('/api/v1/backuprestore/upload', { method: 'POST', headers: {'Content-Type': 'application/json' }, body: this.state.files[0] } )
      fetch('/api/v1/backuprestore/upload', { method: 'POST', body: formData } )
	.then((response) => { return response.json()})
	.then((data) => {
	  if(data.status == 500) {
	    window.flash_messages.addMessage({ id: 'id'+Math.random(), text: data.message, type: 'error' })
	  }
	  else {
	    window.flash_messages.addMessage({ id: 'id'+Math.random(), text: data.message, type: 'success' })
	  }
	})

      this.setState( {files: []} )
    }
    e.target.reset();
  }
  
  handleDownload(e) {
    e.preventDefault();
    
    function camelcase(str) {
      return str.toLowerCase().replace(/(?:_| |\b)(\w)/g, function(str, p1) { return p1.toUpperCase()});
    }

    let body = JSON.stringify( {portfolios: this.state.portfolios_todl, watchlists: this.state.watchlists_todl} )
    
    fetch('/api/v1/backuprestore/download', { method: 'POST', headers: {'Content-Type': 'application/json' }, body: body } )
		   .then((response) => {return response.blob()})
		   .then((blob) => {
		     var timestamp = Math.round(new Date().getTime() / 1000);
		     
		     var objurl = window.URL.createObjectURL(blob);
		     let link = document.createElement('a');
		     link.href = objurl;
		     link.download = camelcase('backup_portfoliotracker_'+timestamp)+'.json';
		     link.click();	
		     //window.location.assign(file);
		   })

    //e.target.reset();
  }

  componentDidMount() {
    this._isMounted = true;

    fetch('/api/v1/backuprestore.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted) {
	  this.setState( {watchlists: data.watchlists, portfolios: data.portfolios} )
	}
      })    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePorfolio(ids) {
    this.setState( {portfolios_todl: ids} )
  }

  handleWatchlist(ids) {
    this.setState( {watchlists_todl: ids} )
  }

  setSelectedFile(e) {
    this.setState( {files: []} )
    var files = e.target.files;

    if(files.length == 0) { return }

    const filename = files[0].name.toLowerCase()
    const size = files[0].size
    const filetype = files[0].type.toLowerCase()
    
    if(!filename.endsWith('.json')) {
      window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Filename must end with .json', type: 'error' })
      return false
    }

    if(size > 2000000) {
      window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Max file size is 2 mb', type: 'error' })
      return false 
    }

    if(filetype != "application/json") {
      window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'File is not a json', type: 'error' })
      return false
    }
    
    this.setState( {files: files} )
    
  }

  
  render () {
    const isDisabled = this.state.watchlists_todl.length + this.state.portfolios_todl.length == 0
    const isDisabled2 = this.state.files.length == 0
    
    return (
      <React.Fragment>
	
	<div className="card bg-light mt-2">
	  <h5 className="card-header">Backup your portfolios and watchlists</h5>
	  <div className="card-body">
	    <form onSubmit={(e) => this.handleDownload(e)}>

	      <div className="row">
		<div className="col-sm-6">
		  <h5 className="card-title">Portfolios</h5>
		  <CheckBoxes data={this.state.portfolios} onChange={(e) => this.handlePorfolio(e)}/>
		</div>

		<div className="col-sm-6">
		  <h5 className="card-title">Watchlists</h5>
		  <CheckBoxes data={this.state.watchlists} onChange={(e) => this.handleWatchlist(e)}/>
		</div>
	      </div>

	      
	      <button type="submit" className="btn btn-secondary btn-sm" disabled={isDisabled}>Export to backup file</button>
	    </form>
	  </div>
	</div>


	<div className="card bg-light mt-2">
	  <h5 className="card-header">Restore your portfolios and watchlists</h5>
	  <div className="card-body">
	    <form onSubmit={(e) => this.handleUpload(e)}>
	      <div className="form-group">
		<div className="custom-file">
		  <input type="file" className="custom-file-input" id="validatedCustomFile" required onChange={(e) => this.setSelectedFile(e)} accept=".json" />
		  <label className="custom-file-label" htmlFor="validatedCustomFile">{this.state.files.length>0 ? <span>{this.state.files[0].name}</span> : <span>Choose a backup file...</span>}</label>
		</div>
		<small><i className="fa fa-info-circle" aria-hidden="true"></i> Existing portfolio or watchlist with the same name will be overwritten</small>
	      </div>
	      
	      <button type="submit" className="btn btn-secondary btn-sm" disabled={isDisabled2}>Restore</button>
	    </form>
	  </div>
	  
	</div>

	
      </React.Fragment>
    );
  }
}

export default BackupRestore
