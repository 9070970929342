import React from "react"
import PropTypes from "prop-types"
class PredefinedList extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      id: '',
      name: '',
      description: '',
      tickers: '',
      script: '',
      stringmetadata: '',
      role: 'undefined'
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/predefined_list.json')
      .then((response) => {return response.json()})
      .then((data) => {
	//console.log(data)
	if (this._isMounted && data.status != 500) {
	  this.updateList(data)
	}
      }) 
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  updateList(data) {
    data.sort((function (a, b) {
      return a.name.localeCompare(b.name);
    }))
    
    this.setState({ list: data })
  }

  reset() {
    this.setState({ id: '', name: '', description: '', tickers: '', script: '', stringmetadata: '', role: 'undefined' })
  }

  handleChange(name, value) {
    //console.log("handleChange "+name+" => "+value)
    let newstate = {}
    newstate[name] = value    
    this.setState(newstate)
  }


  handleCreateUpdate(e) {
    e.preventDefault();
    var metadata = this.state.stringmetadata ? JSON.parse(this.state.stringmetadata) : null
    
    let body = JSON.stringify({predefined_list: {name: this.state.name, description: this.state.description, tickers: (this.state.tickers).toUpperCase(), script: this.state.script, metadata: metadata, role: this.state.role} })
    
    if(this.state.id) {
      fetch('/api/v1/predefined_list/'+this.state.id,
    	    {
    	      method: 'PATCH',
    	      headers: {
    		'Content-Type': 'application/json'
    	      },
	      body: body
    	    }
      ).then((response) => {return response.json()})
       .then((list) => {
	 if(list.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update list : '+list.message, type: 'error' }) } else {

	   let newList = this.state.list.filter((p) => p.id != this.state.id)
	   newList.push(list)

	   this.updateList(newList)
	   $('#dialogModalCenter').modal('hide')
	   window.flash_messages.addMessage({ id: 'id'+Math.random(), text: list.name+' updated', type: 'success' })
	 }
       })
    } else {     
      fetch('/api/v1/predefined_list',
    	    {
    	      method: 'POST',
    	      headers: {
    		'Content-Type': 'application/json'
    	      },
	      body: body
    	    }
      ).then((response) => {return response.json()})
       .then((list) => {
	 if(list.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot create predefined list : '+list.message, type: 'error' }) } else {

	   const currentlist = this.state.list.slice()
	   let newList = currentlist.concat(list)

	   this.updateList(newList)
	   $('#dialogModalCenter').modal('hide')
	   window.flash_messages.addMessage({ id: 'id'+Math.random(), text: list.name+' created', type: 'success' })
	 }
       })
    }
    e.target.reset();    
  }

  
  handleDelete(id) {
    fetch(`/api/v1/predefined_list/${id}`,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((list) => {
       if(list.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+list.message, type: 'error' }) } else {

	 const currentlist = this.state.list.slice()
	 let newList = currentlist.filter((list) => list.id !== id)
	 
	 this.updateList(newList)
	 window.flash_messages.addMessage({ id: 'id'+Math.random(), text: list.name+' deleted', type: 'success' })
       }
     })    
  }


  loadId(id) {
    const list = this.state.list.find(e => e.id == id)
    this.setState({id: list.id, name: list.name || "", description: list.description || "", tickers: list.tickers || "", script: list.script || "", stringmetadata: list.metadata && JSON.stringify(list.metadata) || "", role: list.role || "undefined"})
  }

  handleExecuteScript() {   
    let body = JSON.stringify({predefined_list: {script: this.state.script} })
    
    fetch('/api/v1/predefined_list_execute',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    )
		   .then((response) => {return response.json()})
		   .then((res) => {
		     if(res.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update tickers :'+res.message, type: 'error' }) } else {
		       
		       this.setState({ tickers: res.tickers })
		       
		     }
		   })
  }
  

  
  render () {
    const accordion = this.state.list.map((list, index) => {
      return(
	<div className="card" key={"card"+list.id}>
	  <div className="card-header" id={"heading"+list.id}>
	    <h5 className="mb-0">
              <button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#collapse"+list.id} aria-expanded="false" aria-controls={"#collapse"+list.id}>
		{list.name} : {list.description} <span className="badge badge-secondary">{list.tickers.split(' ').filter(x => x).length}</span>
              </button>
	    </h5>
	  </div>

	  <div id={"collapse"+list.id} className="collapse" aria-labelledby={"heading"+list.id} data-parent="#accordion">
	    <div className="card-body">
	      <p className="text-capitalize"><span className="badge badge-info">Role</span> {list.role}</p>
              <p><span className="badge badge-info">Tickers</span> {list.tickers}</p>      
	      {list.script?<p><span className="badge badge-info">Script</span> {list.script}</p>:''}
	      {list.metadata?<p><span className="badge badge-info">Metadata</span> {JSON.stringify(list.metadata)}</p>:''}
	      
	      <button type="button" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#dialogModalCenter" onClick={ () => this.loadId(list.id) }>Modify</button> <button type="button" className="btn btn-outline-danger btn-sm" onClick={ () => this.handleDelete(list.id) }>Delete</button>
	    </div>
	  </div>
	</div>
      )
    })

    

    const execute_script_button = this.state.script && <button type="button" className="btn btn-info" onClick={ () => this.handleExecuteScript() }>Execute script</button>

    var validmetadata = true
    try { this.state.stringmetadata && JSON.parse(this.state.stringmetadata) } catch(e) {  validmetadata = false  }
    
    return (
    <React.Fragment>
      <button type="button" className="btn btn-primary my-2" data-toggle="modal" data-target="#dialogModalCenter" onClick={ () => this.reset() }>
	Create a new list
      </button>
      
      {/* modal window */}
      <div className="modal fade" id="dialogModalCenter" tabIndex="-1" role="dialog" aria-labelledby="dialogModalCenterTitle" aria-hidden="true">
	<div className="modal-dialog modal-dialog-centered" role="document">
	  <div className="modal-content">
	    <div className="modal-header">
	      <h5 className="modal-title" id="dialogModalLongTitle">{this.state.id ? 'Update' : 'Create a new'} list</h5>
	      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	      </button>
	    </div>
	    
	    <form onSubmit={(e) => this.handleCreateUpdate(e)}>
	      <div className="modal-body">
		<div className="form-group">
		  <label htmlFor="inputName">Name</label>
		  <input type="text" className="form-control" id="name" placeholder="Enter list name" onChange={(e) => this.handleChange('name', e.target.value)} value={this.state.name} />
		</div>
		<div className="form-group">
		  <label htmlFor="inputDescription">Description</label>
		  <input type="text" className="form-control" id="description" placeholder="Enter description" onChange={(e) => this.handleChange('description', e.target.value)} value={this.state.description} />
		</div>
		<div className="form-group">
		  <label htmlFor="inputRole">Role</label>
		  <select className="form-control" id="role" value={this.state.role} onChange={(e) => this.handleChange('role', e.target.value)}>
		    <option value="undefined">Undefined</option>
		    <option value="watchlist">Watchlist</option>
		    <option value="portfolio">Portfolio</option>
		    <option value="both">Both</option>
		  </select>		  
		</div>		
		<div className="form-group">
		  <label htmlFor="inputTickers">Tickers (separate by space)</label>
		  <textarea className="form-control" id="tickers" rows="3" placeholder="Tickers (separate by space)" onChange={(e) => this.handleChange('tickers', e.target.value)} value={this.state.tickers} ></textarea>
		</div>
		<div className="form-group">
		  <label htmlFor="inputScript">Single line script (Optional)</label>
		  <textarea className="form-control" id="script" rows="3" placeholder="Enter script if any" onChange={(e) => this.handleChange('script', e.target.value)} value={this.state.script} ></textarea>
		  
		</div>
		<div className="form-group">
		  <label htmlFor="inputMetadata">Metadata (Optional)</label>
		  <textarea className="form-control" id="metadata" rows="3" placeholder="Metadata if any" onChange={(e) => this.handleChange('stringmetadata', e.target.value)} value={this.state.stringmetadata} ></textarea>
		  
		</div>		
	      </div>
	      <div className="modal-footer">
		{ execute_script_button }
		<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
		<button disabled={!validmetadata} type="submit" className="btn btn-primary">{this.state.id ? 'Update' : 'Create'}</button>
	      </div>
	    </form>
	    
	  </div>
	</div>
      </div>

      

      <div id="accordion">
	{ accordion }
      </div>
    </React.Fragment>
    );
  }
}

export default PredefinedList

