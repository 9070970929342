import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  maintitle: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },  
  listroot: {
    width: '100%',
    //maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },  
});

class Visitor extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      markets: [],
      sectors: [],
      hotstocks: []
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/visitor.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  //console.log(data)
	  
	  const markets = data.markets.sort(function (a, b) {
	    return b.upi - a.upi;
	  });
	  
	  this.setState({ markets: markets, sectors: data.sectors, hotstocks: data.hotstocks}) 
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render () {
    const { classes } = this.props;

    function toPercentage(f) {
      if(f!=null && f>=0) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else
      {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      }
    }
    
    function displaysma(f) {
      if(f!=null && f > 0.8) {
	return <span className="p-1 badge badge-danger text-white">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && (f <= 0.8 && f > 0.5)) {
	return <span className="p-1 badge badge-warning text-white">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && (f <= 0.5 && f > 0.2)) {
	return <span className="p-1 badge badge-info text-white">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && (f <= 0.2)) {
	return <span className="p-1 badge badge-success text-white">{(f*100).toFixed(2)+' %'}</span>
      }
    }

    function displayvolume(f) {
      if(f!=null && f < 0.5) {
	return <span className="p-1 badge badge-light text-dark">{(f*100).toFixed(2)+' %'}</span>
      } else {
	return <span className="p-1 badge badge-dark text-white">{(f*100).toFixed(2)+' %'}</span>
      }
    }
    
    function displayevebitda(f) {
      if(f!= null && f != 0) {
	return <span>{f.toFixed(2)}</span>
      }
    }

    function displaypeg(f) {
      if(f!= null && f != 0) {
	return <span>{f.toFixed(2)}</span>
      }
    }

    function displayupi(f) {
      if(f!= null && f != 0) {
	return <span>{f.toFixed(2)}</span>
      }
    }

    
    return (
      <React.Fragment>
	<div className={classes.root}>
	  <div className={classes.maintitle}>
	    <Box textAlign="center" fontWeight="fontWeightMedium" fontSize="h6.fontSize" letterSpacing={6}>We help investors</Box>
	  </div>
	  
	  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}> 
	    <Grid item sm xs={12}> {/* colonne 1 */}
	      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		<Grid item xs>
		  <Paper className={classes.paper2} elevation={3}>
		    <Typography component="h1" variant="body2" gutterBottom>
		      Mix stocks, ETF and crypto currencies investment
		    </Typography>		    
		    <Typography variant="body2" gutterBottom>
		      Trace the movements of your individual holdings and see how your current allocation stacks up with your long-term goals
		    </Typography>
		    <Typography variant="body2" gutterBottom>
		      Backtest your allocation strategy up to 20 years
		    </Typography>
		    <Typography variant="body2" gutterBottom>
		      Screen the market to find assets with rewarding alpha
		    </Typography>
		    <Typography variant="body2" gutterBottom>
		      Keep it simple and import our predefined lists
		    </Typography>
		    <Button size="small" className="mt-3" variant="contained" disableElevation color="primary" data-toggle="modal" data-target="#loginModal">TRY 30 DAYS FREE</Button>
		  </Paper>
		</Grid>
	      </Grid>
	    </Grid>

	    
            <Grid item sm xs={12}> {/* colonne 2 */}
	      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		<Grid item xs>
		  <Paper className={classes.paper} elevation={3}>
		    <Typography component="h5" color="textPrimary" className={classes.bold}>
		      Market temperature
		    </Typography>
		    <TableContainer component={Paper} elevation={0}>
		      <Table size="small" aria-label="market temperature">
			<TableHead>
			  <TableRow>
			    <TableCell>Name</TableCell>
			    <TableCell align="right">Above sma200d</TableCell>
			    <TableCell align="right">Above sma100d</TableCell>
			    <TableCell align="right">Above sma50d</TableCell>
			    <TableCell align="right">High volume</TableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {this.state.markets.map((market) => (
			    <TableRow key={"markettemp"+market.name}>
			      <TableCell component="th" scope="row">
				<span className="text-capitalize">{market.name}</span>
			      </TableCell>
			      <TableCell align="right">{displaysma(market.sma200d)}</TableCell>
			      <TableCell align="right">{displaysma(market.sma100d)}</TableCell>
			      <TableCell align="right">{displaysma(market.sma50d)}</TableCell>
			      <TableCell align="right">{displayvolume(market.volumeabovesma200d)}</TableCell>
			    </TableRow>
			  ))}
			</TableBody>
		      </Table>
		    </TableContainer>
		  </Paper>
		</Grid>
		
		<Grid item xs>
		  <Paper className={classes.paper} elevation={3}>
		    <Typography component="h5" color="textPrimary" className={classes.bold}>
		      Market valuation
		    </Typography>
		    <TableContainer component={Paper} elevation={0}>
		      <Table size="small" aria-label="market valuation">
			<TableHead>
			  <TableRow>
			    <TableCell>Name</TableCell>
			    <TableCell align="right">UPI</TableCell>
			    <TableCell align="right">PEG</TableCell>
			    <TableCell align="right">EV/Ebitda</TableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {this.state.markets.map((market) => (
			    <TableRow key={"marketval"+market.name}>
			      <TableCell component="th" scope="row">
				<span className="text-capitalize">{market.name}</span>
			      </TableCell>
			      <TableCell align="right">{displayupi(market.upi)}</TableCell>
			      <TableCell align="right">{market.peg ? displaypeg(market.peg) : <i className="fa fa-sm fa-minus"/>}</TableCell>
			      <TableCell align="right">{market.evebitda ? displayevebitda(market.evebitda) : <i className="fa fa-sm fa-minus"/>}</TableCell>
			    </TableRow>
			  ))}
			</TableBody>
		      </Table>
		    </TableContainer>
		  </Paper>
		</Grid>
	      </Grid>
            </Grid>
	    
            <Grid item sm xs={12}> {/* colonne 3 */}
	      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		<Grid item xs>
		  <Paper className={classes.paper} elevation={3}>
		    <Typography component="h5" color="textPrimary" className={classes.bold}>
		      Top stocks
		    </Typography>


		    <List className={classes.listroot}>
		      {this.state.hotstocks.map((x) => (
			<ListItem key={x.role} alignItems="flex-start">
			  <ListItemText
			    secondary={
			      <React.Fragment>
				<Typography
				  component="span"
				  variant="body2"
				  className={classes.inline}
				  color="textPrimary"
				>
				  {x.role}
				</Typography>
				<Typography component="span" variant="caption">
				  {x.entries.map((l) => (<Link key={l.id} color='textSecondary' href={"/stocks/"+l.code}> {l.code} </Link>))}
				</Typography>
			      </React.Fragment>
			    }
			  />
			</ListItem>
		      ))}
		    </List>		    
		  </Paper>
		</Grid>
		
		<Grid item xs>
		  <Paper className={classes.paper} elevation={3}>
		    <Typography component="h5" color="textPrimary" className={classes.bold}>
		      Sectors
		    </Typography>
		    <TableContainer component={Paper} elevation={0}>
		      <Table size="small" aria-label="sectors">
			<TableHead>
			  <TableRow>
			    <TableCell>Name</TableCell>
			    <TableCell align="right">Absolute momentum</TableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {this.state.sectors.map((sector) => (
			    <TableRow key={"sector"+sector.id}>
			      <TableCell component="th" scope="row">
				<span className="text-capitalize">{sector.name}</span>
			      </TableCell>
			      <TableCell align="right">{toPercentage(sector.absmomentum)}</TableCell>
			    </TableRow>
			  ))}
			</TableBody>
		      </Table>
		    </TableContainer>
		  </Paper>
		</Grid>
	      </Grid>
            </Grid>

	  </Grid>

	  
	</div>	
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(Visitor);
