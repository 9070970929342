import React, { useState } from 'react';

function CancelSubscription(props) {
  const handleClick = (evt) => {
    evt.preventDefault();
    return fetch('/api/v1/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: props.subscription_id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        // Display to the user that the subscription has been cancelled.
	window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Subscription cancelled, you can still use until the ending date', type: 'success' })
	props.updateSubscriptionState()
      });
  };

  return (
    <React.Fragment>
      <div
	className="flex justify-between mt-2 mb-2 font-weight-bold text-danger"
	onClick={handleClick}
	style={{cursor: 'pointer'}}
      >
	<span>
          Cancel subscription <span>→</span>
	</span>
      </div>      
    </React.Fragment>
  );
}

export default CancelSubscription;
