import React from "react"
import PropTypes from "prop-types"
class BacktestInside extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      idname: [],
      currentpfid: '',
      currententries: [],
      currentmonths: [],
      maxbymonth: {},
      loading: true,
      help: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/backtest/inside.json')
      .then((response) => {return response.json()})
      .then((data) => {
	this.setState({loading: false})
	if (this._isMounted && data.status != 500) {
	  //console.log(data)
	  
	  const idname = data.reduce((a,b) =>
	    {
	      a.push([b['id'], b['name']])
	      return a
	    }, [])

	  var help = ''
	  if(data.length == 0) {
	    help =
	      <div className="alert alert-secondary" role="alert">
		In order to have datas to show you, you need to create a <a href="/portfolios">portfolio</a> <b>and</b> fill it
	      </div>
	  }
	  
	  this.setState({ idname: idname,  data: data, help: help })
	  if(data.length > 0) {
	    this.handleChange(data[0]['id'])
	  }
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(value) {
    const currentpf = this.state.data.find((x) => x.id == value)
    const currententries = currentpf.entries
    //console.log(currententries)

    const currentmonths = currententries.reduce((a,b) => {
      const months = b[1].reduce((x,y) => {x.push(y[0]);return x}, [])
      if(months.length>a.length) { a = months }
      return a
    }, [])
    //console.log(currentmonths)

    const maxbymonth = currententries.reduce((a,b) => {
      b[1].forEach((e) => {
	if(!a[e[0]] || e[1] > a[e[0]]) {
	  a[e[0]] = e[1]
	}
      })
      return a
    }, {})
    
    //console.log(maxbymonth)
    
    this.setState( {currentpfid: value, currententries: currententries, currentmonths: currentmonths, maxbymonth: maxbymonth} )
  }
  
  render () {
    function displayreturns(f, ref, month) {
      if(f != null && f >= 0) {
	return <span style={f==ref.state.maxbymonth[month]?{textDecoration: 'underline'}:{}}>{f && (f*100).toFixed(2)}</span>
      } else if(f != null && f < 0) {
	return <span style={f==ref.state.maxbymonth[month]?{textDecoration: 'underline'}:{}}className="font-weight-bold">{f && (f*100).toFixed(2)}</span>
      }
    }
    
    return (
      <React.Fragment>
	{this.state.help}

	{ this.state.loading && <div className="row justify-content-center mt-2"><span><i className="fas fa-spinner fa-pulse"></i> Loading...</span></div> }
	
	{
	  this.state.help == '' && !this.state.loading &&
	  <form className="mt-2">
	    <div className="form-group">
	      <select className="form-control" value={this.state.currentpfid} onChange={(e) => this.handleChange(e.target.value)}>
		{
		  this.state.idname.map((x) => {
		    return(<option key={x[0]} value={x[0]}>{x[1]}</option>)
		  })
		}
	      </select>
	    </div>
	  </form>
	}

	{
	  this.state.help == '' && !this.state.loading &&
	  <div className="mt-2">
	    <div className="card">
	      <div className="card-body">

		<h6 className="card-title">Returns month by month over the last 12 months</h6>
		<div className="table-responsive">
		  <table className="table table-sm table-hover">
		    <thead>
		      <tr>
			<th>%</th>
			{
			  this.state.currentmonths.map((x) => {
			    return(<th key={x}>{x}</th>)
			  })
			}
		      </tr>
		    </thead>
		    <tbody>
		      {
			this.state.currententries.map((x) => {
			  const targetlen = this.state.currentmonths.length
			  var arr = x[1]

			  if(arr.length<targetlen) {
			    var padding = new Array(targetlen-arr.length)
			    padding.fill('')
			    arr = padding.concat(arr)
			  }
			  
			  const ret = arr.map((y) => {
			    return(<td key={x[0]+"-"+y[0]}>{displayreturns(y[1], this, y[0])}</td>)
			  })
			  
			  return(<tr key={x[0]}><td><a href={'/stocks/'+x[0]} className="text-dark">{x[0]}</a></td>{ret}</tr>)
			})
		      }
		    </tbody>
		  </table>
		</div>
		
	      </div>
	    </div>
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default BacktestInside
