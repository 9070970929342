import React from "react"
import PropTypes from "prop-types"
import StockSearch from "../components/StockSearch"
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import "../components/StockChart.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  small: {
    fontSize: 12,
  }
});


// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);


class StockCard extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      stock_options: '',
      valuation_options: '',
      profitability_options: '',
      strength_options: '',
      revenue_options: '',
      income_options: '',
      eps_options: '',
      payout_options: '',
      stock: ''
    }
  }

  updateData(data) {
    //console.log(data)
    const stock_options = {
      navigation: {
	bindingsClassName: 'stock'
      },
      yAxis: [
	{
	  labels: {
            align: 'left'
	  },
	  height: '80%',
	  resize: {
            enabled: true,
	    lineWidth: 2
	  }
	},
	{
	  labels: {
	    align: 'left'
	  },
	  top: '80%',
	  height: '20%',
	  offset: 0
	}
      ],

      tooltip: {
	shape: 'square',
	headerShape: 'callout',
	borderWidth: 0,
	shadow: false,
	positioner: function (width, height, point) {
          var chart = this.chart,
              position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: point.plotY
            };
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop
            };
          }

          return position;
	}
      },
      
      series: [
	{
	  type: 'candlestick',
	  id: 'ohlc',
	  data: data.chart_ohlc,
	  name: data.code+' Stock Price',
	  lastVisiblePrice: { enabled: true, color: 'red' },
	  upColor: 'green',
	  color: 'red',
	  dataGrouping: {
            units: [
              [
                'day', // unit name
                [1] // allowed multiples
              ],[
                'week', // unit name
                [1] // allowed multiples
              ],
              [
                'month', // unit name
                [1] // allowed multiples
              ],
          ]}
	},
	{
	  type: 'column',
	  id: 'volume',
	  name: data.code+' Volume',
	  data: data.chart_volume,
	  yAxis: 1,
	}
      ],


      navigator: {
	series: {
	  color: '#7cb5ec',
	  lineWidth: 2
	}
      },
      
      credits: {
	enabled: false
      },

      stockTools: {
	gui: {
	  enabled: false,
	  buttons: [ 'fullScreen', 'indicators', 'currentPriceIndicator' ]
	}
      },

      chart: {
	zoomType: 'x'
      },

      rangeSelector: {
	selected: 5,
	buttons: [
	  {
	    type: 'month',
	    count: 3,
	    text: '3m',
	    title: 'View 3 months'
	  },
	  {
	    type: 'month',
	    count: 6,
	    text: '6m',
	    title: 'View 6 months'
	  },
	  {
	    type: 'month',
	    count: 9,
	    text: '9m',
	    title: 'View 9 months'
	  },
	  {
	    type: 'ytd',
	    text: 'YTD',
	    title: 'View year to date'
	  },
	  {
	    type: 'year',
	    count: 1,
	    text: '1y',
	    title: 'View 1 year'
	  },
	  {
	    type: 'year',
	    count: 3,
	    text: '3y',
	    title: 'View 3 year'
	  },
	  {
	    type: 'year',
	    count: 5,
	    text: '5y',
	    title: 'View 5 year'
	  },
	  {
	    type: 'all',
	    text: 'All',
	    title: 'View all'
	  }
	]
      }    
    }

    const valuation_options = {
      navigation: {
	bindingsClassName: 'valuation'
      },
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'TTM values'
        },	
      },
      series: [
	{ name: 'PER', data: data.metrics.per_ttm },
	{ name: 'P/S', data: data.metrics.ps_ttm },
	{ name: 'EV/Ebit', data: data.metrics.evebit_ttm },
	{ name: 'P/B', data: data.metrics.pb_ttm },
	{ name: 'Div.Yield', tooltip: {valueSuffix: ' %'}, data: data.metrics.yield_ttm },
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: { series: { marker: { enabled: false  } } },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }


    const profitability_options = {
      navigation: {
	bindingsClassName: 'profitability'
      },
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Percentage'
        },	
      },
      series: [
	{ name: 'Gross Margin', tooltip: {valueSuffix: ' %'}, data: data.metrics.gm_ttm },
	{ name: 'Operating Margin', tooltip: {valueSuffix: ' %'}, data: data.metrics.om_ttm },
	{ name: 'Net Margin', tooltip: {valueSuffix: ' %'}, data: data.metrics.nm_ttm },
	{ name: 'Return On Invested Capital', tooltip: {valueSuffix: ' %'}, data: data.metrics.roic_ttm },
	{ name: 'Return On Asset', tooltip: {valueSuffix: ' %'}, data: data.metrics.roa_ttm },
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: { series: { marker: { enabled: false  } } },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }

    const strength_options = {
      navigation: {
	bindingsClassName: 'strength'
      },
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Ratio'
        },	
      },
      series: [
	{ name: 'Quick Ratio', color: '#3498DB', data: data.metrics.quick_ratio, zones: [{ value: 1, color: 'red' }] },
	{ name: 'Debt To Equity', color: '#797D7F', data: data.metrics.debt_equity, zones: [{ value: 2 }, { color: 'red' }]  },
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: { series: { marker: { enabled: false  } } },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }

    const revenue_options = {
      navigation: {
	bindingsClassName: 'revenue'
      },
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Millions'
        },	
      },
      series: [
	{ name: 'Revenue QTR', data: data.metrics.revenue_qtr, type: 'column' },
	{ name: 'Revenue Y', color: 'black', data: data.metrics.revenue_ttm },	
      ],


      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: {
	series: { marker: { enabled: false  } },
	column: { colorByPoint: true, colors: ["#E74C3C","#F1C40F", "#AF7AC5", "#1F618D"] }
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }


    const income_options = {
      navigation: {
	bindingsClassName: 'income'
      },    
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
	type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Millions'
        },	
      },
      series: [
	{ name: 'Income QTR', data: data.metrics.income_qtr, type: 'column' },
	{ name: 'Income Y', color: 'black', data: data.metrics.income_ttm },	
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: {
	series: { marker: { enabled: false  } },
	column: { negativeColor: '#943126' , color: '#1A5276'}
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    } 


    const eps_options = {
      navigation: {
	bindingsClassName: 'eps'
      },     
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: ''
        },	
      },
      series: [
	{ name: 'Estimated EPS', data: data.metrics.eps_estim, type: 'column' },
	{ name: 'Actual EPS', data: data.metrics.eps_actual, color: 'black' },
	{ name: 'FCF PS', data: data.metrics.fcfps_ttm, color: '#2f7ed8' },
	{ name: 'Dividend PS', data: data.metrics.divps_ttm, color: '#8bbc21' },	
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: {
	series: { marker: { enabled: false  } },
	column: { colorByPoint: true, colors: ["#E74C3C","#F1C40F", "#AF7AC5", "#1F618D"] }
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    } 


    const payout_options = {
      navigation: {
	bindingsClassName: 'payout'
      },     
      title: false,
      subtitle: false,
      
      credits: {
	enabled: false
      },      
      chart: {
        type: 'spline'
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: ''
        },	
      },
      series: [
	{ name: 'Payout ratio', data: data.metrics.payout_ttm, color: '#2f7ed8' },
	{ name: 'Interest Coverage', data: data.metrics.intcov_ttm, color: '#8bbc21' },	
      ],

      stockTools: {
	gui: {
	  enabled: false
	}
      },

      plotOptions: {
	series: { marker: { enabled: false  } },
      },
      
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      },  
    }

    
    
    this.setState({stock_options: stock_options, valuation_options: valuation_options, profitability_options: profitability_options, strength_options: strength_options, revenue_options: revenue_options, income_options: income_options, eps_options: eps_options, payout_options: payout_options, stock: data})    
  }
  
  componentDidUpdate(prevprops) {
    //console.log("componentDidUpdate")
    if(prevprops.stock != this.props.stock) {
      this.updateData(this.props.stock)
    }
  }
  
  
  componentDidMount() {
    //console.log("componentDidMount")
    this._isMounted = true;
    this.updateData(this.props.stock)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render () {
    const { classes } = this.props;
    const overview = this.state.stock.metrics ? this.state.stock.metrics['overview'] : ''
    const stock = this.state.stock;
    const dcf_fv_intrinsic = this.state.stock.metrics ? this.state.stock.metrics.dcf_intrinsic : ''
    const dcf_le_intrinsic = this.state.stock.metrics ? this.state.stock.metrics.dcf_le_intrinsic : ''
    const dcf_intrinsic = dcf_fv_intrinsic ? dcf_fv_intrinsic : dcf_le_intrinsic

    

    
    function toPercentage(f,bottom, top) {
      if (typeof f == 'string') { f = parseFloat(f) }
      
      if(f!=null && bottom!=null && f<=bottom) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && top!=null && f>top) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }      
    }

    function toPercentage2(f,bottom, top) {
      if (typeof f == 'string') { f = parseFloat(f) }
      
      if(f!=null && bottom!=null && f<=bottom) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && top!=null && f>top) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }      
    }    
    
    function toNumber(f,bottom, top) {
      if (typeof f == 'string') { f = parseFloat(f) }
      
      if(!isNaN(f) && f!=null && top!=null && f>top) {
	return <span className="text-danger">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null && bottom!=null && f<bottom) {
	return <span className="text-success">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }
    }

    function toNumber2(f,bottom, top) {
      if (typeof f == 'string') { f = parseFloat(f) }
      
      if(!isNaN(f) && f!=null && top!=null && f<top) {
	return <span className="text-danger">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null && bottom!=null && f>bottom) {
	return <span className="text-success">{f.toFixed(2)}</span>
      } else if(!isNaN(f) && f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }
    }

    
    return (
      <React.Fragment>
	{ stock &&
	  <div className={classes.root}>
	    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
	      
	      <Grid item sm xs={12}> {/* colonne 1 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Summary
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small} gutterBottom>
			{stock.detail.description}
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{stock.detail.address } {(stock.detail.country) ? '/' : ''} { stock.detail.country }
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{ stock.detail.exchange } {(stock.detail.assettype) ? '/' : ''} { stock.detail.assettype && stock.detail.assettype.replace('_',' ') } {(stock.detail.currency) ? '/' : ''} {stock.detail.currency}
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{ stock.detail.sector } {(stock.detail.industry) ? '/' : ''} { stock.detail.industry }
		      </Typography> 
		    </Paper>
		  </Grid>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Snapshot
		      </Typography>
		      
		      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={0}>
			<Grid item>
			  <Typography variant="body2">Employees : {overview['FullTimeEmployees']}</Typography>
			  <Typography variant="body2">Market Cap : {toNumber(overview['MarketCapitalization']/1000000000,null,null)} billions</Typography>
			  <Typography variant="body2">Curr/Target Price : {stock.price}<i className="fas fa-angle-right"></i>{toNumber(overview['TargetPrice'],null,null)}</Typography>
			  <Typography variant="body2">Beta : {overview['Beta']}</Typography>
			  <Typography variant="body2">Short Ratio : {toNumber(overview['ShortRatio'],0,8)}</Typography>
			  <Typography variant="body2">Dividend Yield : { toPercentage(stock.detail.dividendyield,0,null) }</Typography>
			  <Typography variant="body2">Fw Dividend Yield : { toPercentage(overview['ForwardAnnualDividendYield'],0,null) }</Typography>
			  <Typography variant="body2">Ex dividend date : {overview['ExDividendDate']}</Typography>
			  <Typography variant="body2">Mom12m : {toPercentage(stock.mom12m,0,0)}</Typography>
			  <Typography variant="body2">Sma10m : {toPercentage(stock.proximitysma10m,0,0)}</Typography>
			  <Typography variant="body2">Mom6m : {toPercentage(stock.mom6m,0,0)}</Typography>
			  <Typography variant="body2">Hi52 : {toPercentage(stock.proximityhigh52,null,null)}</Typography>
			</Grid>
			<Grid item>
			  <Typography variant="body2">EV/Ebitda : {toNumber(stock.detail.evtoebitda,10,14)}</Typography>
			  <Typography variant="body2">PEG : {toNumber(stock.detail.pegratio,1.0,1.01)}</Typography>
			  <Typography variant="body2">P/S TTM : { toNumber(stock.detail.pricetosalesratiottm,2,4) }</Typography>
			  <Typography variant="body2">Operating Margin : { toPercentage(stock.detail.operatingmarginttm,0,null) }</Typography>
			  <Typography variant="body2">Profit Margin : { toPercentage(stock.detail.profitmargin,0,null) }</Typography>
			  <Typography variant="body2">ROE TTM : { toPercentage(stock.detail.returnonequityttm,0.10,0.14) }</Typography>
			  <Typography variant="body2">ROA TTM : { toPercentage(stock.detail.returnonassetsttm,0,null) }</Typography>
			  <Typography variant="body2">P/B : { toNumber(stock.detail.pricetobookratio,3, null) }</Typography>
			  <Typography variant="body2">PER : { toNumber(stock.detail.perratio,null,18) }</Typography>
			  <Typography variant="body2">Fw PER : { toNumber(overview['ForwardPE'], null,18) }</Typography>
			  <Typography variant="body2">UPI : { toNumber(stock.upi,null,null) }</Typography>
			  {stock.detail.ncav > 0 && <Typography variant="body2">P/NCAV : { toNumber(stock.price/stock.detail.ncav,null,null) }</Typography>}
			</Grid>
		      </Grid>
		      
		    </Paper>
		  </Grid>


		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Value Investing Checklist
		      </Typography>
		      <Typography variant="body2">{'FCF stable std dev < 0.25 : '}{ toNumber(stock.metrics.fcfps_std,0.25,0.25) }</Typography>
		      <Typography variant="body2">{'Div stable std dev < 0.25 : '}{ toNumber(stock.metrics.divps_std,0.25,0.25) }</Typography>
		      <Typography variant="body2">{'Mean Payout R < 50% : '}{ toPercentage2(stock.metrics.mean_payout/100,0.5,0.5) }</Typography>
		      <Typography variant="body2">{'Mean Int Cov > 10 : '}{ toNumber2(stock.metrics.mean_intcov,0,10) }</Typography>
		      <Typography variant="body2">{'Debt / Equity < 2 : '}{ toNumber(stock.metrics.last_debt_equity,2,2) }</Typography>
		      <Typography variant="body2">{'Mean ROIC > 12% (moat) : '}{ toPercentage(stock.metrics.mean_roic/100,0.12,0.12) }</Typography>
		      <Typography variant="body2">{'FCF Growth Rate Proj : '}{ toPercentage(stock.metrics.dcf_growth_rate,null,null) }</Typography>
		      <Typography variant="body2">{'DCF Fair Estimate Intrinsic Value : '}{ toNumber(stock.metrics.dcf_intrinsic,null,null) }</Typography>
		      <Typography variant="body2">{'DCF Low Estimate Intrinsic Value : '}{ toNumber(stock.metrics.dcf_le_intrinsic,null,null) }</Typography>
		      { dcf_intrinsic && <Typography variant="body2">{'Price Level : '}{ toNumber(dcf_intrinsic*0.8) } | <b>{ toNumber(dcf_intrinsic*0.9) }</b> | <b>{ toNumber(dcf_intrinsic*1.1) }</b> | { toNumber(dcf_intrinsic*1.2) }</Typography> }
		      { dcf_intrinsic && <Typography variant="body2">{'Current Price : '}{ toNumber(stock.price,dcf_intrinsic*0.9,dcf_intrinsic*1.1) }</Typography> }
		    </Paper>
		  </Grid>
		  
		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Earnings, Free Cash Flow and Dividends
		      </Typography>
		      { this.state.eps_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.eps_options}
			/>
		      }
		    </Paper>
		  </Grid>


		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Payout ratio, Interest Coverage
		      </Typography>
		      { this.state.payout_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.payout_options}
			/>
		      }
		    </Paper>
		  </Grid>

		  
		  
		</Grid>
	      </Grid>

	      
	      <Grid item sm xs={12}> {/* colonne 2 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Price
		      </Typography>
		      { this.state.stock_options &&
			<HighchartsReact
			  constructorType="stockChart"
			  highcharts={Highcharts}
			  options={this.state.stock_options}
			/>
		      }
		    </Paper>
		  </Grid>

		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Revenue
		      </Typography>
		      { this.state.revenue_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.revenue_options}
			/>
		      }
		    </Paper>
		  </Grid>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Income
		      </Typography>
		      { this.state.income_options &&
			<HighchartsReact
			constructorType="chart"
			highcharts={Highcharts}
			options={this.state.income_options}
			/>
		      }
		    </Paper>
		  </Grid>
		  
		</Grid>
	      </Grid>


	      <Grid item sm xs={12}> {/* colonne 3 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Valuation ratios
		      </Typography>
		      {
			this.state.valuation_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.valuation_options}
			/>
		      }
		    </Paper>
		  </Grid>


		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Profitability
		      </Typography>
		      {
			this.state.profitability_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.profitability_options}
			/>
		      }
		    </Paper>
		  </Grid>
		  

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Financial Strength
		      </Typography>
		      {
			this.state.strength_options &&
			<HighchartsReact
			  constructorType="chart"
			  highcharts={Highcharts}
			  options={this.state.strength_options}
			/>
		      }
		    </Paper>
		  </Grid>
		  
		</Grid>
	      </Grid>	      


	      
	    </Grid>
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(StockCard); 
