import React from "react"
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography';

class BacktestOverview extends React.Component {
  _isMounted = false; 

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dates: [],
      months: [],
      currenttab: 'summary',
      maxcagr: '',
      mindd: '',
      minstddev: '',
      maxsortino: '',
      maxupi: '',
      yearmaxret: {},
      monthmaxret: {},
      yearmindd: {},
      yearmaxsortino: {},
      loading: true,
      help: '',
      returns_view: 'years'
    }
  }
  
  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/backtest/overview.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if(data.status == 500) {
	  window.flash_messages.addMessage({ id: 'id'+Math.random(), text: data.message, type: 'error' })
	} else if (this._isMounted && data.status != 500) {
	  //console.log(data)
	  this.updateState(data)
	}
      })
  }

  updateState(data) {
    
    data.sort((a,b) => {
      const sortKey = 'portfolio_upi'
      if(a[sortKey] > b[sortKey]) {
	return -1;
      }
      if(a[sortKey] < b[sortKey]) {
	return 1;
      }
      return 0;
    })
    
    
    const alldates = data.reduce((x,y) => {
      return x.concat(y.stats.perf.map((z) => z[0]))
    }, [])
    
    var dates = alldates.reduce((x,y) => {
      if(!x.includes(y)) { x.push(y) }
      return x
    },[])
    dates.sort()

    const months = data.reduce((x,y) => {
      if(y.stats.perf_by_months.length>x.length) {
	x.length = 0
	x.push(...y.stats.perf_by_months.map((z) => z[0]))
      }
      return x
    }, [])

    const maxcagr = data.reduce((x,y) => {
      const current = y.portfolio_cagr
      if(current>x) { x = current }
      return x
    },0.0)

    const mindd = data.reduce((x,y) => {
      const current = y.portfolio_maxdrawdown
      if(current>x) { x = current }
      return x
    },-1.0)

    const minstddev = data.reduce((x,y) => {
      const current = y.portfolio_standard_deviation
      if(current<x) { x = current }
      return x
    },1.0) 
    
    const maxsortino = data.reduce((x,y) => {
      const current = y.portfolio_sortino
      if(current>x) { x = current }
      return x
    },0.0)    

    const maxupi = data.reduce((x,y) => {
      const current = y.portfolio_upi
      if(current>x) { x = current }
      return x
    },0.0)

    const initialhash = dates.reduce((x,y) => {
      x[y] = ''
      return x
    }, {})

    const initialhashmonth = months.reduce((x,y) => {
      x[y] = ''
      return x
    }, {})
    
    const yearmaxret = data.reduce((x,y) => {
      
      return y.stats.perf.reduce((a,b) => {
	const currentyear = b[0]
	const currentvalue = b[1]

	if(currentvalue > x[currentyear]) { a[currentyear] = currentvalue}
	if(x[currentyear] == '') { a[currentyear] = currentvalue }
	return a
      }, x)
      
    }, Object.assign({}, initialhash))
    //console.log(yearmaxret)

    const monthmaxret = data.reduce((x,y) => {
      
      return y.stats.perf_by_months.reduce((a,b) => {
	const currentmonth = b[0]
	const currentvalue = b[1]

	if(currentvalue > x[currentmonth]) { a[currentmonth] = currentvalue}
	if(x[currentmonth] == '') { a[currentmonth] = currentvalue }
	return a
      }, x)
      
    }, Object.assign({}, initialhashmonth))
    //console.log(monthmaxret)
    
    const yearmindd = data.reduce((x,y) => {
      
      return y.stats.drawdown.reduce((a,b) => {
	const currentyear = b[0]
	const currentvalue = b[1]

	if(currentvalue > x[currentyear]) { a[currentyear] = currentvalue}
	if(x[currentyear] == '') { a[currentyear] = currentvalue }
	return a
      }, x)
      
    }, Object.assign({}, initialhash))
    //console.log(yearmindd)

    const yearmaxsortino = data.reduce((x,y) => {
      
      return y.stats.sortino.reduce((a,b) => {
	const currentyear = b[0]
	const currentvalue = b[1]

	if(currentvalue > x[currentyear]) { a[currentyear] = currentvalue}
	if(x[currentyear] == '') { a[currentyear] = currentvalue }
	return a
      }, x)
      
    }, Object.assign({}, initialhash))
    //console.log(yearmaxsortino)

    var help = ''
    if(data.length == 0) {
      help =
	<div className="alert alert-secondary" role="alert">
	  In order to have datas to show you, you need to create a <a href="/portfolios">portfolio</a> <b>and</b> fill it
	</div>
    }

    
    this.setState( {data: data, dates: dates, months: months, maxcagr: maxcagr, mindd: mindd, minstddev: minstddev, maxsortino: maxsortino, maxupi: maxupi, yearmaxret: yearmaxret, monthmaxret: monthmaxret, yearmindd: yearmindd, yearmaxsortino: yearmaxsortino, loading: false, help: help} )    
  }


  
  componentWillUnmount() {
    this._isMounted = false;
  }


  changeTab(tabname) {
    this.setState( {currenttab: tabname} )
  }
  
  changeView(name) {
    //console.log(name)
    this.setState({returns_view: name})
  }
  
  
  render () {    
    function navbutton(ref, name) {
      return <button className={ref.state.currenttab == name ? 'btn btn-primary mx-1 my-1 shadow-none' : 'btn btn-link mx-1 my-1 shadow-none text-decoration-none'} id={'nav-'+name+'-tab'} data-bs-toggle="tab" role="tab" aria-controls={"nav-"+name} aria-selected={ref.state.currenttab == name ? true : false} onClick={() => ref.changeTab(name)}><span className="text-capitalize">{name}</span></button>
    }

    function navdiv(ref, name, table) {
      return (
	<div className={ref.state.currenttab == name ? 'tab-pane fade show active' : 'tab-pane fade'}  id={'nav-'+name} role="tabpanel" aria-labelledby={'nav-'+name+'-tab'}>
	  <div className="table-responsive-sm">
	    { table }
	  </div>
	</div>
      )
    }   

    function displaycagr(ref, f) {
      return <span style={f==ref.state.maxcagr?{textDecoration: 'underline'}:{}}>{f && (f*100).toFixed(2)}</span>
    }

    function displaydd(ref, f) {
      return <span style={f==ref.state.mindd?{textDecoration: 'underline'}:{}}>{f && (f*100).toFixed(2)}</span>
    }

    function displaystddev(ref, f) {
      return <span style={f==ref.state.minstddev?{textDecoration: 'underline'}:{}}>{f && (f*100).toFixed(2)}</span>
    }
    
    function displaysortino(ref, f) {
      return <span style={f==ref.state.maxsortino?{textDecoration: 'underline'}:{}}>{f && f.toFixed(2)}</span>
    }

    function displayupi(ref, f) {
      return <span style={f==ref.state.maxupi?{textDecoration: 'underline'}:{}}>{f && f.toFixed(2)}</span>
    } 

    function displayreturnatdate(ref, year, f) {
      if(f!=null && f>=0) {
	return <span style={f==ref.state.yearmaxret[year]?{textDecoration: 'underline'}:{}} className="font-weight-normal">{(f*100).toFixed(2)}</span>
      } else if(f<0)
      {
	return <span style={f==ref.state.yearmaxret[year]?{textDecoration: 'underline'}:{}} className="font-weight-bold">{(f*100).toFixed(2)}</span>
      }
    }

    function displayreturnbymonthsatdate(ref, month, f) {
      if(f!=null && f>=0) {
	return <span style={f==ref.state.monthmaxret[month]?{textDecoration: 'underline'}:{}} className="font-weight-normal">{(f*100).toFixed(2)}</span>
      } else if(f<0)
      {
	return <span style={f==ref.state.monthmaxret[month]?{textDecoration: 'underline'}:{}} className="font-weight-bold">{(f*100).toFixed(2)}</span>
      }
    }

    function displayddatdate(ref, year, f) {
      if(f) { return <span style={f==ref.state.yearmindd[year]?{textDecoration: 'underline'}:{}}>{(f*100).toFixed(2)}</span> }
    }    

    function displaysortinoatdate(ref, year, f) {
      if(f) { return <span style={f==ref.state.yearmaxsortino[year]?{textDecoration: 'underline'}:{}}>{(f*10).toFixed(2)}</span> }
    } 
    
    
    const htmldates = this.state.dates.map((x) => {
      return(<td key={x}>{x}</td>)
    })

    const htmlmonths = this.state.months.map((x) => {
      return(<td key={x}>{x}</td>)
    })

    const summary = this.state.data.map((x) => {
      const id = x.id
      const name = x.name
      const cagr = x.portfolio_cagr
      const maxdd = x.portfolio_maxdrawdown
      const sortino = x.portfolio_sortino
      const stddev = x.portfolio_standard_deviation
      const upi = x.portfolio_upi
      
      return(
	<React.Fragment key={id}>
	  <tr><td>{ name }</td><td>{displaycagr(this, cagr)}</td><td>{displaydd(this, maxdd)}</td><td>{displaystddev(this, stddev)}</td><td>{sortino ? displaysortino(this, sortino) : <i className="fas fa-sm fa-infinity"/>}</td><td>{displayupi(this, upi)}</td></tr>
	</React.Fragment>
      )
    })    

    
    const returns = this.state.data.map((x) => {
      const id = x.id
      const name = x.name

      /// transforme en hash avec comme clef l annee
      const all_perf = Object.fromEntries(x.stats.perf)     
      const html_perf = this.state.dates.map((x) => {
	return(
	  <td key={"perf"+id+x}>{displayreturnatdate(this, x, all_perf[x])}</td>
	)
      })
      
      return(
	<React.Fragment key={id}>
	  <tr><td>{ name }</td>{ html_perf }</tr>
	</React.Fragment>
      )
    })


    const returns_by_months = this.state.data.map((x) => {
      const id = x.id
      const name = x.name

      /// transforme en hash avec comme clef l annee
      const all_perf_by_months = Object.fromEntries(x.stats.perf_by_months)     
      const html_perf = this.state.months.map((x) => {
	return(
	  <td key={"perf_by_months"+id+x}>{displayreturnbymonthsatdate(this, x, all_perf_by_months[x])}</td>
	)
      })
      
      return(
	<React.Fragment key={id}>
	  <tr><td>{ name }</td>{ html_perf }</tr>
	</React.Fragment>
      )      
    })
    
    
    const drawdown = this.state.data.map((x) => {
      const id = x.id
      const name = x.name

      /// transforme en hash avec comme clef l annee
      const all_dd = Object.fromEntries(x.stats.drawdown)
      const html_dd = this.state.dates.map((x) => {
	return(
	  <td key={"dd"+id+x}>{displayddatdate(this, x, all_dd[x])}</td>
	)
      })
      
      return(
	<React.Fragment key={id}>
	  <tr><td>{ name }</td>{ html_dd }</tr>
	</React.Fragment>
      )
    })

    const sortino = this.state.data.map((x) => {
      const id = x.id
      const name = x.name

      /// transforme en hash avec comme clef l annee
      const all_sortino = Object.fromEntries(x.stats.sortino)
      const html_sortino = this.state.dates.map((x) => {
	return(
	  <td key={"sortino"+id+x}>{displaysortinoatdate(this, x, all_sortino[x])}</td>
	)
      })
      
      return(
	<React.Fragment key={id}>
	  <tr><td>{ name }</td>{ html_sortino }</tr>
	</React.Fragment>
      )
    })   


    return (
      <React.Fragment>
	{this.state.help}

	{ this.state.help == '' &&
	  <nav>
	    <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
	      { navbutton(this, 'summary') }
	      { navbutton(this, 'returns') }
	      { navbutton(this, 'drawdown') }
	      { navbutton(this, 'sortino') }
	    </div>
	  </nav>
	}
	
	{ this.state.loading && <div className="row justify-content-center pt-4"><span><i className="fas fa-spinner fa-pulse"></i> Loading...</span></div> }
	{ !this.state.loading && this.state.help == '' &&
	  <div className="tab-content" id="nav-tabContent">  
	    { navdiv(this, 'summary',
		     <div className="row justify-content-center pt-4">
		       <div className="card">
			 <div className="card-body">

			   <h6 className="card-title">Risks and returns for your annually rebalanced portfolios</h6>
			   <div className="table-responsive">
			     <table className="table table-sm table-hover">
			       <thead>
				 <tr><th>#</th><th>CAGR %</th><th>Max drawdown %</th><th>Daily std dev %</th><th>Sortino</th><th>UPI</th></tr>
			       </thead>
			       <tbody>
				 { summary }
			       </tbody>
			     </table>
			   </div>
			 </div>
		       </div>

		     </div>
	    ) }
	    
	    { navdiv(this, 'returns',
		     <div className="pt-4">
		       <button disabled={this.state.returns_view == 'years'} className="btn btn-link" onClick={() => this.changeView("years")}>View by years</button> / <button disabled={this.state.returns_view == 'months'} className="btn btn-link" onClick={() => this.changeView("months")}>Last 12 months</button>


		       { this.state.returns_view == 'years' &&
			 <div className="card">
			   <div className="card-body">

			     
			     <h6 className="card-title">Returns each year. Each portfolio is rebalanced the first trading day in January.</h6>
			     <div className="table-responsive">
			       <table className="table table-sm table-hover">
				 <tbody>
				   <tr><td>%</td>{ htmldates }</tr>
				   { returns }
				 </tbody>
			       </table>
			     </div>
			     
	      		   </div>
			 </div>
		       }

		       { this.state.returns_view == 'months' &&
			 <div className="card">
			   <div className="card-body">
			     
			     <h6 className="card-title">Returns last 12 months.</h6>
			     <div className="table-responsive">
			       <table className="table table-sm table-hover">
				 <tbody>
				   <tr><td>%</td>{ htmlmonths }</tr>
				   { returns_by_months }
				 </tbody>
			       </table>
			     </div>
			     
			   </div>
			 </div>
		       }
			     
			 
		     </div>
	    ) }

	    { navdiv(this, 'drawdown',
		     <div className="pt-4">
		       <div className="card">
			 <div className="card-body">

			   <h6 className="card-title">Maximum drawdown by year</h6>
			   <div className="table-responsive">
			     <table className="table table-sm table-hover">
			       <tbody>
				 <tr><td>%</td>{ htmldates }</tr>
				 { drawdown }
			       </tbody>
			     </table>
			   </div>
			   
	      		 </div>
		       </div>
		     </div>	      
	    ) }
	    
	    { navdiv(this, 'sortino',
		     <div className="pt-4">
		       <div className="card">
			 <div className="card-body">

			   <h6 className="card-title">Sortino ratio calculated with the day by day returns</h6>
			   <div className="table-responsive">
			     <table className="table table-sm table-hover">
			       <tbody>
				 <tr><td>(x10)</td>{ htmldates }</tr>
				 { sortino }
			       </tbody>
			     </table>
			   </div>
			   
	      		 </div>
		       </div>
		     </div>	      
	    ) }
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default BacktestOverview
