import React from "react"
import PropTypes from "prop-types"
import StockDetail from "../components/StockDetail"

class Tops extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      roles: [],
      currenttab: '',
      currentsubtab: '',
      stockdetail: '',
      btnclip: false,
      loading: true
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/tops.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  const roles = data.reduce((a,b) => {
	    if(!a.includes(b.role)) {
	      a.push(b.role)
	    }
	    return a
	  }, [])

	  const currenttab = roles[0]
	  const datawithdefaultrole = data.filter((x) => x.role == currenttab)
	  const currentsubtab = datawithdefaultrole[0]['name']
	  
	  //console.log(data)
	  //console.log(roles)
	  this.setState({ data: data, roles: roles, currenttab: currenttab, currentsubtab: currentsubtab, loading: false })
	} else {
	  this.setState({ loading: false })
	}
      }) 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeTab(tabname) {
    const datawithdefaultrole = this.state.data.filter((x) => x.role == tabname)
    const defaultsubtab = datawithdefaultrole[0]['name']    
    this.setState( {currenttab: tabname, currentsubtab: defaultsubtab, btnclip: false} )
  }

  changeSubTab(tabname) {
    this.setState( {currentsubtab: tabname, btnclip: false} )
  }

  displaydetail(stock) {
    this.setState({stockdetail: stock})
  }

  updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip)
    this.setState({btnclip: true})
  }
  
  render () {

    function displayupi(f) {
      return <span>{f && f.toFixed(2)}</span>
    }

    function toPercentage(f) {
      if(f!=null && f<0) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
    }
    
    function navbutton(ref, name) {
      return <button key={'navbutton'+name} className={ref.state.currenttab == name ? 'btn btn-primary mx-1 my-1 shadow-none' : 'btn btn-link mx-1 my-1 shadow-none text-decoration-none'} id={'nav-'+name+'-tab'} data-bs-toggle="tab" role="tab" aria-controls={"nav-"+name} aria-selected={ref.state.currenttab == name ? true : false} onClick={() => ref.changeTab(name)}><span className="text-capitalize">{name}</span></button>
    }

    function navdiv(ref, name, table) {
      return (
	<div key={'navdiv'+name} className={ref.state.currenttab == name ? 'tab-pane fade show active' : 'tab-pane fade'}  id={'nav-'+name} role="tabpanel" aria-labelledby={'nav-'+name+'-tab'}>
	  { table }
	</div>
      )
    }

    function subnavbutton(ref, name) {
      return <button key={'subnavbutton'+name} className={ref.state.currentsubtab == name ? 'btn btn-primary mx-1 my-1 shadow-none' : 'btn btn-link mx-1 my-1 shadow-none text-decoration-none'} id={'subnav-'+name+'-tab'} data-bs-toggle="tab" role="tab" aria-controls={"subnav-"+name} aria-selected={ref.state.currentsubtab == name ? true : false} onClick={() => ref.changeSubTab(name)}><span className="text-capitalize">{name}</span></button>
    }

    function subnavdiv(ref, name, table) {
      return (
	<div key={'subnavdiv'+name} className={ref.state.currentsubtab == name ? 'tab-pane fade show active' : 'tab-pane fade'}  id={'subnav-'+name} role="tabpanel" aria-labelledby={'subnav-'+name+'-tab'}>
	  <div className="table-responsive-sm">
	    { table }
	  </div>
	</div>
      )
    }    
    
    return (
      <React.Fragment>
	{ this.state.loading && <div className="row justify-content-center pt-4"><span><i className="fas fa-spinner fa-pulse"></i> Loading...</span></div> }

	{!this.state.loading && <React.Fragment>
	  <nav>
	    <div className="nav nav-tabs" id="nav-tab" role="tablist">
	      { this.state.roles.map((x) => {
		  return(navbutton(this, x))
		})}
	    </div>
	  </nav>

	  <div className="tab-content" id="nav-tabContent">
	    { this.state.roles.map((x) => {

		const datawithroles = this.state.data.filter(y => y.role == x)
		
		return(navdiv(this, x,
			      <div className="pt-4">
				<nav>
				  <div className="nav nav-pills" id={"nav-tab"+x} role="tablist">
				    {
				      datawithroles.map((z) => {
					return(
					  subnavbutton(this, z.name)
					)
				      })
				    }
				  </div>
				</nav>
				
				<div className="tab-content" id={"nav-tab"+x+"Content"}>
				  {
				    datawithroles.map((z) => {
				      const allcodes = (z.entries.reduce((a,b) => a + " " + b.code,"")).trim()
				      
				      return(

					subnavdiv(this, z.name,
						  <div className="pt-4 row justify-content-left ml-1">
						    <div className="card">
						      <div className="card-body">

							<div className="card-title">{!this.state.btnclip ? <button className="btn btn-dark btn-sm" onClick={() => {this.updateClipboard(allcodes)}}>Copy to clipboard</button> : <button className="btn btn-dark btn-sm" disabled={true}><i className="fas fa-check"/> Copied</button>}</div>
							<table className="table table-sm">
							  <thead>
							    <tr><th>Code</th><th>Mom6m</th><th>Hi52</th><th>Mom12m</th><th>SMA10M</th><th>UPI</th></tr>
							  </thead>
							  <tbody>
							    {
							      z.entries.map((w) => {
								return(
								  <tr key={w.id}>
								    <td>
								      <a href={'/stocks/'+w.code} className="btn btn-sm btn-light" onMouseEnter={() => this.displaydetail(w)} onMouseLeave={() => this.displaydetail('')}>
									{w.code}
								      </a>
								    </td>
								    <td>{toPercentage(w.mom6m)}</td>								    
								    <td>{toPercentage(w.proximityhigh52)}</td>
								    <td>{toPercentage(w.mom12m)}</td>
								    <td>{toPercentage(w.proximitysma10m)}</td>
								    <td>{displayupi(w.upi)}</td>								    
								  </tr>
								)
							      })
							    }
							  </tbody>
							</table>
							
						      </div>
						    </div>
						  </div>

					)				      
				      )
				    })
				  }
				  
				</div>
			      </div>
		))
	      })}
	  </div>
	</React.Fragment>}
	
	{
	  this.state.stockdetail && <div className="stockdetail"><StockDetail stock={this.state.stockdetail} /></div>
	}
      </React.Fragment>
    );
  }
}

export default Tops
