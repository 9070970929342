import React from "react"
import PropTypes from "prop-types"
import StockSearch from "../components/StockSearch"
import StockCard from "../components/StockCard"
import EtfCard from "../components/EtfCard"
import FundCard from "../components/FundCard"
import CryptoCard from "../components/CryptoCard"

class StockSearchBar extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  handleStockSearch(e) {   
    if(e && e.length == 1) {
      const ticker = e[0]
      window.location.href="/stocks/"+ticker
    }
  }

  
  render () {

    
    return (
      <React.Fragment>
	<StockSearch onChange={(e) => this.handleStockSearch(e)} single />
      </React.Fragment>
    );
  }
}

export default StockSearchBar


