import React from "react"
import PropTypes from "prop-types"
import StockSearch from "../components/StockSearch"
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import "../components/StockChart.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.text.secondary,
  },
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  small: {
    fontSize: 12,
  }
});


// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);


class CryptoCard extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      stock_options: '',
      valuation_options: '',
      statistics_options: '',
      stock: ''
    }
  }

  updateData(data) {
    //console.log(data)
    const overview = data['metrics']['overview']
    
    const stock_options = {
      navigation: {
	bindingsClassName: 'stock'
      },
      yAxis: [
	{
	  labels: {
            align: 'left'
	  },
	  height: '80%',
	  resize: {
            enabled: true,
	    lineWidth: 2
	  }
	},
	{
	  labels: {
	    align: 'left'
	  },
	  top: '80%',
	  height: '20%',
	  offset: 0
	}
      ],

      tooltip: {
	shape: 'square',
	headerShape: 'callout',
	borderWidth: 0,
	shadow: false,
	positioner: function (width, height, point) {
          var chart = this.chart,
              position;

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight
                )
              ),
              y: point.plotY
            };
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop
            };
          }

          return position;
	}
      },
      
      series: [
	{
	  type: 'candlestick',
	  id: 'ohlc',
	  data: data.chart_ohlc,
	  name: data.code+' Stock Price',
	  lastVisiblePrice: { enabled: true, color: 'red' },
	  upColor: 'green',
	  color: 'red',
	  dataGrouping: {
            units: [
              [
                'day', // unit name
                [1] // allowed multiples
              ],[
                'week', // unit name
                [1] // allowed multiples
              ],
              [
                'month', // unit name
                [1] // allowed multiples
              ],
          ]}
	},
	{
	  type: 'column',
	  id: 'volume',
	  name: data.code+' Volume',
	  data: data.chart_volume,
	  yAxis: 1,
	}
      ],


      navigator: {
	series: {
	  color: '#7cb5ec',
	  lineWidth: 2
	}
      },
      
      credits: {
	enabled: false
      },

      stockTools: {
	gui: {
	  enabled: false,
	  buttons: [ 'fullScreen', 'indicators', 'currentPriceIndicator' ]
	}
      },

      chart: {
	zoomType: 'x'
      },

      rangeSelector: {
	selected: 5,
	buttons: [
	  {
	    type: 'month',
	    count: 3,
	    text: '3m',
	    title: 'View 3 months'
	  },
	  {
	    type: 'month',
	    count: 6,
	    text: '6m',
	    title: 'View 6 months'
	  },
	  {
	    type: 'month',
	    count: 9,
	    text: '9m',
	    title: 'View 9 months'
	  },
	  {
	    type: 'ytd',
	    text: 'YTD',
	    title: 'View year to date'
	  },
	  {
	    type: 'year',
	    count: 1,
	    text: '1y',
	    title: 'View 1 year'
	  },
	  {
	    type: 'year',
	    count: 3,
	    text: '3y',
	    title: 'View 3 year'
	  },
	  {
	    type: 'year',
	    count: 5,
	    text: '5y',
	    title: 'View 5 year'
	  },
	  {
	    type: 'all',
	    text: 'All',
	    title: 'View all'
	  }
	]
      }    
    }

    const circulating = overview['CirculatingSupply']
    const max_supply = overview['MaxSupply']
    const statistics_options = max_supply > circulating ? (circulating/1000000).toFixed(2) + ' / ' + (max_supply/1000000).toFixed(2) + ' millions (' + (circulating*100/max_supply).toFixed(2) + ' %)' : (circulating/1000000).toFixed(2) + ' millions'
    
    
    this.setState({stock_options: stock_options, stock: data, statistics_options: statistics_options})    
  }
  
  componentDidUpdate(prevprops) {
    //console.log("componentDidUpdate")
    if(prevprops.stock != this.props.stock) {
      this.updateData(this.props.stock)
    }
  }
  
  
  componentDidMount() {
    //console.log("componentDidMount")
    this._isMounted = true;
    this.updateData(this.props.stock)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render () {
    const { classes } = this.props;
    const overview = this.state.stock.metrics ? this.state.stock.metrics['overview'] : ''
    const stock = this.state.stock;
    
    function toPercentage(f,bottom, top) {
      if(f!=null && bottom!=null && f<=bottom) {
	return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null && top!=null && f>top) {
	return <span className="text-success">{(f*100).toFixed(2)+' %'}</span>
      } else if(f!=null)
      {
	return <span>{(f*100).toFixed(2)+' %'}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }      
    }

    function toNumber(f,bottom, top) {
      if(f!=null && top!=null && f>top) {
	return <span className="text-danger">{f.toFixed(2)}</span>
      } else if(f!=null && bottom!=null && f<bottom) {
	return <span className="text-success">{f.toFixed(2)}</span>
      } else if(f!=null)
      {
	return <span>{f.toFixed(2)}</span>
      }
      else {
	return <i className="fa fa-sm fa-minus"/>
      }
    }
    
    return (
      <React.Fragment>
	{ stock &&
	  <div className={classes.root}>
	    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
	      
	      <Grid item sm xs={12}> {/* colonne 1 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Summary
		      </Typography>
		      <Typography variant="body2" color="textPrimary" className={classes.small} gutterBottom>
			{stock.detail.description}
		      </Typography>

		      <Typography variant="body2"><i className="fa fa-xs fa-info-circle" aria-hidden="true"></i> <Link color="inherit" href={overview['WebURL']} variant="body2" className={classes.small}>More information</Link></Typography>
		      <Typography variant="body2"><i className="fa fa-xs fa-info-circle" aria-hidden="true"></i> <Link color="inherit" href={overview['TechnicalDoc']} variant="body2" className={classes.small}>Technical documentation</Link></Typography>
		      <Typography variant="body2" gutterBottom><i className="fa fa-xs fa-info-circle" aria-hidden="true"></i> <Link color="inherit" href={overview['Explorer']} variant="body2" className={classes.small}>Forum</Link></Typography>
		      
		      <Typography variant="body2" color="textPrimary" className={classes.small}>
			{ stock.detail.exchange } {(stock.detail.assettype) ? '/' : ''} { stock.detail.assettype && stock.detail.assettype.replace('_',' ') } {(stock.detail.currency) ? '/' : ''} {stock.detail.currency}
		      </Typography>
		    </Paper>
		  </Grid>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Snapshot
		      </Typography>
		      <Typography variant="body2">Market Cap : {toNumber(overview['MarketCapitalization']/1000000000,null,null)} billions</Typography>
		      <Typography variant="body2">Current Price : {stock.price}</Typography>
		      <Typography variant="body2">Mom12m : {toPercentage(stock.mom12m,0,0)}</Typography>
		      <Typography variant="body2">Sma10m : {toPercentage(stock.proximitysma10m,0,0)}</Typography>
		      <Typography variant="body2">UPI : { toNumber(stock.upi,null,null) }</Typography>
		      <Typography variant="body2">Mom6m : {toPercentage(stock.mom6m,0,0)}</Typography>
		      <Typography variant="body2">Hi52 : {toPercentage(stock.proximityhigh52,null,null)}</Typography>
		    </Paper>
		  </Grid>
		  
		  
		</Grid>
	      </Grid>

	      
	      <Grid item sm xs={12}> {/* colonne 2 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Price
		      </Typography>
		      { this.state.stock_options &&
			<HighchartsReact
			  constructorType="stockChart"
			  highcharts={Highcharts}
			  options={this.state.stock_options}
			/>
		      }
		    </Paper>
		  </Grid>


		  
		</Grid>
	      </Grid>


	      <Grid item sm xs={12}> {/* colonne 3 */}
		<Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>

		  <Grid item xs>
		    <Paper className={classes.paper} elevation={3}>
		      <Typography component="h5" color="textPrimary" className={classes.bold}>
			Statistics
		      </Typography>
		      <Typography variant="body2">Circulating : {this.state.statistics_options}</Typography>
		    </Paper>
		  </Grid>		

		  
		</Grid>
	      </Grid>	      


	      
	    </Grid>
	  </div>
	}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CryptoCard); 
