import React from "react"
import PropTypes from "prop-types"
import CheckBoxes from "../components/CheckBoxes"

class ImportWatchlist extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      watchlists: [],
      disabled: [],
      ids: [],
      loading: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch('/api/v1/predefined_list_for_import_to_watchlist.json')
      .then((response) => {return response.json()})
      .then((data) => {
	if (this._isMounted && data.status != 500) {
	  const watchlists = data.predefined_list.reduce(function(x,i) {var entry={id: i.id, name: i.name+' : '+i.description}; x.push(entry) ; return x}, [])
	  const disabled = data.predefined_list.reduce(function(x,i) {if(data.used_names.includes(i.name)) {x.push(i.id)}; return x  }, [])
	  this.setState( {watchlists: watchlists, disabled: disabled} )
	}
      }) 
  }


  
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleWatchlist(ids) {
    this.setState( {ids: ids} )
  }
  
  handleImport(e) {
    this.setState({ loading: true });
    e.preventDefault();
    
    let body = JSON.stringify({ids: this.state.ids} )

    
    fetch('/api/v1/create_watchlist_with_predefined_list',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    )
		   .then((response) => {return response.json()})
		   .then((data) => {
		     this.setState({ loading: false });
		     if(data.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot import list : '+data.message, type: 'error' }) } else {
		       window.flash_messages.addMessage({ id: 'id'+Math.random(), text: this.state.ids.length+' list imported', type: 'success' })
		       const watchlists = data.predefined_list.reduce(function(x,i) {var entry={id: i.id, name: i.name+' : '+i.description}; x.push(entry) ; return x}, [])
		       const disabled = data.predefined_list.reduce(function(x,i) {if(data.used_names.includes(i.name)) {x.push(i.id)}; return x  }, [])
		       this.setState( {watchlists: watchlists, disabled: disabled, ids: []} )
		       
		     }
		   })
    
    e.target.reset();
    
  }
  
  render () {      
    return (
      <React.Fragment>

	<div className="card bg-light my-2">
	  <div className="card-body">
	    <h5 className="card-title">Choose some predefined watchlists to import</h5>
	    <form onSubmit={(e) => this.handleImport(e)}>
	      <CheckBoxes data={this.state.watchlists} disabled={this.state.disabled} onChange={(e) => this.handleWatchlist(e)}/>
	      {
		!this.state.loading && <button type="submit" className="btn btn-sm btn-primary mt-4" disabled={ this.state.ids.length == 0 }>Import to watchlist</button>
	      }
	      {
		this.state.loading && <div className="spinner-border mt-4" role="status"><span className="sr-only">Loading...</span></div>
	      }
	    </form>
	  </div>
	</div>
	
      </React.Fragment>
    );
  }
}

export default ImportWatchlist
