import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from "../components/CardSection";


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    props.parent.setState({loading: true})
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    
    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const customerId = props.customerId
    const priceId = props.priceId
    //console.log("customerId="+customerId+" priceId="+priceId)
    
    if (error) {
      //console.log('[createPaymentMethod error]', error);
      showCardError(error);
      props.parent.setState({loading: false})      
    } else {
      //console.log('[PaymentMethod]', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
          priceId,
	  stripe,
	  props
        });
      } else {
        // Create the subscription
        createSubscription({ customerId, paymentMethodId, priceId, stripe, props });
      }
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="form-row">
	<div className="col-8">
	  <CardSection />
	</div>
	<div className="col">
	  { props.parent.state.loading &&
	    <div className="mt-2"><span><i className="fas fa-spinner fa-pulse"></i> Please wait...</span></div>
	  }
	  { !props.parent.state.loading &&
	    <button className="btn btn-info" disabled={!stripe}>Subscribe : $2.99 / m</button>
	  }
	</div>
      </div>
    </form>
  );
}

function createSubscription({ customerId, paymentMethodId, priceId, stripe, props }) {
  //console.log('createSubscription')
  
  return (
    fetch('/api/v1/create-subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      }),
    })
      .then((response) => {
        return response.json();
      })
    // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
    // Normalize the result to contain the object returned by Stripe.
    // Add the additional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
	  stripe: stripe
        };
      })
    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail, you
    // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
    // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .then((result) => {
	props.updateSubscriptionState()
	window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Thank you for subscribing !', type: 'success' })
	props.parent.setState({loading: false})
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.

        showCardError(error);
	props.parent.setState({loading: false})
      })
  );
}

function onSubscriptionComplete(result) {
  //console.log('onSubscriptionComplete')
  //console.log(result)

  // Payment was successful. Provision access to your service.
  // Remove invoice from localstorage because payment is now complete.
  clearCache();

  let payment_method
  
  // Payment was successful.
  if(result.subscription) {
    //if (result.subscription.status === 'active') {
    payment_method = updatePaymentMethod({subscriptionId: result.subscription.id, paymentMethodId: result.paymentMethodId})
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.
    //}
  } else if(result.invoice) {
    payment_method = updatePaymentMethod({subscriptionId: result.invoice.subscription, paymentMethodId: result.paymentMethodId})
    //let customerId = result.invoice.customer;
  }
  
  return payment_method
}

function handlePaymentThatRequiresCustomerAction({
  subscription,
  invoice,
  priceId,
  paymentMethodId,
  isRetry,
  stripe
}) {
  //console.log('handlePaymentThatRequiresCustomerAction')
  
  if (subscription && subscription.status === 'active') {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      })
  } else {
    // No customer action needed.
    //return { subscription, priceId, paymentMethodId };
    return { subscription, invoice, priceId, paymentMethodId };
  }
}

function handleRequiresPaymentMethod({
  subscription,
  paymentMethodId,
  priceId,
}) {
  //console.log('handleRequiresPaymentMethod')
  
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } else if (
    subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
  ) {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    localStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status
    );
    throw { error: { message: 'Your card was declined.' } };
  } else {
    return { subscription, priceId, paymentMethodId };
  }
}

function retryInvoiceWithNewPaymentMethod({
  customerId,
  paymentMethodId,
  invoiceId,
  priceId,
  stripe,
  props
}) {
  //console.log('retryInvoiceWithNewPaymentMethod')
  
  return (
    fetch('/api/v1/retry-invoice', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      }),
    })
      .then((response) => {
        return response.json();
      })
    // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
    // Normalize the result to contain the object returned by Stripe.
    // Add the additional details we need.
      .then((result) => {
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
	  
          invoice: result,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          isRetry: true,
	  stripe: stripe
        };
      })
    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
    // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .then((result) => {
	props.updateSubscriptionState()
	window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Thank you for subscribing !', type: 'success' })
	props.parent.setState({loading: false})
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
	showCardError(error);
	props.parent.setState({loading: false})
      })
  );
}


function showCardError(event) {
  //console.log(event)
  var message = "Transaction refused, check console logs"
  if(event.error) { message = event.error.message }
  else if(event.message) { message = event.message }
  else { console.log(event) }
  
  window.flash_messages.addMessage({ id: 'id'+Math.random(), text: message, type: 'error' })
}

function clearCache() {
  localStorage.clear();
}

function updatePaymentMethod({ subscriptionId, paymentMethodId }) {
  //console.log("updatePaymentMethod")
  //console.log("updatePaymentMethod subscriptionId="+subscriptionId+" paymentMethodId="+paymentMethodId)

  return(
  fetch('/api/v1/update-payment', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      paymentMethodId: paymentMethodId,
    })
  })
	 .then((response) => {return response.json()})
	 .then((data) => {
	   //console.log(data)
	   return data
	 })
  )
}
