import React from "react"
import PropTypes from "prop-types"
import StockDetail from "../components/StockDetail"
import StockSearch from "../components/StockSearch"

class PortfolioItem extends React.Component {
  _isMounted = false; // prevent to setState when component is unmounted
  
  /*
     entries = list of portfolio_items
     totalpct = total of target percentage
     totalposcash = total position + cash
   */
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      totalpct: 0,
      totalposcash: 0,
      loading: false,
      stockdetail: '',
      btnclip: false,
      tickers: []
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleDeleteItem(portfolioid,itemid) {
    //console.log('handleDeleteItem portfolioid=' + portfolioid + ' itemid=' + itemid);

    fetch('/api/v1/portfolios/'+portfolioid+'/portfolio_items/'+itemid,
    	  {
    	    method: 'DELETE',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    }
    	  }
    ).then((response) => {return response.json()})
     .then((item) => {
       if(item.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot delete : '+item.message, type: 'error' }) } else {
	 let newentry = this.state.entries.filter((entry) => entry.id != itemid)
	 this.recalculate(newentry)
	 //this.setState({ entries: newentry})
       }
     })
  }


  handleUpdateItemsFromParent() {
    let portfolioid = this.props.portfolioId
    //console.log("handleUpdateItemsFromParent")
    //console.log(this.state.entries)

    // updjson = { 1 => {"percentage" => "1.1" , "quantity" => "1"}, 2 => {"percentage" => "2.2", "quantity" => "2" }}
    // PortfolioItem.update(updjson.keys, updjson.values)
    let updjson = {}
    this.state.entries.forEach(updatefield => {
      let wi = {}
      wi.percentage = parseFloat(updatefield.percentage)
      wi.quantity = updatefield.stock.code.endsWith('.CC') ? parseFloat(updatefield.quantity) : parseInt(updatefield.quantity,10)

      if(isNaN(wi.percentage)) { wi.percentage = 0}
      if(isNaN(wi.quantity)) { wi.quantity = 0}
      
      updjson[updatefield.id] = wi
    })
    let body = JSON.stringify({portfolio_item: updjson})
    //console.log(body)
    fetch('/api/v1/portfolios/'+portfolioid+'/portfolio_items_bulk',
    	  {
    	    method: 'PATCH',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((portfolioitem) => {
		     if(portfolioitem.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot update portfolio items : '+portfolioitem.message, type: 'error' }) } else {

		       //window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Portfolio saved', type: 'success' })
		       this.recalculate(portfolioitem)
		     }
		   })    
  }
  
  

  
  handleAddItems(e) {
    this.setState({ loading: true });
    //e.preventDefault();
    //let symbols = formfield.symbols.value
    const symbols = this.state.tickers.join(' ')
		       
    let body = JSON.stringify({symbols: symbols} )
    
    fetch('/api/v1/portfolios/'+this.props.portfolioId+'/portfolio_items',
    	  {
    	    method: 'POST',
    	    headers: {
    	      'Content-Type': 'application/json'
    	    },
	    body: body
    	  }
    ).then((response) => {return response.json()})
		   .then((portfolioitem) => {
		     this.setState({ loading: false });
		     if(portfolioitem.status == 500) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: 'Cannot create entries : '+portfolioitem.message, type: 'error' }) } else {

		       let asked = symbols.split(/[ ]+/).map(x => x.toUpperCase())
		       let added = portfolioitem.map(x => x.stock.code)
		       let notfound = asked.filter(s => !added.includes(s))

		       if(notfound.length != 0) { window.flash_messages.addMessage({ id: 'id'+Math.random(), text: "Not found : "+notfound.toString(), type: 'error' }) }

		       let newentries = this.state.entries.slice().concat(portfolioitem)
		       this.recalculate(newentries)
		       this.stocksearch && this.stocksearch.clear()
		       this.setState({tickers: []})
		     }

		     
		   })

    //e.target.reset();
  }

  handleChange(id,name,value) {
    //console.log("handleChange "+id+";"+name+";"+value)

    let newentries = this.state.entries.slice()
    let idx = newentries.findIndex(x => x.id == id)
    //let newvalue = parseFloat(value)
    newentries[idx][name] = value
    this.recalculate(newentries)

    
    //if(!isNaN(newvalue)) {
    //  newentries[idx][name] = newvalue
    //  this.recalculate(newentries)
    //} else {
    //  newentries[idx][name] = 0
    //}
    
  }


  handleEven() {
    //console.log("handleEven")
    let newentries = this.state.entries.slice()
    let beginnav = parseFloat(this.props.beginnav)

    beginnav = isNaN(beginnav) ? 0 : beginnav
    const length = newentries.length
    const newpct = (100/length).toFixed(3)
    newentries.forEach(item => { 
      item.percentage = newpct
    })
    
    this.recalculate(newentries)
  }

  handleAutofillRecent() {
    //console.log("handleAutofillRecent")
    
    let newentries = this.state.entries.slice()
    let beginnav = parseFloat(this.props.beginnav)

    beginnav = isNaN(beginnav) ? 0 : beginnav
    

    newentries.forEach(item => {
      const pct = item.percentage
      const price = item.stock.price
      const quantity = item.stock.code.endsWith('.CC') ? (beginnav*pct/100)/price : Math.round((beginnav*pct/100)/price)     
      item.quantity = quantity
    })
    
    this.recalculate(newentries)
  }
  
  handleAutofill12m() {
    //console.log("handleAutofill12m")

    function findValue(closes) {
      const currentdate = new Date();
      //const firstdayoftheyear = new Date(currentdate.getFullYear(), 0, 1);
      let date12m = new Date();
      date12m.setMonth(currentdate.getMonth()-12)
      
      const index = closes.findIndex(item => new Date(item[0]) < date12m)
      return closes[index-1][1]
    }

    
    let newentries = this.state.entries.slice()
    let beginnav = parseFloat(this.props.beginnav)

    beginnav = isNaN(beginnav) ? 0 : beginnav
    

    newentries.forEach(item => {
      const pct = item.percentage
      const closes = item.stock.closes_light
      const price = findValue(closes)
      const quantity = item.stock.code.endsWith('.CC') ? (beginnav*pct/100)/price : Math.round((beginnav*pct/100)/price)      
      item.quantity = quantity
    })
    
    this.recalculate(newentries)
  }

  handleAutofillBeginOfYear() {
    //console.log("handleAutofillBeginOfYear")

    function findValue(closes) {
      const currentdate = new Date();
      const dateboy = new Date(currentdate.getFullYear(), 0, 1);
      //let date12m = new Date();
      //date12m.setMonth(currentdate.getMonth()-12)
      
      const index = closes.findIndex(item => new Date(item[0]) < dateboy)
      
      return index != -1 ? closes[index-1][1] : closes[0][1]
    }

    
    let newentries = this.state.entries.slice()
    let beginnav = parseFloat(this.props.beginnav)

    beginnav = isNaN(beginnav) ? 0 : beginnav
    

    newentries.forEach(item => {
      const pct = item.percentage
      const closes = item.stock.closes_light
      const price = findValue(closes)
      const quantity = item.stock.code.endsWith('.CC') ? (beginnav*pct/100)/price : Math.round((beginnav*pct/100)/price)
      item.quantity = quantity

      //console.log(item.stock.code+":"+price)
    })
    
    this.recalculate(newentries)
  }
  



  
  componentDidUpdate(prevProps) {
    if(prevProps.cash != this.props.cash && !isNaN(this.props.cash)) {
      //console.log("componentDidUpdate:"+prevProps.cash+"=>"+this.props.cash)
      let newentries = this.state.entries.slice()
      this.recalculate(newentries)
    }

    if(prevProps.beginnav != this.props.beginnav && !isNaN(this.props.beginnav)) {
      //console.log("componentDidUpdate:"+prevProps.beginnav+"=>"+this.props.beginnav)
    }

    if(prevProps.portfolioId != this.props.portfolioId ) {
      //console.log("componentDidUpdate:"+prevProps.portfolioId+"=>"+this.props.portfolioId)
      this.setState({btnclip: false})
      const portfolioid = this.props.portfolioId
      fetch('/api/v1/portfolios/'+portfolioid+'/portfolio_items')
			      .then((response) => {return response.json()})
			      .then((data) => {
				//console.log(data)
				if (this._isMounted && data.status != 500) { this.recalculate(data) }
				if (data.status == 500) { this.setState({entries: [], totalpct: 0, totalposcash: 0})}
			      })
    }
    
    
  }
  
  recalculate(data) {
    let newentries = []
    let cash = parseFloat(this.props.cash)
    let total = 0
    let totalpct = 0

    cash = isNaN(cash) ? 0 : cash
    

    
    data.map((entry) => {
      total += entry.quantity * entry.stock.price
    })
    total += cash

    data.map((entry) => {

      let parsepercentage = parseFloat(entry.percentage)
      //let parsequantity = parseInt(entry.quantity,10)
      let parsequantity = entry.quantity
      
      let amount = parsequantity * entry.stock.price
      let target = parsepercentage/100 * total
      let toadjust = Math.round((target - amount)/entry.stock.price)
      let band40 = Math.abs((amount - target)/target)

      if(!isNaN(toadjust)) { entry.toadjust = toadjust } else { entry.toadjust = 0 }
      if(!isNaN(band40)) { entry.band40 = band40 } else { entry.band40 = 0 }
      
      newentries.push(entry)

      totalpct += parsepercentage
    })

    newentries.sort((function (a, b) {
      return a.stock.code.localeCompare(b.stock.code);
    }))

    this.setState({entries: newentries, totalpct: totalpct.toFixed(2), totalposcash: total});  
  }
  
  toPercentageBand(f) {
    if(f!=null && f>=0.4) {
      return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
    } else
    {
      return <span>{(f*100).toFixed(2)+' %'}</span>
    }
  }

  toPercentage(f) {
    if(f!=null && f<0) {
      return <span className="text-danger">{(f*100).toFixed(2)+' %'}</span>
    } else
    {
      return <span>{(f*100).toFixed(2)+' %'}</span>
    }
  }
  
  totalPct() {
    if(this.state.totalpct != 100) {
      return <span className="text-danger">[{this.state.totalpct}%]</span>
    }
    else
    {
      return <span className="text-success">[{this.state.totalpct}%]</span>
    }
  }

  numberToMoney(n) {
    let formatter = new Intl.NumberFormat(undefined);
    return formatter.format(n); 
  }

  displaydetail(stock) {
    this.setState({stockdetail: stock})
  }

  handleStockSearch(e, ref) {
    this.stocksearch = ref
    this.setState( {tickers: e} )
  }

  updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip)
    this.setState({btnclip: true})
  }
  
  render() {
    const allcodes = (this.state.entries.reduce((a,b) => a + " " + b.stock.code, "")).trim()
    let entries = this.state.entries.map((entry) => {

      return(
	<tr key={entry.id}>
	  <td className="align-middle"><button type="button" onClick={() => this.handleDeleteItem(entry.portfolio_id, entry.id)} className="btn btn-sm btn-danger">X</button></td>
	  <td className="align-middle">
	    <a href={'/stocks/'+entry.stock.code} className="btn btn-sm btn-light" onMouseEnter={() => this.displaydetail(entry.stock)} onMouseLeave={() => this.displaydetail('')}>
	      {entry.stock.code}
	    </a>
	  </td>
	  <td className="align-middle"><div className="input-group input-group-sm w-50"><input className="form-control form-control-sm" type="text" value={entry.percentage} onChange={(e) => this.handleChange(entry.id,"percentage",e.target.value)}/><div className="input-group-append"><span className="input-group-text">%</span></div></div></td>
	  <td className="align-middle"><input className="form-control form-control-sm" type="text" value={entry.quantity} onChange={(e) => this.handleChange(entry.id,"quantity",e.target.value)}/></td>
	  <td className="align-middle">{entry.toadjust}</td>
	  <td className="align-middle">{this.toPercentageBand(entry.band40)}</td>
	  <td className="align-middle">{this.toPercentage(entry.stock.mom6m)}</td>
	  <td className="align-middle">{this.toPercentage(entry.stock.proximityhigh52)}</td>
	  <td className="align-middle">{this.toPercentage(entry.stock.proximitysma10m)}</td>
	</tr>	
      )
    })

    
    return (
      <React.Fragment>
      { (this.props.portfolioId != 0) ?
	<React.Fragment>
	  
	  <form>
	    <div className="form-row mt-2">
	      <div className="form-group col-md-8">
		
		<StockSearch onChange={(e, ref) => this.handleStockSearch(e, ref)}/>
		
	      </div>
	      <div className="form-group col-md-2">
		{ this.state.loading &&
		  <div className="spinner-border" role="status">
		    <span className="sr-only">Loading...</span>
		  </div>
		}
	{ !this.state.loading &&
	  <div><button  disabled={this.state.tickers.length == 0} className="btn btn-info" onClick={(e) => this.handleAddItems(e)}>Add to portfolio</button> <button type="button" className="btn btn-sm btn-link" data-toggle="modal" data-target="#helpModal"><i className="fas fa-question-circle"></i> Help</button></div>
	}
	  </div>
	  </div>
	  <small id="symbolsHelp" className="form-text text-muted"></small>	  
	  </form>
	  
	  
	  <form className="form-inline">	
	    <div className="table-responsive">
	      <table className="table table-sm table-hover">
		<thead>
		  <tr>
		    <th>{!this.state.btnclip ? <button data-toggle="tooltip" title="Copy tickers to clipboard" className="btn btn-dark btn-sm" onClick={() => {this.updateClipboard(allcodes)}}><i className="fas fa-copy"/></button> : <button className="btn btn-dark btn-sm" disabled={true}><i className="fas fa-check"/></button>}</th>
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="Stock ticker">Code [{this.state.entries.length}]</span></th>
		    <th className="align-middle">
		      <div className="btn-group dropright">
			<button type="button" className="btn btn-link dropdown-toggle text-dark font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			  <span data-toggle="tooltip" data-placement="auto" title="Your target">% to own</span> {this.totalPct()}
			</button>
			<div className="dropdown-menu">
			  <button type="button" data-toggle="tooltip" data-placement="right" title="Auto fill evenly" className="dropdown-item btn btn-link btn-sm" onClick={() => this.handleEven()}><small>Distribute evenly</small></button>
			</div>
		      </div>
		    </th>
		    
		    <th className="align-middle">
		      <div className="btn-group dropright">
			<button type="button" className="btn btn-link dropdown-toggle text-dark font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			  <span data-toggle="tooltip" data-placement="auto" title="How much shares you have">Quantity owned</span>
			</button>
			<div className="dropdown-menu">
			  <button type="button" data-toggle="tooltip" data-placement="right" title="Auto fill with prices 12 months ago" className="dropdown-item btn btn-link btn-sm" onClick={() => this.handleAutofill12m()}><small>Autofill with prices 12 months ago</small></button>

			  <button type="button" data-toggle="tooltip" data-placement="right" title="Auto fill with recent prices" className="dropdown-item btn btn-link btn-sm" onClick={() => this.handleAutofillRecent()}><small>Autofill with recent prices</small></button>

			  <button type="button" data-toggle="tooltip" data-placement="right" title="Auto fill with prices begin of year" className="dropdown-item btn btn-link btn-sm" onClick={() => this.handleAutofillBeginOfYear()}><small>Autofill with prices begin of year</small></button>
			</div>
		      </div>
		    </th>
		    
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="How much shares to adjust to match your target">Quantity to adjust</span></th>
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="If your line is over or below 40% of your target, it is time to rebalance">40% band</span></th>
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="Absolute momentum">Mom6m</span></th>
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="Relative distance to the 52 weeks high">52wk-hi</span></th>
		    <th className="align-middle"><span data-toggle="tooltip" data-placement="auto" title="Above or below the SMA 10 months">Above sma10m</span></th>
		  </tr>
		</thead>

		<tbody>
		  {entries}		
		</tbody>
	      </table>
	    </div>
	  </form>

	  <p><strong>Total with cash : {this.numberToMoney(this.state.totalposcash)}</strong></p>

	  {
	    this.state.stockdetail && <div className="stockdetail"><StockDetail stock={this.state.stockdetail} /></div>
	  }

	</React.Fragment>
		: <React.Fragment/> }

      { /* help modal */ }
	<div className="modal fade" id="helpModal" tabIndex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
	  <div className="modal-dialog" role="document">
	    <div className="modal-content">
	      <div className="modal-header">
		<h5 className="modal-title" id="helpModalLabel">Help on Portfolio</h5>
		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	      </div>
	      <div className="modal-body">
		<p className="card-text">Create here a portfolio to track a real investment, or to backtest an allocation strategy.</p>
		<p className="card-text"><strong>Beginning Net Asset Value</strong> : it represents the total amount of what you have at the beginning of the year. You can also set the amount for a different date. The returns you will see on the welcome page will depend of the amount you set here.</p>
		<p className="card-text"><strong>Cash : </strong>the amount of money you do not actually use for investing</p>
		<p className="card-text"><strong>Enter symbols to Add stocks : </strong>put the tickers here, you can add multiple tickers with a space. For cryptocurrency a ticker is represented by [SYMBOL]-[MARKET].CC</p>
		<p className="card-text">Tickers example : </p>
		<ul>
		  <li>Regular stocks or ETF : AAPL MSFT</li>
		  <li>Crypto currency : BTC-USD.CC ETH-USD.CC</li>
		</ul>
		<p className="card-text"><strong>% to own : </strong>put here the percentage you want to allocate to this asset</p>
		<p className="card-text"><strong>Quantity owned : </strong>if you have a real portfolio, put here the quantity of shares you own. If not, you can <i>autofill with the prices of the begin of the year</i> for example</p>
		<p className="card-text"><strong>Quantity to adjust : </strong>how much share you must sell or buy to fit the target you want to own</p>
		<p className="card-text"><strong>40% band : </strong>if it hits 40%, you should rebalance your portfolio because the asset is becoming to be over or under weigthed</p>
		<p className="card-text"><strong>Mom6m : </strong>this is the absolute momentum with a lookback period of 6 month. If it is positive with a high value, it means that the price may continue to rise</p>
		<p className="card-text"><strong>Above sma10m : </strong>how far the latest close if from the 10 months simple moving average. If it is negative, it means that the momentum of this asset is declining and the price may continue to decrease</p>
	      </div>
	      <div className="modal-footer">
		<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
	      </div>
	    </div>
	  </div>
	</div>	
      </React.Fragment>
    );
  }
}

export default PortfolioItem
